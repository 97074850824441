import React from 'react';
import styled from 'styled-components/macro';
import { isObject } from 'react-utils';
import BlockRow from './BlockRow';
import BlockEmpty from './BlockTypes/BlockEmpty/BlockEmpty';
import Draggable from '../../hoc/DragDrop/Draggable';
import Button from '../UI/Button/Button';
import withErrorBoundary from '../../hoc/withErrorBoundary';

const BlockArea = (props) => {
	const blockRows =
		props.elems.areas && Object.keys(props.elems.areas).length > 0
			? props.elems.areas[props.id].rows
			: [];

	/**
	 * Helper function that will rearrange a column's blocks in an array
	 *
	 * @param {string} columnId
	 */
	const getColumnBlocks = (columnId) => {
		if(!isObject(props.elems.columns[columnId])) return [];

		const columnBlocks = props.elems.columns[columnId].blocks;

		return columnBlocks.reduce((prev, blockId) => {
			const block = props.elems.blocks[blockId];
			prev.push(block);

			return prev;
		}, []);
	};

	/**
	 * Function that will help the flat state object to become a recursive object
	 * This function will fetch the column objects for each row and collect them in an array
	 * It will also call an additional function on column level that will rearrange blocks
	 * the same ways within columns
	 *
	 * @param {string} row
	 */
	const getRowColumns = (rowId) => {
		if(!isObject(props.elems.rows[rowId])) return [];

		const rowColumns = props.elems.rows[rowId].columns;

		return rowColumns.reduce((prev, colId) => {
			const column = props.elems.columns[colId];
			const newColumns = {
				...column,
				blocks: getColumnBlocks(colId)
			};

			prev.push(newColumns);
			return prev;
		}, []);
	};

	let output = (
		<BlockEmpty
			icon={['fal', 'ghost']}
			title="Här var det tomt..."
			description="Lägg till ett block för att börja bygga din sida"
		>
			<ScButton
				onClick={(ev) => {
					ev.stopPropagation();
					props.addBlock(props.id, null, null, null, 0, ev);
				}}
			>
				Lägg till block
			</ScButton>
		</BlockEmpty>
	);

	if(blockRows.length > 0) {
		output = blockRows.map((rowId, rowIndex) => {
			const updatedRow = {
				...props.elems.rows[rowId],
				columns: getRowColumns(rowId)
			};

			const variantType = `visible_${props.viewInUse}`;

			if(
				!updatedRow.settings[variantType] &&
				!props.viewHiddenBlockRowsForVariant
			)
				return null;

			return (
				<ScDraggable
					scope="page-builder"
					key={rowId}
					index={rowIndex}
					allowedDirection="vertical"
					direction="vertical"
					restriction="row"
					areaId={props.id}
					rowId={rowId}
					type="row"
					enabled={true}
					addClassOnDrag="isDraggingBlockRow"
					className="cms-draggable"
				>
					<BlockRow
						id={rowId}
						areaId={props.id}
						row={updatedRow}
						index={rowIndex}
						blockTypes={props.blockTypes}
						addBlock={props.addBlock}
						columnChanged={props.columnChanged}
						blockChanged={props.blockChanged}
						opts={props.opts}
						updated={updatedRow.updated ? updatedRow.updated : 1}
						viewInUse={props.viewInUse}
						hideBlockRowClicked={props.hideBlockRowClicked}
						blockRowWidthClicked={props.blockRowWidthClicked}
						blockRowReverseClicked={props.blockRowReverseClicked}
						openLinkToolModal={props.openLinkToolModal}
						openBlockSettingsModal={props.openBlockSettingsModal}
						closeBlockSettingsModal={props.closeBlockSettingsModal}
						deleteBlockHandler={props.deleteBlockHandler}
						blockSettingsFormValidation={
							props.blockSettingsFormValidation
						}
					/>
				</ScDraggable>
			);
		});
	}

	return (
		<ScContainer
			className="cms-blockArea"
			id={props.id}
		>
			{output}
		</ScContainer>
	);
};

export default withErrorBoundary(BlockArea);

const ScContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;

	.isDragging & {
		user-select: none;
	}

	.checkoutCart.-collapsed & {
		display: block;
	}
`;

const ScDraggable = styled(Draggable)`
	/* &:first-child {
		margin-top: 32px;
	}
	&:last-child {
		margin-bottom: 32px;
	} */

	.cms-blockRow {
		padding-top: 0;
		padding-bottom: 0;
	}
`;

const ScButton = styled(Button)`
	color: #444;
`;