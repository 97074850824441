import React from 'react';
import styled from 'styled-components/macro';
import { TreeCategoryProps, ScTreeCategoryProps } from './model.TreeCategory';
import { CheckItem } from '../../Forms';

const TreeCategory: React.FC<TreeCategoryProps> = (props) => {
	const { id, name, isChecked, isEnabled, checkboxChanged } = props;

	/**
	 * Triggered when changing checkbox.
	 */
	const checkboxChangedHandler = React.useCallback((event: any) => {
		if(checkboxChanged) checkboxChanged(event, id);
	}, [checkboxChanged, id]);

	return (
		<ScTreeCategory isHorizontal={false}>
			<CheckItem
				name={id}
				title={name}
				type="checkbox"
				checked={isChecked}
				isDisabled={!isEnabled}
				isNotClickable={!isEnabled}
				changed={checkboxChangedHandler}
			/>
			{props.children}
		</ScTreeCategory>
	);
};

const ScTreeCategory = styled.div<ScTreeCategoryProps>`
	padding-right: ${(props) => (props.isHorizontal ? '40px' : '0')};
`;

export default React.memo(TreeCategory);
