import React from 'react';
import { BlockFormItem, BlockFormProps, Address, BlockFormItemTypes } from './BlockForm.types';
import useFormStructure from '../../../../hooks/Forms/useFormStructure';
import BlockEmpty from '../BlockEmpty/BlockEmpty';

const BlockForm: React.FC<BlockFormProps> = (props) => {
	const items = props.data?.items;

	/**
     * Function feeded to a hook callback
     * When called it will create a new form element
     * 
     * @param {JSX.Element[] | undefined} children 
     * @returns {JSX.Element}
     */
	const createForm = React.useCallback((children: JSX.Element[] | undefined) => {
		return (
			<form className="form js-form">
				{props.data?.show_name && props.data.name && (
					<h2>
						{props.data.name}
					</h2>
				)}
			
				{children && children?.length > 0 ? children : (
					<BlockEmpty
						icon={['fal', 'file-contract']}
						title="Här var det tomt..."
						description="Tryck på kugghjulet för att börja bygga ditt formulär"
					/>
				)}

				{(children && children.length > 0) && (
					<button
						className="button"
						data-submit
						type="submit"
						disabled
					>
						{props.data?.button_text}
					</button>
				)}
			</form>
		);
	}, [props.data?.button_text, props.data?.name, props.data?.show_name]);
    
	/**
     * Function feeded to a hook callback
     * Creates markup for each form item type
     * 
     * @param {BlockFormItem} item 
     * @param { number} index 
     * @param {BlockFormItem} parentItem
     * @param {JSX.Element | JSX.Element[] | undefined} children 
     * @returns {JSX.Element}
     */
	const createFormItems = React.useCallback((item: BlockFormItem, index: number, parentItem: BlockFormItem, children?: JSX.Element | JSX.Element[] | undefined): JSX.Element => {
		switch(item.type) {
			case BlockFormItemTypes.INPUT:
				return (
					<div
						key={index}
						className="field"
					> 
						<label
							className={item.required ? 'label dataRequired' : 'label'}
							htmlFor={item.unique_name}
						>
							<div className="labelTitle">
								{item.name}
							</div>
							<div className="labelDescription">
								{item.description}
							</div>
						</label>
						
						<input 
							type={item.input_type || undefined}
							value={item.value ?? undefined}
							placeholder={item.placeholder ?? ''} 
							id={item.unique_name} 
							maxLength={item.maxlength ?? undefined}
							minLength={item.minlength ?? undefined}
							readOnly={item.readonly}
							disabled={item.disabled}
							min={item.min ?? undefined}
							max={item.max ?? undefined}
							data-min={item.min ?? undefined}
							data-max={item.max ?? undefined}
							step={item.step ?? undefined}
						/>
					</div>
				);
        
			case BlockFormItemTypes.SELECT:
				//check if select contains a preselected option
				const selectedItems = item.children.reduce((prev: string[], currentKey: string) => {
					if(!items) return prev;

					const item = items[currentKey];
                        
					if(item.type === BlockFormItemTypes.OPTION_GROUP) {
						item.children.forEach((childKey) => {
							const child = items[childKey];
							if(child.selected && child.name) prev.push(child.name);
						});
					} else {
						if(item.selected && item.name) prev.push(item.name);
					}
					
					return prev;
				}, []);

				let currentValue;

				if(item.multiple) {
					currentValue = selectedItems;
				} else if(!item.multiple && selectedItems.length > 0) {
					currentValue = selectedItems[0];
				} else if(selectedItems.length < 1 && items && item.children[0]) {
					currentValue = items[item.children[0]].name;
				}

				return ( 
					<div
						key={index}
						className="field"
					>

						{(item.name || item.description) && (
							<>
								<label
									className={item.required ? 'label dataRequired' : 'label'}
									htmlFor={item.unique_name}
								>
									{item.name && (
										<div className="labelTitle">
											{item.name}
										</div>
									)}
									{item.description && (
										<div className="labelDescription">
											{item.description}
										</div>
									)}
								</label>
							</>
						)}
						
						<div className={item.multiple ? 'styledSelect -full -multiple' : 'styledSelect -full'}>
							<select
								id={item.unique_name}
								defaultValue={currentValue ?? undefined}
								//key is used to trigger a re-render when selectedItem is changed,
								//because a change of defaultValue does not re-render the input
								key={`${item.unique_name}-${currentValue}`}
								disabled={item.disabled}
								multiple={item.multiple}
								size={item.size ?? undefined}
							>
								{children}
							</select>
							<i className="fal fa-chevron-down" />
						</div>
                        
					</div>
				);

			case BlockFormItemTypes.OPTION_GROUP: 
				return (
					<optgroup
						key={item.key}
						label={item.name ?? undefined}
						disabled={item.disabled}
					>
						{children}
					</optgroup>
				);

			case BlockFormItemTypes.OPTION:
				return (
					<option
						key={item.key}
						value={item.value ?? undefined}
						disabled={item.disabled}
					>
						{item.name}
					</option>
				);

			case BlockFormItemTypes.CHECKBOX_GROUP:
				return (
					<div
						key={index}
						className="field"
					>
						
						{(item.show_name || item.show_description) && (
							<>
								{item.show_name && item.name && (
									<h4>
										{item.name}
									</h4>
								)}
								{item.show_description && item.description && (
									<div className="labelDescription">
										{item.description}
									</div>
								)}
							</>
						)}
						<div className={`checkboxGroup ${item.layout}`}>
							{children}
						</div>
						
					</div>
                    
				);

			case BlockFormItemTypes.CHECKBOX:
				return (
					<div
						key={index}
						className="styledCheckbox"
					>
						<input
							className="hidden"
							type="checkbox"
							id={item.unique_name}
							value={item.value ?? undefined}
							disabled={item.disabled}
							defaultChecked={item.selected}
							//key is used to trigger a re-render when item.selected is changed,
							//because a change of defaultValue does not re-render the input
							key={`${item.unique_name}-${item.selected}`}
						/>
						<label
							className={item.required ? 'dataRequired checkboxLabel' : 'checkboxLabel'}
							htmlFor={item.unique_name}
						>
							<div className="checkbox">
								{item.selected && <i className="far fa-check"></i>}
							</div>
							<div className="checkboxText">
								<div className="checkboxTitle">
									{item.name}
								</div>
								{item.description && (
									<div className="checkboxDescription">
										{item.description}
									</div>
								)}
								
							</div>
						</label>
					</div>
				);

			case BlockFormItemTypes.RADIO_GROUP:
				return (
					<div
						key={index}
						className="field"
					>
						
						{(item.show_name || item.show_description) && (
							<>
								{item.show_name && item.name && (
									<div className="labelTitle">
										{item.name}
									</div>
								)}
								{item.show_description && item.description && (
									<div className="labelDescription">
										{item.description}
									</div>
								)}
							</>
						)}
						<div
							className={`radioGroup ${item.layout}`}
						>
							{children}
						</div>
					</div>
				);

			case BlockFormItemTypes.RADIO:
				
				return (
					
					<div key={index}>
						 <input
							type="radio"
							id={item.unique_name}
							disabled={item.disabled}
							value={item.value ?? undefined}
							name={'unique_name' in parentItem ? parentItem?.unique_name : undefined}
							className="styledRadio"
							defaultChecked={item.selected}
							//key is used to trigger a re-render when item.selected is changed,
							//because a change of defaultValue does not re-render the input
							key={`${item.unique_name}-${item.selected}`}
						 />
						
						<label
							className="styledRadio"
							htmlFor={item.unique_name}
						>
							<div className="radioCircle"></div>
							<div className="radioText">
								<div className="radioTitle">
									{item.name}
								</div>
								{item.description && (
									<div className="radioDescription">
										{item.description}
									</div>
								)}
								
							</div>
						</label>
					</div>
				);

			case BlockFormItemTypes.TEXT_AREA:
				return (
					<div
						key={index}
						className="field"
					>
						<label
							className={item.required ? 'label dataRequired' : 'label'}
							htmlFor={item.unique_name}
						>
							<div className="labelTitle">
								{item.name}
							</div>
							<div className="labelDescription">
								{item.description}
							</div>
						</label>
						<textarea
							id={item.unique_name}
							placeholder={item.placeholder ? item.placeholder : ''}
							maxLength={item.maxlength ?? undefined}
							minLength={item.minlength ?? undefined}
							readOnly={item.readonly}
							disabled={item.disabled}
							rows={item.rows ?? undefined}
						>
						</textarea>
					</div>
				);

			case BlockFormItemTypes.ADDRESS_GROUP:
				//divide address-group's children into separate groups
				const addressChildren = item.children.reduce((prev: Address, currentKey: string) => {
					if(items) {
						const item = items[currentKey];
						const sortOrder = item.sort_order;
						switch(sortOrder) {
							case 3:
							case 4:
								prev['postal'].push(currentKey);
								break;
							case 5:
								prev['country'].push(currentKey);
								break;
							default: 
								prev['address'].push(currentKey);    
						}
					}
					return prev;
				}, {
					address: [],
					postal: [],
					country: []
				});

				return (
					<div
						key={index}
						className="field"
					>
						{(item.show_name || item.show_description) && (
							<>
								{item.show_name && item.name && (
									<h4>
										{item.name}
									</h4>
								)}
								{item.show_description && item.description && (
									<div className="labelDescription">
										{item.description}
									</div>
								)}
							</>
						)}

						{addressChildren.address.map((childKey, index) => {
							if(items) {
								return createFormItems(items[childKey], index, item);	 
							}
							return null;
						})}
						<div
							style={{ display: 'flex' }}
							className="postalRow"
						>
							{addressChildren.postal.map((childKey, index) => {
								if(items) {
									return createFormItems(items[childKey], index, item);	 
								}
								return null;
							})}
						</div>

						{addressChildren.country.map((childKey, index) => {
							if(items) {
								const country = items[childKey];
								const countryChildren = country.children.map((childKey) => items[childKey]);

								// Select all country's children elements, both option-groups and options in an array of elements
								const countryChildrenElements = countryChildren.reduce((prev: JSX.Element[], current: BlockFormItem) => {
									if(current.type === BlockFormItemTypes.OPTION_GROUP) {
										const options = current.children.map((childKey) => items[childKey]);
										const optionElements = options.map((option, index) => createFormItems(option, index, current));

										const optionGroup = createFormItems(current, index, country, optionElements);

										prev.push(optionGroup);
									} else {
										const optionElement = createFormItems(current, index, country);
										prev.push(optionElement);
									}
									return prev;
								}, []);

								return createFormItems(country, index, item, countryChildrenElements);	 
							}
							return null;
						})}
					</div>
				);

			case BlockFormItemTypes.NAME_GROUP:
				return (
					<div
						key={index}
					>
						
						{(item.show_name || item.show_description) && (
							<>
								{item.show_name && item.name && (
									<h4>
										{item.name}
									</h4>
								)}
								{item.show_description && item.description && (
									<div className="labelDescription">
										{item.description}
									</div>
								)}
							</>
						)}
						<div
							className="firstSureNameRow"
							style={{ display: 'flex' }}
						>
							{children}
						</div>
					</div>
				);
            
			case BlockFormItemTypes.FILE:
				return (
					<div
						key={index}
						className="field"
					>
						<label
							className={item.required ? 'label dataRequired' : 'label'}
							htmlFor={item.unique_name}
						>
							<div className="labelTitle">
								{item.name}
							</div>
							<div className="labelDescription">
								{item.description}
							</div>
						</label>
						<input
							type="file"
							id={item.unique_name}
							multiple={item.multiple}
							disabled={item.disabled}
						/>
					</div>
				);
			default:
				return <></>; 
		}
	}, [items]);
    
	const { buildForm } = useFormStructure(items, createForm, createFormItems);

	return (
		<div className="cms-block cms-blockForm">
			{buildForm()}
		</div>
	);
};

export default BlockForm;

