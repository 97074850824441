import React from 'react';
import styled, { css } from 'styled-components/macro';
import { StyledTreeItemProps, TreeProps } from './Tree.types';
import TreeWrapper from '../TreeWrapper/TreeWrapper';
import TreeItem from '../TreeItem/TreeItem';
import ItemsWrapper from '../ItemsWrapper/ItemsWrapper';
import TreeCategory from '../TreeCategory/TreeCategory';
import useCheckboxTree from '../../../hooks/useCheckboxTree/useCheckboxTree';

const Tree: React.FC<TreeProps> = (props) => {
	const { tree: groupsTree } = useCheckboxTree({
		data: props.treeItems
	});

	return (
		<TreeWrapper isHorizontal={false}>
			{groupsTree.map((category) => (
				<TreeCategory
					key={`CheckboxTreeCategory_${category.id}`}
					id={category.id}
					name={category.name}
					isChecked={category.isChecked}
					isEnabled={category.isEnabled}
					checkboxChanged={props.categoryChecked}
				>
					<ItemsWrapper isHorizontal={props.itemsIsHorizontal}>
						{category.items.map((item) => {
							// Pre-select groups list when creating or editing a current user.
							const isGroupChecked = item.isChecked || !!(props.preselectedGroups && props.preselectedGroups.find((group) => group === item.key) !== undefined);

							return (
								<ScTreeItem
									key={item.id ?? item.key}
									id={item.key}
									icon={item.icon}
									text={item.text}
									isChecked={isGroupChecked}
									isEnabled={item.isEnabled}
									isHorizontal={props.itemsIsHorizontal}
									checkboxChanged={props.itemChecked}
								/>
							);
						})}
					</ItemsWrapper>
				</TreeCategory>
			))}
		</TreeWrapper>
	);
};

export default React.memo(Tree);

const ScTreeItem = styled(TreeItem)<StyledTreeItemProps>`
	${props => props.isHorizontal && css`
		width: 20%;
	`}
`;