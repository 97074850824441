import React from 'react';
import styled from 'styled-components/macro';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, PercentCrop, convertToPixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { CropModalProps, UploadResult } from './CropModal.types';
import axios from '../../../../utils/oc-axios';
import { CheckItem, InputRange, Select } from '../../../../components/Forms';
import Label from '../../../../components/Forms/Label/Label';
import { Button, Loader } from '../../../../components/UI';
import { SelectProps } from '../../../../components/Forms/Select/model.Select';
import { CheckItemProps } from '../../../../components/Forms/CheckItem/model.CheckItem';
import { IMAGE_CROP_ASPECT_RATIOS } from '../../../../settings';

// Get the initial aspect ratio from the project settings file
const INITITAL_RATIO = IMAGE_CROP_ASPECT_RATIOS[0].ratio ?? 16 / 9;

const CropModal: React.FC<CropModalProps> = (props) => {

	// Selected image props
	const { imgTargetFolder, imgMime, imgExt, uploadCompleted, imgName } = props;

	// Retrieve the close prop from the OC7 modal
	const { close: modalClose } = props.modal;

	// A reference to the image element
	const imgElementRef = React.useRef<HTMLImageElement>(null);

	// Last aspect ratio setting
	const lastAspectRatioRef = React.useRef<number>(INITITAL_RATIO);

	// A loading state for when cropping the image
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	// State managing the crop details
	const [cropDetails, setCropDetails] = React.useState<Crop>();

	// State managing the final crop result
	const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();

	// State managing the rotation value
	const [rotate, setRotate] = React.useState<number>(0);

	// State managing the aspect ratio
	const [aspect, setAspect] = React.useState<number | undefined>(INITITAL_RATIO);

	/**
	 * Callback which is triggered from ReactCrop
	 * It will set the crop instruction details which will be used by the canvas element
	 * 
	 * @param {PixelCrop} _crop 
	 * @param {PercentCrop} percentCrop 
	 * @returns {void}
	 */
	const setCropHandler = React.useCallback((_crop: PixelCrop, percentCrop: PercentCrop): void => {
		setCropDetails(percentCrop);
	}, []);

	/**
	 * Callback handler which is triggered when the image has loaded
	 * It will then set the initial crop state
	 * 
	 * @param {React.SyntheticEvent<HTMLImageElement>} ev 
	 * @returns {void}
	 */
	const imageLoadHandler = React.useCallback((ev: React.SyntheticEvent<HTMLImageElement>): void => {
		if(aspect) {
			const { width, height } = ev.currentTarget;
			setCropDetails(centerAspectCrop(width, height, aspect));
	  	}
	}, [aspect]);

	/**
	 * Changes the rotation of the image
	 * 
	 * @todo - InputRange component is poorly typed, thus the any
	 * @param {any} ev 
	 * @returns {void}
	 */
	const changeRotationHandler = React.useCallback((ev: any): void => {
		setRotate(ev.target.value);
	}, []);

	/**
	 * Callback triggered from React Crop after interaction with the crop area
	 * The state update is what is finally used to perform the crop
	 * 
	 * @param {PixelCrop} crop 
	 * @param {PercentCrop} _percentageCrop 
	 * @returns {void}
	 */
	const completedCropHandler = React.useCallback((crop: PixelCrop, _percentageCrop: PercentCrop) => {
		setCompletedCrop(crop);
	}, []);

	/**
	 * Handles the aspect ratio from a fixed value to free transform
	 * 
	 * @param {React.SyntheticEvent<HTMLSelectElement>|React.ChangeEvent<HTMLInputElement>} ev
	 * @param {SelectProps|CheckItemProps} elementProps
	 * @returns {void}
	 */
	const changeAspectRatioHandler = React.useCallback((ev: React.SyntheticEvent<HTMLSelectElement>|React.ChangeEvent<HTMLInputElement>, elementProps: SelectProps|CheckItemProps): void => {
		let ratio = undefined;
		const isChangingRatio = elementProps && elementProps.name === 'aspectRatio';

		if(aspect && !isChangingRatio) {
			setAspect(ratio);
			return;
		}

		if(isChangingRatio || !aspect) {
			// Get the new ratio from the select dropdown event
			ratio = ev.currentTarget.value ? ev.currentTarget.value : lastAspectRatioRef.current;

			if(imgElementRef.current && ratio) {
				// Make sure the ratio in an int
				ratio = +ratio;
				const { width, height } = imgElementRef.current;
				const newCropDetails = centerAspectCrop(width, height, ratio);
				lastAspectRatioRef.current = ratio;
				setAspect(ratio);
				setCropDetails(newCropDetails);
				setCompletedCrop(convertToPixelCrop(newCropDetails, width, height));
			}
		}
		
	}, [aspect]);

	/**
	 * A callback function triggered when saving the cropped image
	 * It will create a canvas element (not mounted to the DOM) on which it will draw the cropped image
	 * It will take into accopunt the set proportions, rotation and scale
	 * Once done it will create a blob from the canvas and upload the new image to the server
	 * 
	 * @returns {void}
	 */
	const saveImageHandler = React.useCallback((): void => {
		if(!imgElementRef.current || !completedCrop) return;

		const image = imgElementRef.current as HTMLImageElement;

		// Create a canvas element, which is not attached to the DOM
		const canvas = document.createElement('canvas');

		// Create a 2d context
		const ctx = canvas.getContext('2d');

		if(!ctx) {
			throw new Error('No 2d context available');
		}

		setIsLoading(true);

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		// devicePixelRatio slightly increases sharpness on retina devices
		// at the expense of slightly slower render times and needing to
		// size the image back down if you want to download/upload and be
		// true to the images natural size.

		// We changed pixel ratio from window.devicePixelRatio to 1 as figured the result was more accurate
		// Although this may have side effects on retina screens
		// https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
		const pixelRatio = 1;

		canvas.width = Math.floor(completedCrop.width * scaleX * pixelRatio);
		canvas.height = Math.floor(completedCrop.height * scaleY * pixelRatio);

		ctx.imageSmoothingQuality = 'high';

		const cropX = completedCrop.x * scaleX;
		const cropY = completedCrop.y * scaleY;

		const rotateRads = rotate * (Math.PI / 180);
		const centerX = image.naturalWidth / 2;
		const centerY = image.naturalHeight / 2;

		ctx.save();

		// Move the crop origin to the canvas origin (0,0)
		ctx.translate(-cropX, -cropY);

		// Move the origin to the center of the original position
		ctx.translate(centerX, centerY);

		// Rotate around the origin
		ctx.rotate(rotateRads);

		// Move the center of the image to the origin (0,0)
		ctx.translate(-centerX, -centerY);

		// Draw the image to the canvas
		ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
		ctx.restore();

		// Convert the canvas data to a blob
		canvas.toBlob(async (blob) => {
			if(!blob) return;
		
			// Create a form data object
			const formData = new FormData();

			// Create a file name based on the original file name and the pixel dimensions
			const originalFileName = imgName.replace(/\.(jpg|png|jpeg)$/i, '');
			const fileName = `${originalFileName}_${Math.round(completedCrop.width)}x${Math.round(completedCrop.height)}`;

			// Set form data
			formData.append('files[]', blob, `${fileName}.${imgExt}`);
			formData.append('target_folder', imgTargetFolder);
	
			axios.post('/media/upload', formData, {
				headers: {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'Content-Type': 'multipart/form-data'
				}
			}).then((res: UploadResult) => {
				const file = res.data.approved_files;
				uploadCompleted(file);
				modalClose();
			}).catch(() => {
				alert('Det gick inte att beskära bilden');
				setIsLoading(false);
			});
	
		  }, imgMime, 1);
	}, [completedCrop, rotate, imgMime, imgName, imgExt, imgTargetFolder, uploadCompleted, modalClose]);

	return  (
		<>
			{isLoading && (
				<Loader
					isDark={false}
					opacityEnabled={true}
				/>
			)}
			
			<ScWrapper>
			
				<ScCropWrapper>
					<ReactCrop
						crop={cropDetails}
						onChange={setCropHandler}
						onComplete={completedCropHandler}
						aspect={aspect}
						ruleOfThirds={true}
					>
						<img
							ref={imgElementRef}
							alt="Här är bilden som ska beskäras"
							crossOrigin="anonymous"
							src={props.imgSrc}
							style={{ transform: `rotate(${rotate}deg)` }}
							onLoad={imageLoadHandler}
						/>
					</ReactCrop>
				</ScCropWrapper>
				<ScToolsWrapper>
					<Label label="Rotation">
						<InputRange
							step="1"
							min="0"
							max="360"
							value={rotate}
							changed={changeRotationHandler}
							name="rotation"
						/>
					</Label>

					<Label label="Fast Bildförhållande">
						<CheckItem
							type="checkbox"
							checked={aspect !== undefined}
							changed={changeAspectRatioHandler}
						/>
					</Label>

					{aspect && (
						<Select
							name="aspectRatio"
							changed={changeAspectRatioHandler}
						>
							{IMAGE_CROP_ASPECT_RATIOS.map((aspectRatio) => {

								const isSelected = lastAspectRatioRef.current === aspectRatio.ratio;

								return (
									<option
										key={aspectRatio.id}
										value={aspectRatio.ratio}
										selected={isSelected}
									>
										{aspectRatio.name}
									</option>	
								);
								
							})}
						</Select>
					)}

					<Button onClick={saveImageHandler}>
						Beskär
					</Button>
				
				</ScToolsWrapper>
			</ScWrapper>
		</>
		
	);
};

export default CropModal;

const ScWrapper = styled.div`
	display: flex;
	padding: 16px;
`;

const ScCropWrapper = styled.div`
	flex: 1;
	padding: 8px;
	border: 1px solid #ccc;
	margin-right: 8px;
`;

const ScToolsWrapper = styled.div`
	width: 200px;
	padding: 8px;
	border: 1px solid #ccc;
`;

/**
 * This is to demonstate how to make and center a % aspect crop which is a bit trickier so we use some helper functions.
 * 
 * @param {number} mediaWidth 
 * @param {number} mediaHeight 
 * @param {number} aspect 
 * @returns {PercentCrop}
 */
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {

	const aspectCrop = makeAspectCrop({ unit: '%', width: 90 }, aspect, mediaWidth, mediaHeight);

	return centerCrop(
		aspectCrop,
		mediaWidth,
		mediaHeight
	);
}
