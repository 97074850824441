import React from 'react';
import { User } from '../../containers/ControlPanel/Users/model.Users';
import { Privileges } from '../../definitions/Privileges';
import { PrivilegesMode } from '../Permissions/privileges.types';
import useUserGroupPrivileges from '../Permissions/useUserGroupPrivileges';
import useAuth from '../useAuth/useAuth';
import {
	Category,
	Item,
	ItemIcon
} from '../useCheckboxTree/types.useCheckboxTree';

const usePrivilegesToCheckboxTree = () => {
	const { verifyUserPermission } = useAuth();
	const { getVerifiedUserPrivileges } = useUserGroupPrivileges();

	/**
	 * Creates an object in the format of a CheckboxTree basedon all available privileges.
	 *
	 * @param {any[]} allPrivileges All the privileges to create a base tree from.
	 * @return {Category[]} The updated tree.
	 */
	const getTreeFromPrivileges = React.useCallback(
		(allPrivileges: any[]): Category[] => {
			const tree: Category[] = [];

			// loop all categories for privileges
			// for (const [_x, _rawCat] of Object.entries(allPrivileges)) {
			for(const _privCategory of allPrivileges) {
				// skip category if does not have any privileges
				if(!Object.keys(_privCategory.privileges).length) continue;

				// map the privilege category to a CheckboxTree structure
				const category: Category = {
					id: _privCategory.id,
					//key: rawCat.key,
					name: _privCategory.name,

					// All items under this category.
					items: []
				};

				// loop all prvileges in the current category and map to TreeItem
				for(const rawPrivilege of Object.entries(
					_privCategory.privileges
				)) {
					// TODO extract data like this as there are no interfaces yet
					const privKey: keyof Privileges = rawPrivilege[0] as keyof Privileges;
					const privData: any = rawPrivilege[1];

					const item: Item = {
						key: privKey,
						text: privData.name,

						// if the element if checked or not.
						isChecked: false,
						isEnabled: false
					};

					if(category.items) category.items.push(item);
				}

				tree.push(category);
			}

			return tree;
		},
		[]
	);

	/**
	 * Enable categories and items that the authenticated user have as privileges.
	 *
	 * @param {Category[]} tree The tree/Category array to update.
	 * @return {Category[]} The updated tree.
	 */
	const enableWithAuthenticatedUser = React.useCallback(
		(tree: Category[]): Category[] => {
			return tree.map((category) => {
				// loop all permissions in the current category
				const updatedItems = category.items.map((item: Item) => {
					// check if the privilege is enabled
					// by checking the current logged-in user to have the privilege.
					const isItemEnabled: boolean = verifyUserPermission(
						item.key as keyof Privileges
					);

					// return new updated item
					return {
						...item,
						isEnabled: isItemEnabled
					};
				});

				// return updated category with it's updated items
				return {
					...category,
					items: updatedItems
				};
			});
		},
		[verifyUserPermission]
	);

	/**
	 * Check category's and item's checkboxes from a Privileges object.
	 *
	 * @param {Category[]} tree The tree/Category array to update.
	 * @param {Privileges} privileges The Privileges to check/unchekj.
	 * @return {Category[]} The updated tree.
	 */
	const checkItemsFromList = React.useCallback(
		(tree: Category[], privileges: Privileges): Category[] => {
			return tree.map((category) => {
				// loop all permissions in the current category
				const updatedItems = category.items.map((item: Item) => {
					// get if the privilege is checked from the users privileges
					const isChecked: boolean = !!privileges[
						item.key as keyof Privileges
					] as boolean;

					// console.debug(
					// 	'useAuth2CheckboxTree',
					// 	item,
					// 	`isChecked: ${isChecked}`
					// );

					// return new updated item
					return {
						...item,
						isChecked: isChecked
					};
				});

				// return updated category with it's updated items
				return {
					...category,
					items: updatedItems
				};
			});
		},
		[]
	);

	/**
	 * Add icons to items from an User's privileges.
	 *
	 * @param {Category[]} tree The tree/Category array to update.
	 * @param {User} user The user to interpolate the privileges with.
	 * @return {Category[]} The updated tree.
	 */
	const withIconfromUserPrivileges = React.useCallback(
		(tree: Category[], user: User): Category[] => {
			const userVerifiedPrivileges = getVerifiedUserPrivileges(user);

			return tree.map((category) => {
				// loop all permissions in the current category
				const updatedItems = category.items.map((item: Item) => {
					let icon: ItemIcon | null = null;

					// get data for the current permission from the hook
					//  by using the permission's key
					const privilegeMode =
						userVerifiedPrivileges[item.key as keyof Privileges];

					// generate icon from the data from the verifiedPermissionsHook
					// If the privilege from the verifiedPermissionsHook is
					//  missing then dont show an icon
					if(
						privilegeMode &&
						privilegeMode !== PrivilegesMode.INCLUDED
					) {
						icon = {
							icon: ['fas', 'circle'],
							tooltipText:
								privilegeMode === PrivilegesMode.ADDED
									? 'Rättighet tilldelad på användarnivå men inte på gruppnivå'
									: 'Rättighet tilldelad på gruppnivå men inte användarnivå',
							color:
								privilegeMode === PrivilegesMode.ADDED
									? '#91D794'
									: '#FC8282'
						};
					}

					// console.debug(
					// 	'useAuth2CheckboxTree',
					// 	item,
					// 	`privilegeMode: ${privilegeMode}`,
					// 	icon
					// );

					// return new updated item with icon if the icon is not null.
					if(icon) {
						return {
							...item,
							icon: icon
						};
						// otherwise return the item untouched
					} else {
						return item;
					}
				});

				// return updated category with it's updated items
				return {
					...category,
					items: updatedItems
				};
			});
		},
		[getVerifiedUserPrivileges]
	);

	return {
		getTreeFromPrivileges,
		enableWithAuthenticatedUser,
		checkItemsFromList,
		withIconfromUserPrivileges
	};
};

export default usePrivilegesToCheckboxTree;
