import { Privileges } from '../../definitions/Privileges';

export enum PrivilegesMode {
	ADDED = 'added',
	REMOVED = 'removed',
	INCLUDED = 'included',
}

export type VerifiedUserGroupPrivileges = Partial<Record<keyof Privileges, PrivilegesMode>>;

export interface PrivilegeData {
	id: number;
	name: string;
	privileges: {
		[key in keyof Privileges]: {
			enabled: boolean;
			id: number;
			key: keyof Privileges;
			name: string;
		}
	};
}
