import React from 'react';
import styled from 'styled-components/macro';
import BlockEmpty from '../BlockEmpty/BlockEmpty';

const BlockEmbed = (props) => {
	const contents = props.data.contents;

	let output = (
		<ScContainer className="cms-block cms-blockEmbed">
			<BlockEmpty
				icon={['fal', 'code']}
				title="Här var det tomt..."
				description="Tryck på kugghjulet för att redigera ditt embed-block"
			/>
		</ScContainer>
	);

	if(contents) {
		// in case we have some content we will clean so that it's safe from possibly malicious code
		// const sanitizedContent = DOMPurify.sanitize(contents, {
		// 	ALLOWED_TAGS: ['iframe', 'script'],
		// 	ALLOWED_ATTR: [
		// 		'src',
		// 		'width',
		// 		'height',
		// 		'frameborder',
		// 		'allow',
		// 		'allowfullscreen'
		// 	]
		// });

		// output = (
		// 	<ScContainer
		// 		className="cms-block cms-blockEmbed"
		// 		dangerouslySetInnerHTML={{ __html: sanitizedContent }}
		// 	/>
		// );
		output = (
			<ScContainer className="cms-block cms-blockEmbed">
				<BlockEmpty
					icon={['fal', 'code']}
					title="Embed-block"
					description="Platshållare för embed-block"
				/>
			</ScContainer>
		);
	}
	return output;
};

export default BlockEmbed;

const ScContainer = styled.div`
	/* display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column; */
	min-height: 100px;

	iframe {
		border: 0;
	}
`;
