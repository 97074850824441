import React from 'react';
import styled from 'styled-components/macro';
import { isArray } from 'lodash';
import { UserDetailsProps } from './model.UserDetails';
import axios from './../../../../utils/oc-axios';
import useModal from '../../../../hooks/Modal/useModal';
import TextInput from '../../../Forms/TextInput/TextInput';
import Label from '../../../Forms/Label/Label';
import {
	BasicContentModal,
	Button,
	InfoBox,
	Loader,
	Message,
	ModalContainer
} from '../../../UI';
import ControlPanelHeading from '../../ControlPanelHeading/ControlPanelHeading';
import { Category } from '../../../../hooks/useCheckboxTree/types.useCheckboxTree';
import { Select } from '../../../Forms';
import { ValidationTypes } from '../../../../hooks/useFormValidation/types';
import { Group } from '../../../../containers/ControlPanel/Groups/Groups.types';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import useGroups from '../../../../containers/ControlPanel/Groups/hooks/useGroups';
import Tree from '../../../CheckboxTree/Tree/Tree';
import useUsers from '../../../../containers/ControlPanel/Users/hooks/useUsers';

const UserDetails: React.FC<UserDetailsProps> = (props) => {
	const { modal, reloadUsersList } = props;
	const formValidation = props.formValidation;
	const resetGroupRightsModal = useModal();
	const deleteUserModal = useModal();
	const notification = useAlert()[1] as AlertDispatch;

	// Extract the user from the modal state
	const user = modal?.currentState;

	// Extract the updateState method from the modal
	const updateState = modal?.updateState;

	// If user id is set to -1 then we are creating a new user 
	const isCreatingNewUser = React.useMemo(() => user && user.id === -1, [user]);

	// If creating a new user and the user has a group set then we can use the given ID 
	// in order to only fetch that group instead of all groups.
	const selectedUserGroup = React.useRef<number | null>(isCreatingNewUser && user?.groups.length === 1 ? user.groups[0].id : null);

	// Data, states and methods used to handle groups
	const { data: groupsData, isLoading: isLoadingGroups, isFetching: isFetchingGroups, isError: hasGroupsError, refetch: refetchGroups } = useGroups(selectedUserGroup.current);
	
	// If only one groups is returned from the useGroups hook then it will be returned as an object
	// and needs to be converted to an array.
	const availableGroups = React.useMemo(() => {
		if(!groupsData) return;
		return !isArray(groupsData) ? [groupsData] : groupsData;
	}, [groupsData]);

	// State used to prevent from spamming the reset password button
	const [isTriggeredPassReset, setIsTriggeredPassReset] = React.useState<boolean>(false);

	// Hook that handles the users data
	const { data: userData, isLoading: isLoadingUser, isFetching: isFetchingUser, refetch: refetchUser, isError: hasUserError, fetchStatus } = useUsers(undefined, !isCreatingNewUser, user?.id);
	
	/**
	 * Update the modal state with the user data when it's fetched.
	 */
	React.useEffect(() => {
		if(userData && updateState) updateState({ $set: userData });
	}, [updateState, userData]);

	/**
	 * Generate the groups tree and enable the groups the user belong to.
	 */
	const userGroupsTreeItems = React.useMemo((): Category[] => {
		// Wait for user data to be fetched
		if(isFetchingUser) return [];

		const items = availableGroups ? availableGroups.map((group) => {

			const isUserGroup = user?.groups.find((_g) => _g.key === group.key);

			return {
				key: group.key,
				text: group.name,

				// checked if the user belong to the group
				isChecked: isUserGroup !== undefined,

				// enabled if the logged in user belong to it
				//  to disable giving away permissions users don't have
				// TODO: need the hook to suppert checking if the user elong to a group.
				// isEnabled: isMemberOfGroup(group.key),

				// TODO
				// Man ska inte kunan tilldela grupper man inte tillhör.
				isEnabled: true,

				items: [],
				id: group.id
			};
		}) : [];

		return [
			{
				id: 'groups',
				name: 'Alla',
				items: items
			}
		];
	}, [availableGroups, isFetchingUser, user?.groups]);

	/**
	 * Handle when a tree item is changed.
	 * 
	 * @return {void}
	 */
	const groupCategoryCheckedHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, _categoryID: string): void => {
		event.stopPropagation();
	}, []);

	/**
	 * Handle when a tree item is changed.
	 * 
	 * @return {void}
	 */
	const groupCheckedHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, groupKey: string): void => {
		if(!availableGroups) return;
		event.stopPropagation();

		// an object for the "query" for immutability-helper update
		let updateQry = {};

		// toggle the current state of the category.
		const addUserToGroup = event.target.checked;

		switch(addUserToGroup) {
			// add user to a new group
			case true:
				const newGroup = availableGroups.find((g: Group) => g.key === groupKey);

				updateQry = { $push: [newGroup] };
				break;

			// remove user from group
			case false:
				const groupIndex = user?.groups.findIndex(
					(g: Group) => g.key === groupKey
				);

				updateQry = { $splice: [[groupIndex, 1]] };
				break;
		}

		// updating user's privileges will automatically update tree as it
		//	depends on the users privileges
		if(updateState) {
			updateState({
				groups: {
					...updateQry
				}
			});
		}
		
	}, [availableGroups, updateState, user?.groups]);

	/**
	 * Open confirmation modal to delete current user
	 * 
	 * @return {void}
	 */
	const openDeleteUser = React.useCallback((): void => {
		deleteUserModal.open({
			actions: [
				{
					text: 'Nej',
					isDefault: true,
					action: (_oState: null, _cState: null, closeModal: any) => {
						closeModal();
					}
				},
				{
					text: 'Ja',
					action: (_oState: null, _cState: null, closeModal: any) => {
						let alertId = notification('SHOW', {
							priority: AlertPriorityTypes.loading,
							title: 'Användare tas bort',
							children: `Tar bort ${user?.name}...`
						});

						// close the confirmation modal
						modal?.close();

						// close the user details modal
						closeModal();

						axios
							.delete(`modules/users/${user?.id}`)
							.then(() => {
								notification('MODIFY', {
									alertID: alertId,
									priority: AlertPriorityTypes.info,
									title: 'Användare borttagen',
									children: `Användaren ${user?.name} har nu bilvit borttagen.`
								});

								// reload users list
								if(reloadUsersList) reloadUsersList();
							
							})
							.catch(() => {
								notification('MODIFY', {
									alertID: alertId,
									priority: AlertPriorityTypes.error,
									title: 'Ett eller flera fel hittades',
									children: `Det gick inte att ta bort användaren ${user?.name}.`
								});
							});
					}
				}
			]
		});
	}, [deleteUserModal, modal, notification, reloadUsersList, user]);

	/**
	 * Handles when a input field changes, updating state
	 * 
	 * @return {void}
	 */
	const fieldChangedHandler = React.useCallback((ev: any, elProps: any): void => {
		let newValue = ev.target.value;

		// do some modifications for the value of some fields
		switch(elProps.id) {
			case 'enabled':
				newValue = newValue === 'true' ? true : false;
		}

		if(updateState) {
			updateState({
				[elProps.id]: { $set: newValue }
			});
		}

	}, [updateState]);

	/**
	 * Trigger a password reset for the user.
	 */
	const resetpasswordHandler = React.useCallback(() => {
		if(!user) return;

		let alertId = notification('SHOW', {
			priority: AlertPriorityTypes.loading,
			title: 'Skickar återställningslänk',
			children: `Skickar en återställningslänk till ${user?.email}...`
		});

		axios
			.post('auth/password', {
				email: user?.email
			})
			.then(() => {
				notification('MODIFY', {
					alertID: alertId,
					priority: AlertPriorityTypes.success,
					title: 'Återställningslänken skickad',
					children: `Återställningslänk har blivit skickad till ${user?.email}`
				});

				setIsTriggeredPassReset(true);
			})

			.catch(() => {
				notification('MODIFY', {
					alertID: alertId,
					priority: AlertPriorityTypes.error,
					title: 'Ett fel har uppstått',
					children: `Ett fel inträffade när återställningslänken skickades till ${user?.email}, prova igen`
				});
			});
	}, [notification, user]);

	return (
		<>
			{isLoadingUser && fetchStatus !== 'idle' && <Loader />}
						
			{user &&
				deleteUserModal.getAsComponent(
					<BasicContentModal
						title={`Du håller på att ta bort användaren ${user?.name}`}
						text="Är du säker på att du vill ta bort?"
					/>
				)}

			{resetGroupRightsModal.getAsComponent(
				<BasicContentModal
					title="Du håller på att återställa rättigheterna"
					text="Är du säker på att du vill återställa rättigheterna till gruppinställningar?"
				/>
			)}

			<ModalContainer>

				{isFetchingUser && (
					<ScLoaderWrapper>
						<ScLoaderText>
							Användaren hämtas... 
						</ScLoaderText>
						<Loader
							hasNoBackdrop
							isDark
							size="20px"
						/>
					</ScLoaderWrapper>
				)}

				{!isFetchingUser && hasUserError && (
					<ScMessage
						size="16"
						icon={['fal', 'redo']}
						pressed={() => refetchUser()}
					>
						Ett fel inträffade när användaren hämtades, hämta igen
					</ScMessage>
				)}

				<ScUserInfo>
					<ScFlexSpaceBetween>
						<ControlPanelHeading>
							Användaruppgifter
						</ControlPanelHeading>
						{!isCreatingNewUser && (
							<ScRemoveUser onClick={openDeleteUser}>
								Ta bort användare
							</ScRemoveUser>
						)}
					</ScFlexSpaceBetween>

					<TextInput
						id="name"
						name="name"
						isRequired
						label="Namn"
						placeholder="Namn"
						value={user?.name ?? ''}
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={props.formValidation.errors['name']}
						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) =>
							formValidation.registerElement(ref, {
								required: true
							})}
					/>

					<TextInput
						id="firstname"
						name="firstname"
						label="Förnamn"
						placeholder="Förnamn"
						value={user?.firstname ?? ''}
						changed={fieldChangedHandler}
					/>

					<TextInput
						id="lastname"
						name="lastname"
						label="Efternamn"
						placeholder="Efternamn"
						value={user?.lastname ?? ''}
						changed={fieldChangedHandler}
					/>

					<TextInput
						id="login_name"
						name="login_name"
						isRequired
						label="Användarnamn"
						placeholder="Användarnamn"
						value={user?.login_name ?? ''}
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={formValidation.errors['login_name']}
						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) =>
							formValidation.registerElement(ref, {
								required: true
							})}
					/>

					<TextInput
						id="email"
						name="email"
						isRequired
						label="E-postadress"
						placeholder="E-postadress"
						value={user?.email ?? ''}
						type="text"
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={formValidation.errors['email']}
						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) =>
							formValidation.registerElement(ref, {
								required: true,
								validation: {
									type: ValidationTypes.EMAIL
								}
							})}
					/>

					<TextInput
						id="mobile_phone"
						name="mobile_phone"
						label="Mobilnummer"
						placeholder="Mobilnummer"
						value={user?.mobile_phone ?? ''}
						type="number"
						changed={fieldChangedHandler}
					/>

					<Select
						id="enabled"
						fullWidth
						label="Status"
						value={user?.enabled || isCreatingNewUser}
						changed={fieldChangedHandler}
					>
						<option value="true">
							Aktiverad
						</option>
						<option value="false">
							Inaktiverad
						</option>
					</Select>

					<Select
						id="language"
						fullWidth
						label="Språk"
						value={user?.language}
						changed={fieldChangedHandler}
					>
						<option value="en">
							Engelska
						</option>
						<option value="sv">
							Svenska
						</option>
						<option value="de">
							Tyska
						</option>
					</Select>

					{!isCreatingNewUser && (
						<Label
							label="Lösenord"
							description="Ett mail med en länk för att välja lösenord kommer att skickas ut till användaren."
						>
							<Button
								isAutoWidth
								isDisabled={isTriggeredPassReset}
								onClick={resetpasswordHandler}
							>
								Återställ lösenord
							</Button>
						</Label>
					)}

					{isCreatingNewUser && (
						<InfoBox>
							Ett mail med en länk för att välja lösenord kommer
							att skickas ut till användaren efter användaren är
							skapad.
						</InfoBox>
					)}
				</ScUserInfo>
				<ScGroupsAndRights>
					<ScGroups>
						<ScFlexSpaceBetween>
							<ControlPanelHeading>
								Grupper
							</ControlPanelHeading>
						</ScFlexSpaceBetween>

						{(isLoadingGroups || isFetchingGroups || isFetchingUser) && (
							<Loader
								hasNoBackdrop
								isDark
								size="20px"
							/>
						)}

						{!isFetchingGroups && hasGroupsError && (
							<ScMessage
								size="16"
								icon={['fal', 'redo']}
								pressed={() => refetchGroups()}
							>
								Ett fel inträffade när grupper hämtades, hämta igen
							</ScMessage>
						)}

						{!isLoadingGroups && !isFetchingGroups && !isFetchingUser && availableGroups && (
							<Tree
								treeItems={userGroupsTreeItems}
								categoryChecked={groupCategoryCheckedHandler}
								itemChecked={groupCheckedHandler}
								itemsIsHorizontal={availableGroups.length > 25}
								preselectedGroups={selectedUserGroup.current ? [selectedUserGroup.current.toString()] : undefined}
							/>
						)}
					</ScGroups>
				</ScGroupsAndRights>
			</ModalContainer>
		</>
	);
};
export default UserDetails;

const ScUserInfo = styled.div`
	display: flex;
	flex: 1;
	margin-right: 40px;
	flex-direction: column;
`;

const ScGroupsAndRights = styled.div`
	display: flex;
	flex: 1;
`;

const ScGroups = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 16px;
`;

const ScFlexSpaceBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	width: 100%;
	margin-top: 8px;
`;

const ScRemoveUser = styled.div`
	font-size: 12px;
	text-decoration: underline;
	color: var(--red-color);
	text-transform: uppercase;
	margin-left: 24px;
	white-space: nowrap;
	cursor: pointer;
`;

const ScMessage = styled(Message)`
	margin-bottom: 24px;
	margin-top: 16px;
`;

const ScLoaderText = styled.p`
	margin-right: 8px;
	font-style: italic;
`;

const ScLoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
`;