import React from 'react';
import { useDispatch } from 'react-redux';
import { BlockVideoProps, Tag } from './BlockVideo.types';
import axios from './../../../../utils/oc-axios';
import { Icon } from '../../../UI';
import BlockEmpty from '../BlockEmpty/BlockEmpty';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import { SHOW_VIMEO_VIDEO_TAGS } from '../../../../settings';
import TextEditor from '../../../../containers/TextEditor/TextEditor';
import { updateTextContent } from '../../../../store/actions/action-builder';

const BlockVideo: React.FC<BlockVideoProps> = (props) => {
	const { data } = props;

	// declare redux dispatcher
	const dispatch = useDispatch();

	// Holds the video tags if the video is a vimeo video
	const [tags, setTags] = React.useState<string[]>();

	// Used to show notifications
	const notification = useAlert()[1] as AlertDispatch;

	// Create classes based on the given data
	const positionClass = data?.content_position ? '--position-' + data.content_position : '--position-center';
	const themeClass = data?.content_theme ? '--theme-' + data.content_theme : '--theme-default';

	/**
	 * Get the appropriate url based on the given provider (e.g. 'youtube' or 'vimeo')
	 */
	const getURL = React.useCallback((id: string) => {
		switch(data?.provider) {
			case 'youtube':
				return `https://www.youtube.com/embed/${id}?autoplay=${data?.autoplay && !data?.thumbnail_active ? 1 : 0}
				&mute=${data?.autoplay && !data?.thumbnail_active ? 1 : 0}&rel=0&modestbranding=1`;
	
			case 'vimeo':
				return `https://player.vimeo.com/video/${id}?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=${data?.autoplay}&loop=${data?.autoplay}&muted=${data?.autoplay}`;
		
			default:
				break;
		}
	}, [data?.autoplay, data?.provider, data?.thumbnail_active]);

	// Set the appropriate desktop url
	const desktopURL = React.useMemo(() => {
		if(data?.video_id) return getURL(data.video_id);
		else return null;
	}, [data?.video_id, getURL]);

	// Set the appropriate mobile url
	const mobileURL = React.useMemo(() => {
		if(data?.video_id_mobile) return getURL(data.video_id_mobile);
		else return null;
	}, [data?.video_id_mobile, getURL]);

	/**
	 * Fetch video tags if the video is a vimeo video
	 */
	React.useEffect(() => {
		if(data?.provider === 'vimeo' && data?.video_id && SHOW_VIMEO_VIDEO_TAGS) {
			axios.get(`modules/vimeo/${data.video_id}/tags`)
				.then((resp) => {
					const tags = resp.data?.result?.map((tag: Tag) => tag.name);
					setTags(tags);
				})
				.catch(() => {
					notification('SHOW', {
						priority: AlertPriorityTypes.error,
						title: 'Ett fel inträffade',
						children: 'Kunde inte hämta video taggar'
					});
				});
		}
	}, [data?.provider, data?.video_id, notification]);

	// Set the image url based on which view is in use (e.g. 'desktop' or 'mobile')
	const imageSrc = React.useMemo(() => {
		switch(props.viewInUse) {
			case 'desktop':
				return data?.media ? data?.media.src : null;
	
			default:
				return data?.mobile_media ? data?.mobile_media.src : null;
		}
	}, [data?.media, data?.mobile_media, props.viewInUse]);

	/**
	 * Handles TextEditor content for specifik block
	 * 
	 * @param {string} content
	 * @param {any} block set as any since the TextEditor isn't typed
	 * @param {string} valueProp
	 * @returns {void}
	 */
	const setTextEditorContentHandler = React.useCallback((content: string, block: string, valueProp: string = 'caption') => {
		dispatch(updateTextContent(content, block, valueProp));
	}, [dispatch]);

	/**
	 * Return a thumbnail component
	 * 
	 * @param {string} imageSrc
	 * @returns {JSX.Element}
	 */
	const Thumbnail = React.useCallback((imageSrc: string): JSX.Element => (
		<>
			<Icon
				className="playIcon"
				icon={['fas', 'caret-circle-right']}
			/>
			<img
				className="videoThumbnail"
				alt=""
				src={imageSrc}
			/>
		</>
	), []);

	if(data && (desktopURL || mobileURL)) {
		return (
			<div className="cms-block cms-blockVideo">
				<div className="videoContainer">
					{!data.autoplay && data.thumbnail_active && imageSrc !== null && Thumbnail(imageSrc)}
					{mobileURL && (
						<iframe
							title="video"
							src={mobileURL}
							allow="accelerometer; autoplay; encrypted-media; fullscreen"
							className="mobileVideo"
						/>
					)}
					{desktopURL && (
						<iframe
							title="video"
							src={desktopURL}
							allow="accelerometer; autoplay; encrypted-media; fullscreen"
							className={data.video_id_mobile ? 'desktopVideo' : ''}
						/>
					)}

					{data.caption_active && (
						<div className={`captionWrapper ${positionClass}`}>
							<figcaption className={themeClass}>
								<TextEditor
									setTextEditorContent={setTextEditorContentHandler}
									block={props.id}
									valueProp="caption"
									initialText={data.caption}
									openInputModal={props.openLinkToolModal}
								/>
							</figcaption>
						</div>
					)}
				</div>
				{tags && (
					<div className="tags">
						{tags.map(tag => (
							<div
								className="tag"
								key={tag}
							>
								{tag}
							</div>
						))}
					</div>
				)}
			</div>
		);
	}

	return (
		<div className="cms-block cms-blockVideo">
			<BlockEmpty
				icon={['fal', 'video']}
				title="Här var det tomt..."
				description="Tryck på kugghjulet för att redigera din video"
			>
			</BlockEmpty>
		</div>
	);
};

export default BlockVideo;
