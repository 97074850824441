import React from 'react';
import styled from 'styled-components/macro';
import { Group } from './Groups.types';
import { GROUPS_LIST_COLUMNS } from './consts.Groups';
import useGroups, { filterGroupsBySearchText } from './hooks/useGroups';
import GroupDetailsModal from './GroupDetails/GroupDetailsModal';
import ModuleContainer from '../../../components/Content/ModuleContainer/ModuleContainer';
import Search from '../../../components/Search/Search';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import SkeletonTable from '../../../components/Skeletons/SkeletonTable/SkeletonTable';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import ReactTable from '../../../components/GenericTable/ReactTable/ReactTable';
import { Message } from '../../../components/UI';

const resultsPerPage = 50;

const Groups: React.FC = () => {
	// Hold the parameters used when fetching groups
	const [searchTerm, setSearchTerm] = React.useState('');

	// Data, states and methods used to handle groups
	const { data: groupsData, isLoading: isLoadingGroups, isFetching: isFetchingGroups, isError: hasGroupsError, refetch: refetchGroups } = useGroups();

	// Hold the groups that match the search term
	const filteredGroups = React.useMemo(() => filterGroupsBySearchText(searchTerm, groupsData), [groupsData, searchTerm]);
	
	// A ref used to access the Group detail modal
	const groupDetailsModalRef = React.useRef<React.ElementRef<typeof GroupDetailsModal>>(null);

	// Calculate the amount of table pages based on the total amount of groups and 
	// how many to display on each page in the table
	const amountPages = React.useMemo(() => {
		if(!filteredGroups) return 0;
		
		return Math.ceil(filteredGroups.length / resultsPerPage);
	}, [filteredGroups]);

	/**
	 * Reset the module to its initial state when the component is mounted.
	 * 
	 * @returns {void}
	 */
	const resetModuleHandler = React.useCallback((): void => {
		setSearchTerm('');
		refetchGroups();
	}, [refetchGroups]);

	/**
	 * Calls the openModal function in the GroupDetailsModal component 
	 * by using the ref linked to the modal.
	 * 
	 * In order to call the openModal function from the parent component, 
	 * the GroupDetailsModal component is created as a forwardRef component and
	 * a useImperativeHandle hook is used to be able to call the openModal function from the parent component.
	 * 
	 * Read more about forwardRef here: https://react.dev/reference/react/forwardRef
	 * Read more about useImperativeHandle here: https://react.dev/reference/react/useImperativeHandle
	 * 
	 * @param {Group | null}
	 * @returns {void}
	 */
	const openGroupDetails = React.useCallback((group: Group | null): void => { 
		groupDetailsModalRef.current?.openModal(group);
	}, []);

	/**
	 * Handles when user clicks the button to search.
	 * Will use the current value from the text input and set it to a local state
	 *
	 * @param {HTMLElement} input
	 */
	const searchButtonClickedHandler = React.useCallback((input: any) => {
		const value = input.current.value;

		setSearchTerm(value);
	}, []);

	/**
	 * Clears the search input texts when x button is clicked.
	 */
	const searchClearedHandler = React.useCallback(() => {
		setSearchTerm('');
	}, []);

	return (
		<ErrorBoundary resetted={resetModuleHandler}>
			<GroupDetailsModal
				ref={groupDetailsModalRef}
				refetchGroups={refetchGroups}
			/>

			<ModuleContainer
				hasButton
				buttonText="Skapa grupp"
				buttonIcon={['fal', 'plus']}
				header="Grupper"
				buttonOnClick={() => openGroupDetails(null)}
			>
				<ScSearch
					hasButton
					searchPlaceholder="Sök på namn, användarnamn eller e-postadress"
					searchBtnClicked={searchButtonClickedHandler}
					isDisabled={isLoadingGroups || isFetchingGroups}
					cleared={searchClearedHandler}
					enableSearchOnEnter
				/>

				{!isFetchingGroups && hasGroupsError && (
					<ScMessage
						size="16"
						icon={['fal', 'redo']}
						pressed={() => refetchGroups()}
					>
						Ett fel inträffade när grupper hämtades, ladda om
					</ScMessage>
				)}

				{!isLoadingGroups && !isFetchingGroups && filteredGroups && filteredGroups?.length === 0 && (
					<ScNoSearchResult>
						Inga grupper hittades med sökfrasen 
						{' '}
						&quot;
						{searchTerm}
						&quot;
						...
					</ScNoSearchResult>
				)}

				{(isLoadingGroups || isFetchingGroups) && (
					<SkeletonTable />
				)}

				{!isLoadingGroups && !isFetchingGroups && filteredGroups && filteredGroups?.length > 0 && (
					<ReactTable
						columns={GROUPS_LIST_COLUMNS}
						data={filteredGroups}
						totalResults={filteredGroups.length}
						rowClickedCallback={(row) => openGroupDetails(row.original)}
						hasNativePagination
						amountPages={amountPages}
						resultsPerPage={resultsPerPage}
					/>
				)}
			</ModuleContainer>
		</ErrorBoundary>
	);
};

export default withErrorBoundary(Groups);

const ScSearch = styled(Search)`
	margin-bottom: 24px;
`;

const ScMessage = styled(Message)`
	margin-bottom: 24px;
	margin-top: 16px;
`;

const ScNoSearchResult = styled.p`
	margin-bottom: 16px;
`;