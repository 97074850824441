import React from 'react';
import styled from 'styled-components/macro';
import { FormItemProps, ScFormItemProps } from './model.FormItem';
import { Icon } from '../../UI';
import TextInput from '../TextInput/TextInput';

const FormItem: React.FC<FormItemProps> = (props) => {
	const isExpandable = (props.allowed && props.allowed.length > 0);

	return (
		<ScWrapper>
			<ScFormItem isActive={props.isActive}>
				<ScInfoWrapper>
                    
					{!props.isLocked && (
						<ScDraghandle 
							data-is-draghandle={true}
						>
							<ScDraghandleIcon
								icon={['far', 'grip-lines']}
							/>
						</ScDraghandle>
					)}

					{props.icon && (
						<ScFormIcon
							icon={props.icon}
						/>
					)}

					{!props.isEditingName && (
						<ScText
							onClick={() => props.nameChangeEnabled(props.id)}
						>
							{props.name}
						</ScText>
					)}
					{props.isEditingName && (
						<ScTextInput
							omitLabel
							value={props.name}
							changed={(ev: React.ChangeEvent<HTMLInputElement>) => {
								props.nameChanged(ev, props.id);}}
							pressed={(ev: React.KeyboardEvent<HTMLInputElement>) => {
								props.nameChanged(ev, props.id);}}
						/>
					)}
				</ScInfoWrapper>
				
				<ScActionWrapper>
					{(props.isDefault || props.isDisabled) && (
						<ScStatus>
							{props.isDefault && (
								<span>
									Förvald
								</span>
							)}
							{props.isDisabled && (
								<span>
									Inaktiverad
								</span>
							)}
						</ScStatus>
					)}

					{(props.children || isExpandable) && (
						<ScControl onClick={props.expandClicked}>
							<ScIcon
								icon={['fal', props.isExpanded ? 'chevron-up' : 'chevron-down']}
							/>
						</ScControl>
					)}

					{props.actions && props.actions.map((action) => {
						return (
							<ScControlWrapper key={action.name}>
								{action.status && (
									<ScStatus>
										{action.status}
									</ScStatus>
								)}
								<ScControl onClick={action.clicked}>
									{action.element}
								</ScControl>
							</ScControlWrapper>
						);
					})}
					
					{props.settingsClicked && (
						<ScControl onClick={props.settingsClicked}>
							<ScIcon
								icon={[props.isActive ? 'fas' : 'fal', props.isActive ? 'cog' : 'gear']}
							/>
						</ScControl>
					)}
					
					{!props.isLocked && (
						<ScControl onClick={() => props.deleteClicked(props.parent, props.index, props.id)}>
							<ScIcon
								icon={['fal', 'trash']}
							/>
						</ScControl>
					)}
				</ScActionWrapper>
			</ScFormItem>
			
			{(props.isExpanded && (isExpandable || props.children)) && (
				<ScChildrenWrapper>
					{props.children}

					{props.allowed && props.allowed.map((item, key) => {
						return (
							<ScAdd
								onClick={() => props.addItemClicked(item.element, null, props.id)}
								key={key}
							>
								+ Lägg till 
								{' '}
								{item.name.toLowerCase()}
							</ScAdd>
						);
					})}
				</ScChildrenWrapper>
			)}

		</ScWrapper>
	);
};

export default FormItem;

const ScWrapper = styled.div`
    margin: 4px 0px 4px 0px;
	width: 100%;
`;

const ScFormItem = styled.div<ScFormItemProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
	flex: 1;
	height: 40px;
	padding: 0 16px;
	background-color: ${(props) => (props.isActive ? '#333' : '#d1d1d1')};
	color: ${(props) => (props.isActive ? '#fafafa' : '#333')};
`;

const ScInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
	flex: 1;
	overflow: hidden;
`;

const ScActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
	margin-left: 16px;
`;

const ScControlWrapper = styled.div`
	display: flex;
	margin-right: 8px;
	align-items: center;
`;

const ScControl = styled.div`
	margin-right: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	:last-child {
		margin-right: 0;
	}
`;

const ScDraghandle = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
	user-select: none;
	cursor: row-resize;
	margin-right: 16px;
`;

const ScDraghandleIcon = styled(Icon)`
    pointer-events: none;
	font-size: 14px;
`;

const ScText = styled.div`
	background: rgba(255, 255, 255, 0.0);
	padding: 0;
	transition: background ease 0.3s, padding ease 0.3s;
	font-size: 14px;
	font-weight: 300;
	display: block;
	height: 19px;
	margin-top: 1px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	:hover {
		background: rgba(255, 255, 255, 0.5);
		padding: 0 8px;
	}
`;

const ScFormIcon = styled(Icon)`
	margin-right: 8px;
	font-size: 14px;
`;

const ScIcon = styled(Icon)`
  cursor: pointer;
  font-size: 14px;
`;

const ScChildrenWrapper = styled.div`
  padding: 4px 8px;
  border: 1px solid #d1d1d1;
`;

const ScAdd = styled.div`
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 4px;
	margin-top: 4px;
	cursor: pointer;
`;

const ScStatus = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 8px;
	font-style: italic;
	font-weight: 300;
	margin-right: 8px;
`;

const ScTextInput = styled(TextInput)`
	padding: 0 8px;
	height: auto;
	border: 0;
	font-size: 14px;
	font-weight: 300;
`;