import React from 'react';
import update from 'immutability-helper';
import { useSelector } from 'react-redux';
import immutable from 'immutable';
import { DraggableDestination, DraggableInstruction, DraggableSource } from '../../hoc/DragDrop/DragDrop.types';
import { BlockFormItem, BlockFormType, DraggableFormItem, GeneralFormItemStructures } from '../../components/Builder/BlockTypes/BlockForm/BlockForm.types';
import { AlertDispatch } from '../useAlert/types.useAlert';
import useAlert, { AlertPriorityTypes } from '../useAlert';
import DragDrop from '../../hoc/DragDrop/DragDrop';
import { RootState } from '../../store/types/RootTypes';
import { BlockExamItem, BlockExamType } from '../../components/Builder/BlockTypes/BlockExam/BlockExam.types';
import { BuilderBlock, Changed } from '../../components/Builder/Block.types';
import { UpdateState } from '../useModal/useModal.model';

/**
 * This hook returns a set of functions and states to be used withing the FormItem component 
 * and to be provided to the useFormStructure hook in order to handle the render, creation and editing of form items
 */
const useFormItems = <T extends BlockFormType | BlockExamType>(blockData: T, updateState: UpdateState<BuilderBlock<T>>, currentState: BuilderBlock<T>, changed?: Changed<BuilderBlock<T>>) => {
	// Used to show notifications
	const notification = useAlert()[1] as AlertDispatch;

	// Used to show input field on form items in order to edit their name
	const [isEditingName, setIsEditingName] = React.useState<string | null>(null);

	// Keeps track of which items are expanded
	const [isExpanded, setIsExpanded] = React.useState<string[]>([]);

	// Get the general structure of form items
	const { structures }: { structures: GeneralFormItemStructures } = useSelector((selector: RootState) => selector.builder.formStructure);

	/**
     * Handle the drag position within it's parent
     * 
     * @param {DraggableSource<DraggableFormItem>} source 
     * @param {DraggableDestination<DraggableFormItem>} destination 
     * @param {DraggableInstruction} instruction 
	 * @returns {void}
     */
	const dragEndHandler = React.useCallback((source: DraggableSource<DraggableFormItem>, destination: DraggableDestination<DraggableFormItem>, instruction: DraggableInstruction): void => {
		if(!source || !destination || !instruction) return;

		const { properties: sourceProps, scope: sourceScope } = source;
		const { properties: destProps, scope: destScope } = destination;
		const { index: sourceIndex } = sourceProps;
		const { index: destIndex } = destProps;
		const { position } = instruction;
		let index;

		if(sourceScope !== destScope) return;

		// Determine what adjustments to drop position are necessary
		switch(true) {
			case sourceIndex < destIndex && position === 'top':
				index = destIndex - 1;
				break;

			case sourceIndex > destIndex && position === 'bottom':
				index = destIndex + 1;
				break;

			default:
				index = destProps.index;
		}
        
		// Find the parent and update it's children
		const parent = blockData.items[destProps.parent];
		const updatedParent = update(parent, {
			children: {
				$splice: [
					// Remove the element from it's current position
					[sourceProps.index, 1],
	
					// Add the element at it's new position
					// convert sourceProps.id (number) to string as childrens is an array of strings.
					[index, 0, sourceProps.id.toString()]
				]
			}
		});

		// Update the current state with the new parent's children
		const updatedState = immutable.setIn(blockData, ['items', destProps.parent, 'children'], updatedParent.children);
        
		// Update modal state
		updateState({
			data: {
				$set: updatedState
			}
		}).then((updatedState) => {
			if(changed) changed(updatedState);
		}).catch(() => {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Ett fel inträffade',
				children: 'Det gick inte att ändra positionen'
			});
		});

	}, [blockData, changed, notification, updateState]);

	/**
     * Function feeded to a hook callback
     * When called it will create a new Drag and Drop scope with a complete set of draggable items
     * 
     * @param {JSX.Element[] | undefined} children 
     * @param {string} parent 
     * @returns {JSX.Element}
     */
	const createDragDropScope = React.useCallback((children: JSX.Element[] | undefined, parent?: string): JSX.Element => {
		const scope = parent ? `scope-${parent}` : 'scope-root';
		return (
			<DragDrop
				scope={scope}
				onDragEnd={dragEndHandler}
			>
				{children}
			</DragDrop>
		);
	}, [dragEndHandler]);

	/**
     * Add or remove item key from isExpanded to show/hide the item's children
     * 
     * @param {string} itemKey
     * @returns {void}
     */
	const expandClickedHandler = React.useCallback((itemKey: string): void => {
		if(isExpanded.includes(itemKey)) {
			const newList = isExpanded.filter(key => key !== itemKey);
			setIsExpanded(newList);
		} else {
			setIsExpanded([...isExpanded, itemKey]);	
		}
	}, [isExpanded]);

	/**
     * Adds an appropriate icon to a FormItem
     * 
     * @param {BlockExamItem | BlockFormItem} item
     * @returns {string|undefined}
     */
	const getIcon = React.useCallback((item: BlockExamItem | BlockFormItem): string|undefined => {
		if(!item.type) return;
		const itemStructure = structures[item.type];

		if('icons' in itemStructure) {
			const { input_type } = item;
			
			const icons = itemStructure.icons;
			if(icons && input_type && input_type in icons) {
				return icons[input_type];
			}
		}

		if('icon' in itemStructure) {
			const icon = itemStructure.icon;
			if(icon) return icon;
		}

		return;
	}, [structures]);

	/**
     * Keeps track of an item that is currently opened for name editing
     * 
     * @param {string} itemKey
	 * @returns {void}
     */
	const enableEditNameHandler = React.useCallback((itemKey: string): void => {
		setIsEditingName(itemKey);
	}, []);

	/** 
     * Updates the name property of a FormItem
     * When clicking Enter it will disable name editing
     * 
     * @param {React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>} ev 
     * @param {string} itemKey 
	 * @returns {void}
     */
	const changeItemNameHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>, itemKey: string): void => {
		
		// Preview the name change in the builder when clicking Enter
		if('key' in ev && ev.key === 'Enter') {
			if(changed) changed(currentState);
			setIsEditingName(null);
			return;
		} 

		const target = ev.target as HTMLInputElement;
		const value = target.value;

		// Update the current state with the new name
		const updatedState = immutable.setIn(blockData, ['items', itemKey, 'name'], value);
		
		if(updateState && updatedState) {
			updateState({
				data: {
					$set: updatedState
				}
			});
		}
	}, [blockData, changed, currentState, updateState]);

	return {
		expandClickedHandler,
		createDragDropScope,
		getIcon,
		enableEditNameHandler,
		isEditingName,
		changeItemNameHandler,
		isExpanded,
		setIsExpanded
	};
};

export default useFormItems;