import React, { useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import styled from 'styled-components/macro';
import ChooseNavItemDialog from './ChooseNavItemDialog';
import axios from '../../utils/oc-axios';
import {
	addedNewItem,
	socketAddedNewItem,
	socketChangePosition,
	socketUpdatedNavItem,
	socketRemoveItem
} from '../../components/Tree/stateManager';
import useModal from '../../hooks/Modal/useModal';
import useFormValidation from '../../hooks/useFormValidation';
import useSocket from '../../hooks/useSocket';
import Tree from '../../components/Tree/Tree';
import PageSettings from '../SideDrawer/Settings/Page/PageSettings';
import FolderSettings from '../SideDrawer/Settings/Folder/FolderSettings';
import { BasicContentModal } from '../../components/UI';
import {
	fetchAllNavigations,
	fetchAllTemplates,
	removeNavigationItem,
	duplicatePage,
	duplicateNavigationFolder,
	fetchAllNavigationTypes,
	updatePage
} from '../../store/thunks/thunk-cache';
import { updateNavigationItems } from '../../store/actions/action-cache';
import { SkeletonTree } from '../../components/Skeletons';
import LinkSettings from '../SideDrawer/Settings/Link/LinkSettings';
import useAuth from '../../hooks/useAuth/useAuth';
import { NavigationPrivileges } from '../../definitions/Privileges';
import usePageLanguage from '../../hooks/usePageLanguage/usePageLanguage';
import { SUPPORTED_LANGUAGES } from '../../settings';
import useHomePages from '../../hooks/useHomePages/useHomePages';

/**
 * @param {string} selectedNavigation	The nav to highlight and expand to as curently selected
 *
 * @param {function} itemDoubleClicked	Callback to execute when the user double clicks an item
 * @param {function} itemSaved			Callback to execute when a menu item is saved.
 */
const MainNavigation = (props) => {
	const { itemClicked, enableCache } = props;
	
	// redux dispatcher
	const dispatch = useDispatch();

	// get auth functions
	const { verifyUserPermission } = useAuth();

	// Returns the active language (used to filter nav items)
	const { activeLanguage } = usePageLanguage();

	// fetch stored templates (tom designmall, fullbredd, etc)
	const templates = useSelector((state) => state.cache.templates.data);

	// fetch stored navigation types (e.g. page, folder, link)
	const navigationTypes = useSelector(
		(state) => state.cache.navigationTypes.data
	);

	// fetch the actual tree data structure
	const navigationItems = useSelector(
		(state) => state.cache.navigation.items
	);

	// Holds an object with navigation items filtered based on the specified language
	const [translatedNavItems, setTranslatedNavItems] = React.useState();

	// Method to check if there is a gap where no home page is set
	const { checkHomePages } = useHomePages();

	// Modal to show alert about the parent missing translation for the selected language
	const missingTranslatedParentModal = useModal();

	// items that should be disabled from being selected.
	const disabledItems = useMemo(() => {
		return (
			Object.values(navigationItems)
				.filter((item) => {
					let disableItem = false;

					// if there are no filters, don't disable the item.
					if(!props.disabledItemsFilters) return false;

					// iterate all filters to check if the item match one of them, thus disabling the item
					for(const filter of props.disabledItemsFilters) {
					// don't disable the item if it doesn't contain a property named after the filter property.
					//	the filter can't be applied to the item.
						if(!item.hasOwnProperty(filter.property)) return false;

						// disable the item if it matches one filter, skipping next filters as it already is disabled.
						if(item[filter.property] === filter.value) {
							disableItem = true;
							break;
						}
					}

					return disableItem;
				})
				.map((item) => item.uuid)
		);
	}, [navigationItems, props.disabledItemsFilters]);

	const socket = useSocket({
		'navigations.items.new': (data) =>
			dispatch(
				updateNavigationItems(socketAddedNewItem(navigationItems, data))
			),
		'navigations.items.update': (data) =>
			dispatch(
				updateNavigationItems(
					socketChangePosition(navigationItems, data)
				)
			),
		'navigations.items.edit': (data) =>
			dispatch(
				updateNavigationItems(
					socketUpdatedNavItem(navigationItems, data)
				)
			),
		'navigations.items.delete': (data) =>
			dispatch(
				updateNavigationItems(socketRemoveItem(navigationItems, data))
			)
	});

	// shows a new modal with root navigation category specific allowances
	// if only one template OR one type is allowed this modal will not show
	const addNavItemModal = useModal();

	// shows page settings specific modal content
	const pageSettingsModal = useModal();

	// shows folder settings specific modal content
	const folderSettingsModal = useModal();

	// shows link settings specific modal content
	const linkSettingsModal = useModal();

	// shows confirmation modal when removing a nav item
	const deleteNavItemModal = useModal();

	// mutable object to pass allowances to component showing allowed
	// templates and types. See modal definiton "addNavItemModal"
	const rootNavigationAllowances = useRef({});

	// validation...
	const formValidation = useFormValidation();

	/**
	 * When component mounts then we will fetch
	 * - navigation (actual tree)
	 * - templates available
	 * - types available
	 *
	 * and dispatch them to the redux store where
	 * they will be stored in cache
	 */
	React.useEffect(() => {
		dispatch(fetchAllNavigations(!enableCache)).then(() => {
			dispatch(fetchAllTemplates());

			dispatch(fetchAllNavigationTypes());
		}).catch((err) => {
			console.error('[MainNavigation]', 'useEffect', 'fetchAllNavigations', err);
		});
	}, [dispatch, enableCache]);

	/**
	 * Filters navigationItems based on the specified language and wether or not to show all items,
	 * and returns the filtered object
	 * 
	 * @param {string|null} otherLanguageToShow
	 * @returns {object} 
	 */
	const getFilteredNavItems = React.useCallback((otherLanguageToShow = null) => {
		const items = Object.values(navigationItems).reduce((prev, item) => {
			const id = item.is_root ? 'r' + item.id : item.id;
			let isTranslated = false;
			let isHidden = true;
			let translatedItem;
			let updatedItem = item;
			const translation = item.l10n;

			// Check if the item has a translation for the selected language
			if(translation && activeLanguage in translation) {
				translatedItem = translation[activeLanguage];
				isTranslated = true;
				isHidden = false;
			}

			// If we want to show items for another language and there's no translation for the selected language we get the 
			// translation for the other language instead
			if(otherLanguageToShow && translation && !(activeLanguage in translation) && otherLanguageToShow in translation) {
				translatedItem = translation[otherLanguageToShow];
				isTranslated = false;
				isHidden = false;
			}

			if(translatedItem) {
				// Update title, slug and is_draft with the translated object's values
				updatedItem = {
					...updatedItem,
					title: translatedItem.title,
					slug: translatedItem.slug,
					is_draft: translatedItem.is_draft
				};
			}

			// Always show root items and newly added items
			if(item.is_root || item.id === 'add') {
				isTranslated = true;
				isHidden = false;
			}

			return {
				...prev,
				[id]: {
					...updatedItem,
					// This prop is used to control the actions for the nav item
					// (actions are restricted while is_translated is false)
					is_translated: isTranslated,
					// This prop is used to hide items that's missing a translation for the selected langauge
					is_hidden: isHidden
				}
			};
		}, {});

		return items;
	}, [activeLanguage, navigationItems]);

	/**
	 * Update the translatedNavItems state based on active language and wether or not to show all items
	 */
	React.useEffect(() => {
		const items = getFilteredNavItems(props.otherLanguageToShow);

		setTranslatedNavItems(items);
	}, [getFilteredNavItems, props.otherLanguageToShow]);

	/**
	 * Will either open a modal where allowed templates
	 * and/or types are listed.
	 * Or dispatch "addChosenNavItemHandler" if only one
	 * template/type is available for within the
	 * root navigation category scope
	 *
	 * @param {Event} ev
	 * @param {object} data
	 * @param {object} suggestedState
	 * @param {object} fullProps
	 */
	const addNavItemHandler = async (_ev, _data, suggestedState, fullProps) => {
		const rootNavigationId = fullProps.rootNavigationId;
		const allowances = navigationItems[rootNavigationId].allowances;
		const amountTemplates = allowances.templates.length;
		const amountTypes = allowances.types.length;

		// Open modal with options given in the render of this component in addNavItemModal.getAsComponent()
		// Store the suggestedState in the modals state to easy be able to acces and send it in the callbacks

		if(
			amountTemplates > 1 ||
			amountTypes > 1 ||
			(amountTemplates === 1 && amountTypes === 1)
		) {
			rootNavigationAllowances.current = allowances;
			addNavItemModal.open({
				title: 'Lägg till',
				isDismissable: true,
				actions: [
					{
						text: 'Stäng',
						isDefault: true,
						isVisible: false,
						action: (originalState, currentState, closeModal) =>
							closeModal()
					}
				],
				state: suggestedState
			});
		} else {
			amountTemplates > 0
				? addChosenNavItemHandler(
					'page',
					allowances.templates[0],
					suggestedState
				  )
				: addChosenNavItemHandler(
					allowances.types[0],
					null,
					suggestedState
				  );
		}
	};

	/**
	 * Duplicate a navigation page.
	 *
	 * @param {object} navItem  The navigation page to duplicate.
	 */
	const duplicateNavPage = async (navItem) => {
		dispatch(duplicatePage(navItem.uuid, navItem.navigation_id));
	};

	/**
	 * Duplicate a navigation folder.
	 *
	 * @param {object} navItem  The navigation folder to duplicate
	 */
	const duplicateNavFolder = async (navItem) => {
		dispatch(duplicateNavigationFolder(navItem.navigation_id));
	};

	/**
	 * Duplicate a navigation link
	 *
	 * @param {object} navItem  The navigation link to duplicate.
	 */
	const duplicateNavLink = async (navItem) => {
		throw new Error('TODO');
		//dispatch(duplicatePage(navItem.uuid, navItem.id));
	};

	/**
	 * Removes a nav item from the state and the reference in it's parent.children
	 *
	 * @param {object} navItem 		The navItem to remove, data from Tree
	 */
	const removeNavItem = React.useCallback(async (navItem) => {
		const navID = navItem.navigation_id;
		const parentID = navItem.parent_reference;

		dispatch(
			updateNavigationItems(
				update(navigationItems, {
					[navID]: {
						$merge: {
							isLoading: true
						}
					}
				})
			)
		);

		// Remove item from navigation state.
		dispatch(removeNavigationItem(navID, parentID, socket.id));
	}, [dispatch, navigationItems, socket.id]);

	/**
	 * Save settings for navigation item when clicked saved in the settings modal.
	 *
	 * @param {string} itemType			The type of the nav item, like folder, link, page.
	 * @param {object} updatedNavItem
	 */
	const saveNavigationItem = React.useCallback(async (itemType, updatedNavItem) => {
		// Get original item as updatedNavItem does not contain all the data needed like the 'id'
		const originalNavItem = Object.values(navigationItems).find(
			(item) => item.uuid === updatedNavItem.uuid
		);

		// Set isLoading to true to show the loading skeleton for that nav item  and dispatch to state.
		dispatch(
			updateNavigationItems(
				update(navigationItems, {
					[originalNavItem.id]: {
						$merge: {
							isLoading: true
						}
					}
				})
			)
		);

		// Convert the template id from "string" to "int"
		// @todo ismael, muterat objekt
		updatedNavItem.template_id = +updatedNavItem.template_id;

		const updatedItem = update(originalNavItem, {
			$merge: {
				...updatedNavItem,
				socket: socket.id,

				// disable the sksleton as this data will be used to update the state
				// 	when the back-end request is completed.
				isLoading: false
			}
		});

		// Generate updated state
		const updatedState = update(navigationItems, {
			[originalNavItem.id]: {
				$merge: updatedNavItem
			}
		});

		// If navitem is a PAGE, trigger thunk instead of a request
		if(itemType === 'page') {
			// The back-end call will be done internally in the redux-thunk.
			await dispatch(updatePage(updatedNavItem.uuid, updatedItem));
		} else {
			if(itemType === 'link') {
				let prefix = '';

				switch(true) {
					case updatedNavItem.uri_type === 'email':
						prefix = 'mailto:';
						break;

					case updatedNavItem.uri_type === 'phone':
						prefix = 'tel:';
						break;

					default:
						break;
				}

				// update the navItem object with the correct values.
				updatedNavItem = update(updatedNavItem, {
					// append rpefix (ex. mailto:) to the value (ex: info@google.com)
					// to make something like this, mailto:info@google.com
					uri: { $set: `${prefix}${updatedNavItem.uri}` },

					// remote 'uri_type' as back-end dont use it.
					$unset: ['uri_type']
				});
			}

			// Update in the back-end
			await axios.put(
				`navigations/settings/${itemType}s/${updatedNavItem.uuid}`,
				updatedNavItem
			);

			// Update navigation item
			dispatch(updateNavigationItems(updatedState));
		}

		if(props.itemSaved) await props.itemSaved(updatedNavItem);
	}, [dispatch, navigationItems, props, socket.id]);

	const treeExpansioHandler = (id, status) => {
		return status === 'expanded';
	};

	/**
	 * Triggered when use have chosen by clicking a navItem type
	 * from the modal opened in adNavItemHandler
	 *
	 * @param {object} suggestedState
	 */
	const addChosenNavItemHandler = (
		choosenType,
		templateId,
		suggestedState
	) => {
		const state = suggestedState || addNavItemModal.originalState;

		// Change the type of the navigationItem to the one choose as default is page
		const updatedState = update(state, {
			add: {
				type: { $set: choosenType },
				template_id: { $set: templateId }
			}
		});

		// Update the navigationItems state with the new input element
		dispatch(updateNavigationItems(updatedState));

		addNavItemModal.close();
	};

	/**
	 * Triggered by the Tree as a callback function for when
	 * an input summoned in order to create a new item was either blurred
	 * or registrered an "Enter"-keypress.
	 *
	 * If a new item was successfully created the new state is dispatched
	 * and stored in redux
	 *
	 * @param {Event} origEvent (Object containing original event dispatched)
	 * @param {object} input (Object containing info to create item)
	 * @param {string} text (String desc. item name)
	 * @param {string} rootNavId (String desc. root navigation id)
	 */
	const inputDoneAddNavItem = async (ev, input, text, rootNavId) => {
		ev.stopPropagation();
		const isAddingTranslation = !input.is_translated;
		let updatedState;

		const parentId = input.parent_reference;
		const parent = navigationItems[parentId];
		const itemIndex = parent.children.indexOf(input.id);

		let data = {
			navigation_id: input.rootNavigationId ?? rootNavId,
			navigation_type: navigationItems.add ? navigationItems.add.type : null,
			template_id: input.template_id,
			parent_id: input.is_child ? parentId : null,
			title: text,
			socket: socket.id
		};

		// If item already exist but miss translation for the selected language
		if(isAddingTranslation) {
			data = {
				...data,
				navigation_type: input.type,
				uuid: input.uuid
			};
		}

		try {
			const resp = await axios.post('navigations/items', data);

			if(isAddingTranslation) {
				updatedState = update(navigationItems, {
					[input.id]: {
						l10n: {
							$merge: { 
								[activeLanguage]: {
									title: resp.data.title,
									slug: resp.data.slug,
									is_draft: resp.data.is_draft
								} 
								
							}
						},
						$unset: ['input']
					}
				});

			} else {
				const item = {
					...resp.data,
					navigation_id: resp.data.id,
					publish_date: null,
					unpublish_date: null,
					l10n_code: activeLanguage,
					template_id: input.template_id,
					l10n:  {
						[activeLanguage]: {
							title: resp.data.title,
							slug: resp.data.slug,
							is_draft: resp.data.is_draft
						}
					}
				};

				updatedState = addedNewItem(navigationItems, item);
			}

		} catch(e) {
			console.error(
				'[MainNavigation]',
				'onInputDoneAddNavItem',
				'[Could not add folder]',
				e
			);

			if(isAddingTranslation) {
				updatedState = update(navigationItems, {
					[input.id]: {
						$unset: ['input']
					}
				});
			} else {
				updatedState = update(navigationItems, {
					[parentId]: {
						children: {
							$splice: [[itemIndex, 1]]
						}
					},
					$unset: [input.id]
				});
			}
		}

		dispatch(updateNavigationItems(updatedState));
	};

	/**
	 * Callback that receives data from tree
	 * In most cases it is enough simply using the "updateInstructions" parameter
	 * Which contains a new state for the tree + an accurate axios payload
	 *
	 * @param {object} updateInstructions
	 * @param {object} dragSource
	 * @param {object} dragDestination
	 * @param {object} dragInstruction
	 * @param {Event} origEv
	 */
	const dragEndHandler = async (updateInstructions, dragSource) => {
		const { updatedState, axiosPayload } = updateInstructions;
		const sourceProperties = dragSource.properties;

		dispatch(updateNavigationItems(updatedState));

		await axios.put(`navigations/items/${sourceProperties.itemId}`, {
			...axiosPayload,
			socket: socket.id
		});
	};

	/**
	 * Opens a settings dialog for a page
	 *
	 * @param {Event} ev
	 * @param {object} navItemProps
	 * @param {object} navNode
	 */
	const openPageSettings = async (ev, navItemProps, navItem) => {
		ev.stopPropagation();

		const modalActions = [
			{
				text: 'Spara',
				action: async (originalState, currentState, closeModal) => {
					formValidation.submit(async () => {
						closeModal();
						checkHomePages();

						await saveNavigationItem('page', currentState.page);
					});
				}
			},
			{
				text: 'Radera',
				isVisible: verifyUserPermission(NavigationPrivileges.ARCHIVE),
				action: (originalState, currentState, closeModal) => {
					formValidation.resetErrors();
					openDeleteNavItem(navItem.item, closeModal);
					checkHomePages();
				}
			},
			{
				text: 'Avbryt',
				isDefault: true,
				action: (originalState, currentState, closeModal) => {
					formValidation.resetErrors();
					closeModal();
					checkHomePages();
				}
			}
		];

		try {
			pageSettingsModal.open({
				title: 'Ändrar sida',
				position: 'right',
				actions: modalActions,
				state: {
					page: navItem.item,
					dataFetched: false,

					// the navItem 's root navigation, used for filtering not allowed templates.
					rootNavigationId: navItem.rootNavigationId
				}
			});
		} catch(err) {
			console.error(
				'[MainNavigation] openPageSettings',
				'Axios error',
				err
			);
		}
	};

	/**
	 * Opens a settings dialog for a page
	 *
	 * @param {Event} ev
	 * @param {object} navItemProps
	 * @param {object} navItem
	 */
	const openFolderSettings = async (ev, navItemProps, navItem) => {
		ev.stopPropagation();

		const modalActions = [
			{
				text: 'Spara',
				action: async (originalState, currentState, closeModal) => {
					formValidation.submit(async () => {
						closeModal();
						await saveNavigationItem('folder', currentState);
					});
				}
			},
			{
				text: 'Radera',
				isVisible: verifyUserPermission(NavigationPrivileges.ARCHIVE),
				action: (originalState, currentState, closeModal) => {
					formValidation.resetErrors();
					openDeleteNavItem(navItem.item, closeModal);
				}
			},

			{
				text: 'Avbryt',
				isDefault: true,
				action: (originalState, currentState, closeModal) => {
					formValidation.resetErrors();
					closeModal();
				}
			}
		];

		try {
			folderSettingsModal.open({
				title: 'Ändrar mapp',
				position: 'right',
				actions: modalActions,
				state: {
					...navItem.item,
					dataFetched: false
				}
			});
		} catch(err) {
			console.error(
				'[MainNavigation] openFolderSettings',
				'Axios error',
				err
			);
		}
	};

	/**
	 * Opens a settings dialog for a page
	 *
	 * @param {Event} ev
	 * @param {object} navItemProps
	 * @param {object} navItem
	 */
	const openLinkSettings = React.useCallback(async (ev, navItemProps, navItem) => {
		ev.stopPropagation();

		const modalActions = [
			{
				text: 'Spara',
				action: async (originalState, currentState, closeModal) => {
					formValidation.submit(async () => {
						closeModal();
						await saveNavigationItem('link', currentState);
					});
				}
			},
			{
				text: 'Radera',
				isVisible: verifyUserPermission(NavigationPrivileges.ARCHIVE),
				action: (originalState, currentState, closeModal) => {
					openDeleteNavItem(navItem.item, closeModal);
				}
			},
			{
				text: 'Avbryt',
				isDefault: true,
				action: (originalState, currentState, closeModal) =>
					closeModal()
			}
		];

		try {
			linkSettingsModal.open({
				title: 'Ändrar länk',
				position: 'right',
				actions: modalActions,
				state: {
					...navItem.item,
					dataFetched: false
				}
			});
		} catch(err) {
			console.error(
				'[MainNavigation] openLinkSettings',
				'Axios error',
				err
			);
		}
	}, [formValidation, linkSettingsModal, openDeleteNavItem, saveNavigationItem, verifyUserPermission]);

	/**
	 * Open confirmation modal to delete current nav item
     * 
     * @param {object} navItem
     * @param {function} closeParentModal
	 */
	const openDeleteNavItem = useCallback((navItem, closeParentModal) => {
		const modalActions = [
			{
				text: 'Nej',
				isDefault: true,
				action: (
					originalState,
					currentState,
					closeModal
				) => {
					closeModal();
				}
			},
			{
				text: 'Ja',
				action: (
					originalState,
					currentState,
					closeModal
				) => {
					removeNavItem(navItem);
					closeModal();
					closeParentModal();
				}
			}
		];
	
		try {
			deleteNavItemModal.open({
				actions: modalActions,
				state: {
					item: navItem,
					dataFetched: false
				}
			});
		} catch(err) {
			console.error(
				'[MainNavigation] openDeleteNavItem',
				'Axios error',
				err
			);
		}
	}, [deleteNavItemModal, removeNavItem]);

	const navigationHeaderOpts = [
		{
			icon: ['fal', 'plus'],
			type: 'page',
			hasPermission: verifyUserPermission(NavigationPrivileges.CREATE),
			showOnlyForType: ['folder'],
			middleware: 'middlewareAddInput',
			action: addNavItemHandler
		}
	];

	const navigationItemActions = [
		{
			icon: ['fal', 'plus'],
			type: 'folder',
			hasPermission: verifyUserPermission(NavigationPrivileges.CREATE),
			showOnlyForType: ['folder'],
			showForStackableItems: true,
			middleware: 'middlewareAddInput',
			action: addNavItemHandler
		}
	];

	if(!props.disableSettings) {
		navigationItemActions.push({
			icon: ['fal', 'gear'],
			showOnlyForType: ['page'],
			type: 'settings',
			hasPermission: verifyUserPermission(NavigationPrivileges.EDIT),
			action: openPageSettings
		});

		navigationItemActions.push({
			icon: ['fal', 'gear'],
			showOnlyForType: ['folder'],
			type: 'settings',
			hasPermission: verifyUserPermission(NavigationPrivileges.EDIT),
			action: openFolderSettings
		});

		navigationItemActions.push({
			icon: ['fal', 'gear'],
			showOnlyForType: ['link'],
			type: 'settings',
			hasPermission: verifyUserPermission(NavigationPrivileges.EDIT),
			action: openLinkSettings
		});
	}

	/**
	 * If the item's parent isn't translated show an alert and return false
	 * otherwise return true
	 * 
	 * @returns {boolean}
	 */
	const hasTranslatedParentHandler = React.useCallback((item) => {
		const parentReference = item.parent_reference;
		const parent = translatedNavItems[parentReference];
		
		if(!parent.is_translated) {
			missingTranslatedParentModal.open({
				position: 'center',
				hideBackdrop: false,
				isDismissable: true,
				actions: [
					{
						text: 'OK',
						isDefault: true,
						action: (_originalState, _currentState, closeModal) => {
							closeModal();
						}
					}
				],
				state: {}
			});

			return false;
		} 
		
		return true;
	}, [missingTranslatedParentModal, translatedNavItems]);

	/**
	 * Show an input in the tree if clicking on an untranslated item,
	 * otherwise call itemClicked callback
	 * 
	 * @param {Event} ev
	 * @param {object} item
	 * @returns {void}
	 */
	const itemClickedHandler = React.useCallback((_ev, item) => {
		if(!item.is_translated) {
			const hasTranslatedParent = hasTranslatedParentHandler(item);

			// The item's parent has to have a translation before we can 
			// show an input to add a translation to the item
			if(!hasTranslatedParent) return;

			const updatedState = update(navigationItems, {
				[item.id]: { $merge: {
					...item, 
					input: true
				}}
			});

			dispatch(updateNavigationItems(updatedState));
			return;
		}
		if(itemClicked) itemClicked(item);
	}, [hasTranslatedParentHandler, dispatch, itemClicked, navigationItems]);

	return (
		<>
			{missingTranslatedParentModal.getAsComponent(
				<div>
					<ScModalHeading>
						Mappen saknar översättning!
					</ScModalHeading>
					<ScModalText>
						För att skapa en översättning för denna sidan behöver du först ange en översättning för mappen som sidan ligger i.
					</ScModalText>
				</div>
			)}
			<Tree
				scope="main-navigation"
				payload={translatedNavItems}
				disabledItems={disabledItems}
				// Disable higlight if inside a modal, dont want to mark a page when choosing one for a link.
				highlighted={
					props.selectedNavigation ? props.selectedNavigation : []
				}
				preExpanded={
					props.selectedNavigation ? props.selectedNavigation : []
				}
				enableDrag={true}
				headerOpts={navigationHeaderOpts}
				opts={navigationItemActions}
				dragEnd={dragEndHandler}
				inputCallback={inputDoneAddNavItem}
				skeleton={<SkeletonTree />}
				stackableIcons={{
					stacked: {
						collapsed: ['fal', 'tags'],
						expanded: ['fas', 'tags']
					},
					single: ['fal', 'tag']
				}}
				dblClicked={(ev, item) => {
					ev.preventDefault();
					ev.stopPropagation();
					if(props.itemDoubleClicked) props.itemDoubleClicked(item);
				}}
				clicked={itemClickedHandler}
				expansionCallback={treeExpansioHandler}
			/>

			{/* Render modal with settings if opened, one entry for every type */}
			{pageSettingsModal.getAsComponent(
				<PageSettings
					templates={templates}
					duplicate={duplicateNavPage}
					formValidation={formValidation}
				/>
			)}
			{deleteNavItemModal.getAsComponent(
				<BasicContentModal
					title={`Radera ${deleteNavItemModal.currentState?.item.title}`}
					text={`Är du säker på att du vill radera? ${SUPPORTED_LANGUAGES.length > 0 ? 'Eventuella översättningar kommer också att tas bort.' : ''}`}
				/>
			)}
			{folderSettingsModal.getAsComponent(
				<FolderSettings
					title="Redigera Mappen"
					duplicate={duplicateNavFolder}
					formValidation={formValidation}
				/>
			)}
			{linkSettingsModal.getAsComponent(
				<LinkSettings
					duplicate={duplicateNavLink}
					formValidation={formValidation}
				/>
			)}
			{addNavItemModal.getAsComponent(
				<ChooseNavItemDialog
					templates={templates}
					navigationTypes={navigationTypes}
					allowances={rootNavigationAllowances}
					addChosenNavItemHandler={addChosenNavItemHandler}
				/>
			)}
		</>
	);
};

MainNavigation.propTypes = {
	disabledItems: PropTypes.array,
	disableSettings: PropTypes.bool,
	selectedNavigation: PropTypes.array,
	enableCache: PropTypes.bool,

	itemClicked: PropTypes.func,
	itemDoubleClicked: PropTypes.func
};

export default MainNavigation;

const ScModalHeading = styled.p`
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	margin: 41px 16px 0;
`;

const ScModalText = styled.p`
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	margin: 16px 16px 22px;
`;