import React from 'react';
import styled from 'styled-components/macro';
import axios from './../../../utils/oc-axios';
import { User, UserListItem, UserTableData, UsersParams, UsersProps } from './model.Users';
import { USERS_LIST_COLUMNS } from './consts.Users';
import useUsers from './hooks/useUsers';
import ModuleContainer from '../../../components/Content/ModuleContainer/ModuleContainer';
import Search from '../../../components/Search/Search';
import UserDetails from '../../../components/ControlPanel/Users/UserDetails/UserDetails';
import useModal from '../../../hooks/Modal/useModal';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import { SkeletonTable } from '../../../components/Skeletons';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';
import { AlertDispatch } from '../../../hooks/useAlert/types.useAlert';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import useFormValidation from '../../../hooks/useFormValidation/useFormValidation';
import ReactTable from '../../../components/GenericTable/ReactTable/ReactTable';
import { Message } from '../../../components/UI';

const resultsPerPage = 50;

// Set stale time to 30min (in ms) to prevent unnecessary refetching of users when revisiting the page
const usersStaleTime = 1000 * 60 * 30;

const Users: React.FC<UsersProps> = () => {
	const userDetailsModal = useModal();
	const notification = useAlert()[1] as AlertDispatch;
	const formValidation = useFormValidation();

	// Holds the params used to fetch users
	const [userParams, setUserParams] = React.useState<UsersParams>({
		page: 1,
		limit: resultsPerPage,
		query: '',
		sort: 'name',
		order: 'asc'
	});

	// Hook that handles the users data
	const { data, isLoading: isLoadingUsers, isFetching: isFetchingUsers, refetch: refetchUsers, isError: hasUsersError } = useUsers(userParams, true, undefined, usersStaleTime);
	const amountOfUsers = data?.amount;
		
	// Modify the data to fit the table
	const users = React.useMemo(() => {
		if(!data?.users) return null;

		return data.users.reduce((pevUsers: UserTableData[], currentUser: UserListItem) => {
		
			return [
				...pevUsers,
				{
					...currentUser,
					// Replace user.enabled with a string
					enabled: currentUser.enabled ? 'Ja' : 'Nej'
				}
			];
		}, []);
	}, [data?.users]); 

	// Calculate the amount of table pages based on the total amount of users and 
	// how many to display on each page in the table
	const amountPages = React.useMemo(() => {
		if(!amountOfUsers) return 0;
		
		return Math.ceil(amountOfUsers / resultsPerPage);
	}, [amountOfUsers]);

	/**
	 * Opens a modal to create a new user.
	 * 
	 * @return {void}
	 */
	const openNewUserModal = React.useCallback((): void => {
		// Creating a new user
		userDetailsModal.open({
			title: 'Skapar användare',
			width: '98%',
			height: '98%',
			isDismissable: 'true',
			isBackdropBlurred: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					action: (
						_oState: number,
						_cState: number,
						closeModal: any
					) => {
						formValidation.resetErrors();
						closeModal();
					}
				},
				{
					text: 'Lägg till',
					action: (
						_oState: number,
						newUserData: User,
						closeModal: any
					) => {
						
						formValidation.submit(() => {

							closeModal();
							
							let alertId = notification('SHOW', {
								priority: AlertPriorityTypes.loading,
								title: 'Skapar användare',
								children: `Skapar användare ${newUserData.name}...`
							});
							axios
								.post('modules/users', newUserData)
								.then(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority: AlertPriorityTypes.success,
										title: 'Användare skapad',
										children: `Användaren ${newUserData.name} har blivit skapad.`
									});
								})

								.catch(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority: AlertPriorityTypes.error,
										title: 'Ett eller flera fel hittades',
										children: `Det gick inte att skapa användaren ${newUserData.name}.`
									});
								})

								// trigger update list of users
								.finally(() => {
									formValidation.resetErrors();
									refetchUsers();
								});
						});
					}
				}
			],
			// send object with id -1 to indicate a new user is being created
			state: {
				id: -1,
				email: '',
				name: '',
				language: 'sv',
				login_name: '',
				enabled: false,
				verified: false,
				privileges: {},
				groups: []
			}
		});
	}, [formValidation, notification, userDetailsModal, refetchUsers]);

	/**
	 * Opens a modal to edit an user.
	 * 
	 * @param {UserListItem} user
	 * @return {void}
	 */
	const openEditUserModal = React.useCallback((user: UserListItem): void => {
		// Editing a user.
		userDetailsModal.open({
			title: `Redigerar ${user?.name}`,
			width: '98%',
			height: '98%',
			isDismissable: 'true',
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					action: (
						_oState: number,
						_cState: number,
						closeModal: any
					) => {
						formValidation.resetErrors();

						closeModal();
					}
				},
				{
					text: 'Spara',
					action: (
						_oState: number,
						_cState: User,
						closeModal: any
					) => {
						formValidation.submit(() => {
							const user = _cState;

							closeModal();

							let alertId = notification('SHOW', {
								priority: AlertPriorityTypes.loading,
								title: 'Uppdaterar användare',
								children: `Sparar användaren ${user.name}...`
							});

							axios
								.put(`modules/users/${user.id}`, user)
								.then(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority:
												AlertPriorityTypes.success,
										title: 'Användare uppdaterad',
										children: `Användaren ${user.name} har sparats.`
									});
								})

								.catch(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority: AlertPriorityTypes.error,
										title: 'Ett eller flera fel hittades',
										children: `Det gick inte att uppdatera användaren ${user.name}.`
									});
								})

								// trigger update list of users
								.finally(() => {
									formValidation.resetErrors();
									refetchUsers();
								});
						});
					}
				}
			],
			state: {
				id: user.id,
				email: '',
				name: '',
				language: 'sv',
				login_name: '',
				enabled: false,
				verified: false,
				privileges: {},
				groups: []
			}
		});
	}, [formValidation, notification, userDetailsModal, refetchUsers]);

	/**
	 * Opens a modal with the user's details
	 * 
	 * @param {UserListItem | null} user
	 * @return {void}
	 */
	const openUserDetails = React.useCallback((user: UserListItem | null): void => {
		// if editing a user, it should not be null
		if(!user) {
			openNewUserModal();
		} else {
			openEditUserModal(user);
		}
	}, [openEditUserModal, openNewUserModal]);

	/**
	 * Handles when user clicks the button to search.
	 * Will use the current value from the text input and set it to a local state
	 *
	 * @param {React.MutableRefObject<HTMLInputElement>} input
	 * @returns {void}
	 */
	const searchButtonClickedHandler = React.useCallback((input:  React.MutableRefObject<HTMLInputElement>): void => {
		const value = input.current.value;

		setUserParams(state => {
			return {
				...state,
				query: value,
				page: 1
			};
		});
	}, []);

	/**
	 * Clears the search input texts when x button is clicked.
	 * 
	 * @returns {void}
	 */
	const searchClearedHandler = React.useCallback((): void => {
		setUserParams(state => {
			return {
				...state,
				query: '',
				page: 1
			};
		});
	}, []);

	/**
	 * Handles when user clicks change page in the table
	 * 
	 * @param {number} index
	 * @returns {void}
	 */
	const paginationHandler = React.useCallback((index: number): void => {
		setUserParams(state => {
			return {
				...state,
				page: index + 1
			};
		});
	}, []);

	return (
		<>
			{userDetailsModal.getAsComponent(
				<UserDetails
					reloadUsersList={refetchUsers}
					formValidation={formValidation}
				/>
			)}

			<ErrorBoundary>
				<ModuleContainer
					hasButton
					buttonText="Skapa användare"
					buttonIcon={['fal', 'plus']}
					header="Användare"
					buttonOnClick={() => openUserDetails(null)}
				>
				
					<ScSearch
						hasButton
						searchPlaceholder="Sök på namn, användarnamn eller e-postadress"
						searchBtnClicked={searchButtonClickedHandler}
						isDisabled={isLoadingUsers || isFetchingUsers}
						cleared={searchClearedHandler}
						enableSearchOnEnter
					/>

					{!isFetchingUsers && hasUsersError && (
						<ScMessage
							size="16"
							icon={['fal', 'redo']}
							pressed={() => refetchUsers()}
						>
							Ett fel inträffade när användare hämtades, ladda om
						</ScMessage>
					)}

					{users && users.length === 0 && !isLoadingUsers && !isFetchingUsers && (
						<ScNoSearchResult>
							Inga grupper hittades med sökfrasen 
							{' '}
							&quot;
							{userParams.query}
							&quot;
							...
						</ScNoSearchResult>
					)}

					{(isLoadingUsers || isFetchingUsers) && (
						<SkeletonTable />
					)}
					
					{!isLoadingUsers && !isFetchingUsers && users && users.length > 0 && (
						<ReactTable
							columns={USERS_LIST_COLUMNS}
							data={users}
							totalResults={amountOfUsers ?? 0}
							rowClickedCallback={(row) => openUserDetails(row.original)}
							customPaginationCallback={paginationHandler}
							amountPages={amountPages}
							resultsPerPage={resultsPerPage}
							customInitialPageIndex={userParams.page - 1}
						/>
					)}
					
				</ModuleContainer>
			</ErrorBoundary>
		</>
	);
};
export default withErrorBoundary(Users);

const ScSearch = styled(Search)`
	margin-bottom: 24px;
`;

const ScMessage = styled(Message)`
	margin-bottom: 24px;
	margin-top: 16px;
`;

const ScNoSearchResult = styled.p`
	margin-bottom: 16px;
`;