import React from 'react';
import * as Sentry from '@sentry/react';
import BlockEmpty from '../components/Builder/BlockTypes/BlockEmpty/BlockEmpty';

const withErrorBoundary = (WrappedComponent) => {
	return class extends React.Component {
		state = {
			hasError: false
		};

		static getDerivedStateFromError(error) {
			// Update state so the next render will show the fallback UI.
			return { hasError: true };
		}

		componentDidCatch(error, errorInfo) {
			Sentry.captureException(error);
		}

		render() {
			if(this.state.hasError) {
				return (
					<BlockEmpty
						icon={['fal', 'ghost']}
						title="Sorry, något har gått fel här..."
						description="En komponent har inte kunnat renderas korrekt. Detta beror högst troligt på att att datan har en felaktig struktur. Felet har automatiskt rapporterats."
					/>
				);
			}

			return <WrappedComponent {...this.props} />;
		}
	};
};

export default withErrorBoundary;
