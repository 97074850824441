import update from 'immutability-helper';
import BlockTextDefinition from './BlockTypes/BlockText/BlockTextDefinition';
import BlockImageDefinition from './BlockTypes/BlockImage/BlockImageDefinition';
import BlockDividerDefinition from './BlockTypes/BlockDivider/BlockDividerDefinition';
import BlockVideoDefinition from './BlockTypes/BlockVideo/BlockVideoDefinition';
import BlockSlideShowDefinition from './BlockTypes/BlockSlideShow/BlockSlideShowDefinition';
import BlockEmbedDefinition from './BlockTypes/BlockEmbed/BlockEmbedDefinition';
import BlockFormDefinition from './BlockTypes/BlockForm/BlockFormDefinition';
import BlockNotesDefinition from './BlockTypes/BlockNotes/BlockNotesDefinition';
import BlockExamDefinition from './BlockTypes/BlockExam/BlockExamDefinition';
import ModuleDefinitions from '../../modules/ModuleDefinitions';

/**
 * This file is used to define block types, their data structure as object, their ops in the builder and so on.
 * Importing definitions from the respective block folder to skip overpopulating this file and making it hard to read.
 */
const BlockDefinitions = (() => {
	// Map/extract blocks from modules
	const modulesBlocks = {};

	for(const [moduleName, moduleData] of Object.entries(ModuleDefinitions)) {
		const blocks = moduleData.blocks;

		// Loop the current module's blocks
		for(const [blockName, blockData] of Object.entries(blocks)) {
			// Named "path" as it contains the module's name and block's name as a string
			//	making it easy to pinpoint from which module a block belongs to.

			const isOnlyBlock = Object.keys(blocks).length <= 1;

			const blockPath = isOnlyBlock
				? blockName
				: `${moduleName}-${blockName}`;

			const updatedBlock = update(blockData, {
				isModuleBlock: { $set: true },

				/* Inject the path inthe prop 'type' inside block's definition baseStruct.data 
				as it is used as a "map" from raw JSON data from back-end to an specific block component.

				It will be used to pick a blocks definition from the object in the return statement further down, when rendering it on the page editor (Builder).
				*/
				baseStruct: {
					data: {
						type: { $set: blockPath }
					}
				}
			});

			modulesBlocks[blockPath] = updatedBlock;
		}
	}

	return {
		text: {
			...BlockTextDefinition
		},
		image: {
			...BlockImageDefinition
		},
		divider: {
			...BlockDividerDefinition
		},
		video: {
			...BlockVideoDefinition
		},
		slideshow: {
			...BlockSlideShowDefinition
		},
		embed: {
			...BlockEmbedDefinition
		},
		form: {
			...BlockFormDefinition
		},
		notes: {
			...BlockNotesDefinition
		},
		exam: {
			...BlockExamDefinition
		},
		...modulesBlocks
	};
})();

export default BlockDefinitions;
