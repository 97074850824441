import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
	CheckItemProps,
	ScCheckboxItemProps,
	ScCheckIconProps,
	ScContainerProps,
	ScTitleProps
} from './model.CheckItem';
import { Icon, Message } from '../../UI';

const CheckItem: React.FC<CheckItemProps> = (props) => {
	const hasError =
		props.error && Array.isArray(props.error) && props.error.length > 0;

	return (
		<ScContainer
			className={props.className}
			style={props.style}
			isBright={props.isBright}
			isNotClickable={props.isNotClickable}
			isHidden={props.isHidden}
			title={props.title}
			description={props.description}
			isSmall={props.isSmall}
		>
			<ScInput
				type={props.type}
				onChange={(ev) => {
					ev.stopPropagation();
					if(props.changed) props.changed(ev, props);
				}}
				checked={props.checked}
				name={props.name}
				disabled={props.isDisabled}
				ref={props.inputRef}
				value={props.value || ''}
			/>
			<ScCheckboxItem
				type={props.type}
				data-icon="check"
				isDisabled={props.isDisabled}
				hasError={hasError}
				width={props.width}
				height={props.height}
				isSmall={props.isSmall}
			>
				<ScCheckIcon
					isSmall={props.isSmall}
					icon={['fal', 'check']}
					size={props.isSmall ? 'xs' : 'sm'}
				/>
			</ScCheckboxItem>

			{(props.title || props.description) && (
				<ScTextWrapper>
					{props.title && (
						<ScTitle
							isSmall={props.isSmall}
							isDisabled={props.isDisabled}
						>
							{props.title}
						</ScTitle>
					)}
					{props.description && (
						<Message
							size="10"
							weight="300"
						>
							{props.description}
						</Message>
					)}
					{hasError && (
						<ScError>
							{props.error}
						</ScError>
					)}
				</ScTextWrapper>
			)}
			{props.children}
		</ScContainer>
	);
};

export default CheckItem;

const ScInput = styled.input`
	appearance: none;
	display: none;
`;

const ScCheckboxItem = styled.div<ScCheckboxItemProps>`
	height: ${(props) => props.height || '24px'};
	width: ${(props) => props.width || '24px'};
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background: transparent;
	border: 1px solid var(--dark-color);
	cursor: pointer;
	transition: background 0.1s linear;
	border-radius: 24px;

	${(props) =>
		props.hasError &&
		css`
			border: 1px solid #d0021b;
			margin-bottom: 0px;
		`}
	${(props) =>
		props.isSmall &&
		css`
			width: 16px;
			height: 16px;
		`}
	${(props) =>
		props.isDisabled &&
		css`
			border: 1px solid #888;
		`}
		${(props) =>
		(props.isSquare || props.type === 'checkbox') &&
		css`
			border-radius: 0px;
		`};
`;

const ScCheckIcon = styled(Icon)<ScCheckIconProps>`
	color: transparent;
	padding: 0 2px;
	${(props) =>
		props.isSmall &&
		css`
			padding: 0 4px;
			font-size: 12px;
		`}
`;

const ScContainer = styled.label<ScContainerProps>`
	display: ${(props) => (props.isHidden ? 'none' : 'inline-block')};
	cursor: pointer;
	margin-bottom: ${(props) => (props.isSmall ? '4px' : '16px')};
	input:checked ~ ${ScCheckboxItem} {
		background: var(--bg-dark-color);
	}

	input:checked ~ ${ScCheckboxItem} ${ScCheckIcon} {
		color: var(--font-bright-color);
	}

	&:last-child {
		margin-bottom: 0;
	}

	${(props) =>
		props.isBright &&
		css`
			input:checked ~ ${ScCheckboxItem} {
				background: var(--bg-bright-color);
			}
			input:checked ~ ${ScCheckboxItem} ${ScCheckIcon} {
				color: var(--font-dark-color);
			}
		`}
	${(props) =>
		props.isNotClickable &&
		css`
			pointer-events: none;
			cursor: default;
		`}

	${(props) =>
		(props.title || props.description) &&
		css`
			display: flex;
			align-items: center;
		`}
`;

const ScTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 8px;
`;

const ScTitle = styled.p<ScTitleProps>`
	font-size: 12px;
	font-weight: 600;
	${(props) =>
		props.isDisabled &&
		css`
			color: #888;
		`}
	${(props) =>
		props.isSmall &&
		css`
			font-weight: 300;
		`}
`;

const ScError = styled.p`
	font-size: 10px;
	font-weight: 300;
	color: #d0021b;
`;
