import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import TextEditor from './../../../../../containers/TextEditor/TextEditor';
import BlockEmpty from './../../../../../components/Builder/BlockTypes/BlockEmpty/BlockEmpty';
import BuyMoreProduct from './BuyMoreProduct';
import { updateTextContent } from '../../../../../store/actions/action-builder';
import { isTyping } from '../../../../../containers/Builder/helpers/TextEditorUtils';

const blockEmpty = (
	<BlockEmpty
		icon={['fal', 'cart-plus']}
		title="Här var det tomt..."
		description="Tryck på kugghjulet för att lägga till produkter."
	/>
);

/**
 * Used to show the content in the settings modal for the CheckOutBuyMores block.
 */
const BlockCheckoutBuyMore = (props) => {
	const dispatch = useDispatch();

	const block = props.data;

	/**
	 * Handles TextEditor content for specifik block
	 * @To-Do: Check if ordinary block content fn can be used instead
	 *
	 * @param {*} content
	 * @param {*} block
	 * @param {*} valueProp
	 */
	const setTextEditorContentHandler = useCallback(
		(content, block) => {
			// isTyping(false);
			dispatch(updateTextContent(content, block, 'title'));
		},
		[dispatch]
	);

	const articles = useMemo(
		() =>
			block.articles.map((product) => {
				const isDisabled =
					product.product_status !== 'enable' ||
					product.status !== 'enable';

				return (
					<BuyMoreProduct
						key={`product_${product.number}`}
						articleNr={product.product_number}
						name={product.product_name}
						price={product.price}
						thumbnail={product.thumbnail}
						isDisabled={isDisabled}
					/>
				);
			}),
		[block.articles]
	);

	/**
	 * Sets the max images to show depending on variant
	 */
	const maxSlidesPerShow = useCallback(() => {
		switch(props.viewInUse) {
			case 'desktop':
				return articles.length < 5 ? articles.length : 5;

			default:
				return articles.length < 1 ? articles.length : 1;
		}
	}, [articles.length, props.viewInUse]);

	// expensive work around due to a bug in React Slick
	// the bug wont update it's instance when props are changed
	const key =
		props.updated !== 1 ? `slick_${props.updated}` : `slick_${props.uuid}`;

	return (
		<div className="cms-block cms-blockProductsBuyMore">
			{articles.length === 0 && blockEmpty}
			{articles.length > 0 && (
				<>
					<div className="buyMore__title">
						<TextEditor
							setTextEditorContent={setTextEditorContentHandler}
							isTyping={isTyping}
							initialText={block.title}
							block={props.id}
							toolbarDisabled={true}
						/>
					</div>

					<Slider
						className="buyMore__items"
						key={key}
						dots={false}
						infinite={false}
						initialSlide={0}
						slidesToShow={maxSlidesPerShow()}
						slidesToScroll={1}
						adaptiveHeight={true}
						// autoplaySpeed={+block.transition_delay}
						autoplay={false}
						arrows={true}
						lazyLoad="progressive"
					>
						{articles}
					</Slider>
				</>
			)}
		</div>
	);
};

BlockCheckoutBuyMore.propTypes = {};

export default BlockCheckoutBuyMore;
