import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import ControlPanelDefaultDefinitions from './ControlPanelDefaultDefinitions';
import ControlPanelCustomerDefinitions from './ControlPanelCustomerDefinitions';
import { setSelectedNavigationAction } from '../../store/actions/action-cache';
import ModuleDefinitions from '../../modules/ModuleDefinitions';
import { Icon } from '../../components/UI';
import ClientHeader from '../../components/SideDrawer/ClientHeader';
import NavigationList from '../../components/SideDrawer/NavigationList';
import MainNavigation from '../MainNavigation/MainNavigation';
import TrashBin from '../TrashBin';
import useAuth from '../../hooks/useAuth/useAuth';
import { NavigationPrivileges } from '../../definitions/Privileges';
import { Select, CheckItem } from '../../components/Forms';
import usePageLanguage from '../../hooks/usePageLanguage/usePageLanguage';
import { SUPPORTED_LANGUAGES } from '../../settings';

/**
 * Map all available modules to an array for showing in the menu.
 */
const modulesMenuEntries = Object.keys(ModuleDefinitions).reduce((prev, current) => {
	const module = ModuleDefinitions[current];
	
	if(!module.page) return prev;

	return [
		...prev,
		{
			name: module.displayName,
			icon: module.icon,
			permission: module.permission,
			link: {
				internal: true,
				to: `/modules/${current}`,
				rel: 'noopener noreferrer'
			}
		}
	];
		
}, []);

// Merge all default menu definitions with the customer menu definitions.
const controlPanelMenuDefinitions = { ...ControlPanelDefaultDefinitions, ...ControlPanelCustomerDefinitions };

// Get the menu links from the menu entries
const controlPanelMenuEntries = Object.keys(controlPanelMenuDefinitions).map(
	(linkName) => controlPanelMenuDefinitions[linkName]
);

const SideDrawer = () => {
	const dispatch = useDispatch();
	const { verifyUserPermission } = useAuth();

	// Returns the active language and a method to change language
	const { activeLanguage, changeLanguage } = usePageLanguage();

	// State is used to show nav items for another language
	const [otherLanguageToShow, setOtherLanguageToShow] = React.useState(null);

	// This hook returns the current 'location' object
	const location = useLocation();

	// This hook returns a function that lets you navigate programmatically
	const navigate = useNavigate();

	// Has trash can permissions
	const hasTrashCanPermission = verifyUserPermission(
		NavigationPrivileges.LIST
	);

	// The current selected navigation, like the current selected page.
	const selectedNavigation = useSelector(
		(state) => state.cache.navigation.selected
	);

	// get the current navitem by it's nav id
	const currentPageNavItem = useSelector((state) =>
		Object.values(state.cache.navigation.items).filter(
			(item) => item.uuid === location.pathname.split('/')[2]
		)[0]);

	/**
	 * Show the page in the PageIframe
	 *
	 * @param {object} navItem
	 */
	const menuItemClickedHandler = useCallback((navItem) => {
		if(navItem.type === 'page') {
			// navigate to that page, useEffect will update the state automatically.
			navigate(`/page/${navItem.uuid}`);
		}
	}, [navigate]);

	/**
	 * Navigate to a page in Editor mode
	 *
	 * @param {Event} ev
	 * @param {object} item
	 */
	const menuItemDoubleClickedHandler = useCallback((item) => {
		if(item.type === 'page') {
			if(verifyUserPermission(NavigationPrivileges.EDIT)) {
				navigate(`/page/${item.uuid}/edit`);
			}
		}
	}, [navigate, verifyUserPermission]);

	/**
	 * Trigger when a menu item is saved after being modified.
	 *
	 * @param {object} item 	The item that changed
	 */
	const menuItemSavedHandler = useCallback((item) => {
		// uppdate the url if slug is changed BUT ONLY if the same page whe are editing is open in view-mode
		if(location.pathname.indexOf(`/page/${item.uuid}`) >= 0) {
			navigate(`/page/${item.uuid}`);
		}
	}, [location.pathname, navigate]);

	/**
	 * Updated the selected page when loading for first time.
	 */
	useEffect(() => {
		if(currentPageNavItem)
			dispatch(setSelectedNavigationAction([currentPageNavItem.id]));
	}, [currentPageNavItem, dispatch]);

	/**
	 * Update the page language
	 * 
	 * @param {React.SyntheticEvent<HTMLSelectElement, Event>} ev
	 * @param {SelectProps} props
	 * @returns {void}
	 */
	const languageChangedHandler = React.useCallback((ev, _props) => {
		if('value' in ev.target) {
			changeLanguage(ev.target.value);
			setOtherLanguageToShow(null);
		}
	}, [changeLanguage]);

	/**
	 * Toggles the otherLanguageToShow state
	 * 
	 * @param {React.SyntheticEvent<HTMLSelectElement, Event> | undefined} ev
	 * @returns {void}
	 */
	const showOtherNavItemsHandler = React.useCallback((ev) => {
		let otherLanguage = null;

		if(ev && 'value' in ev.target) {
			otherLanguage = ev.target.value === '' ? null : ev.target.value;
		} else {
			otherLanguage = SUPPORTED_LANGUAGES.find(language => language.code !== activeLanguage).code;
		}
		
		// Set the state to the other language if it's not already set
		setOtherLanguageToShow(state => {
			if(state === otherLanguage) return null;
			return otherLanguage;
		});
	}, [activeLanguage]);

	/**
	 * The default route, showing the main navigation
	 */
	const defaultRoute = React.useMemo(() => (
		<>
			<ClientHeader />
			<ScContainer>
				{verifyUserPermission(
					NavigationPrivileges.LIST
				) && (
					<>
						{SUPPORTED_LANGUAGES.length > 0 && (
							<ScSelectWrapper>
								<ScLabel>
									Visa sidor för:
								</ScLabel>
								<Select
									changed={languageChangedHandler}
									value={activeLanguage}
								>
									{SUPPORTED_LANGUAGES.map((language) => (
										<option
											key={language.code}
											value={language.code}
										>
											{language.name}
										</option>
									))}
								</Select>
						
								{SUPPORTED_LANGUAGES.length === 2 && (
									<CheckItem
										type="checkbox"
										title={'Visa sidor på: ' + SUPPORTED_LANGUAGES.find(language => language.code !== activeLanguage).name}
										isSmall
										checked={!!otherLanguageToShow}
										changed={() => showOtherNavItemsHandler()}
									/>
								)}

								{SUPPORTED_LANGUAGES.length > 2 && (
									<>
										<ScLabel>
											Visa sidor på andra språk:
										</ScLabel>
										<Select
											changed={showOtherNavItemsHandler}
											value={otherLanguageToShow === null ? '' : otherLanguageToShow}
										>
											<option value="">
												Välj språk
											</option>
											{SUPPORTED_LANGUAGES.map((language) => {
												if(language.code === activeLanguage) return null;

												return (
													<option
														key={language.code}
														value={language.code}
													>
														{language.name}
													</option>
												);
											})}
										</Select>
									</>
								)}
							</ScSelectWrapper>
						) }
					
						<MainNavigation
							otherLanguageToShow={otherLanguageToShow}
							itemClicked={menuItemClickedHandler}
							itemDoubleClicked={menuItemDoubleClickedHandler}
							itemSaved={menuItemSavedHandler}
							selectedNavigation={selectedNavigation}
						/>
					</>
				)}

				<ScTrash
					isDisabled={!hasTrashCanPermission}
					onClick={() => {
						if(!hasTrashCanPermission) return;

						navigate('/trashbin');
					}}
				>
					<Icon icon={['fal', 'trash']} />
					<ScTrashTitle>
						Papperskorg
					</ScTrashTitle>
				</ScTrash>
			</ScContainer>
		</>
	), [activeLanguage, hasTrashCanPermission, otherLanguageToShow, languageChangedHandler, menuItemClickedHandler, menuItemDoubleClickedHandler, menuItemSavedHandler, navigate, selectedNavigation, showOtherNavItemsHandler, verifyUserPermission]);

	/**
	 * The trash bin route, showing the trash bin if the user has permission
	 */
	const trashBin = React.useMemo(() => {
		if(hasTrashCanPermission) {
			return <TrashBin />;
		}

		return <ClientHeader />;
	}, [hasTrashCanPermission]);

	return (
		<ScWrapper location={location}>
			<Routes>
				<Route
					path={'/trashbin'}
					element={trashBin}
				/>
				<Route
					path="/modules/*"
					element={(
						<>
							<ClientHeader />
							<NavigationList items={modulesMenuEntries} />
						</>
					)}
				/> 
				<Route
					path="/control-panel/*"
					element={(
						<>
							<ClientHeader />
							<NavigationList items={controlPanelMenuEntries} />
						</>
					)}
				/>

				{/* Default routes if no-one above matches */}
				<Route
					path="/page/:uuid/:lang?/edit?"
					element={defaultRoute}
				/>
				<Route
					path="/"
					element={defaultRoute}
				/>
			</Routes>
		</ScWrapper>
	);
};

export default SideDrawer;

const ScWrapper = styled.div`
	width: 344px;
	display: flex;
	flex-flow: column;
	background: var(--sideDrawer-bg-color);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
	transition: transform 0.3s ease;
	border-right: 1px solid #e1e1e1;

	transform: ${(props) =>
		props.location.pathname.includes('/page') &&
		props.location.pathname.includes('/edit')
			? 'translateX(-100%)'
			: 'translateX(0%)'};
`;

const ScContainer = styled.div`
	flex: 1;
	/* background-color: var(--bg-bright-color); */
	overflow-x: hidden;
	overflow-y: auto;
	/* padding-bottom: 24px; */
`;

const ScTrash = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 64px;
	margin-bottom: 64px;
	cursor: pointer;
	color: var(--font-dark-grey-color);
	${(props) =>
		props.isDisabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}
`;

const ScTrashTitle = styled.div`
	align-items: center;
	margin-top: 8px;
	font-weight: 300;
`;

const ScLabel = styled.p`
	display: block;
	width: 100%;
	font-weight: 300;
	font-size: 14px;
	margin-bottom: 4px;
`;

const ScSelectWrapper = styled.div`
	padding: 16px 8px 16px 16px;
`;
