import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../../utils/oc-axios';
import { APIModuleUsersResponse, UsersParams } from '../model.Users';

const useUsers = (params?: UsersParams, isEnabled: boolean = true, userID?: number, staleTime?: number) => {

	/**
     * Fetch users data
     * 
     * @param {AbortSignal | undefined} signal 
     * @returns {Promise<APIModuleUsersResponse>}
     */
	const fetchUsers = React.useCallback(async ({ signal } : { signal?: AbortSignal }): Promise<APIModuleUsersResponse> => {
		try {
			return axios.get(userID ? `/modules/users/${userID}` : '/modules/users', {
				params,
				signal
			}).then(res => res.data);
		} catch(error) {
			throw new Error('Det gick inte att hämta användare');
		}

	}, [params, userID]);

	// Holds a useQueryResult with users data
	const query = useQuery(['users', params, userID], fetchUsers, {
		enabled: isEnabled,
		staleTime: staleTime || 0
	});

	return query;
};

export default useUsers;