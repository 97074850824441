import React from 'react';
import { BlockExamProps, BlockExamItem } from './BlockExam.types';
import BlockEmpty from '../BlockEmpty/BlockEmpty';
import useFormStructure from '../../../../hooks/Forms/useFormStructure';
import { BlockFormItemTypes } from '../BlockForm/BlockForm.types';

const BlockExam: React.FC<BlockExamProps> = (props) => {
	const { data } = props;

	// Extract the items from the current state
	const items = data.items;

	/**
     * Function feeded to a hook callback
     * When called it will create a new form element
     * 
     * @param {JSX.Element[] | undefined} children 
     * @returns {JSX.Element}
     */
	const createForm = React.useCallback((children: JSX.Element[] | undefined): JSX.Element => {
		return (
			<>
				{data.name && (
					<h3 className="blockExam__title">
						{data.name}
					</h3>
				)}

				{!children || (children && children.length <= 0) && (
					<BlockEmpty
						icon={['fal', 'comments-question-check']}
						title="Här var det tomt..."
						description="Tryck på kugghjulet för att börja bygga ditt prov"
					/>
				)}

				{(children && children.length > 0) && (
					<form className="blockExam__form">
						{children}
						<input
							className="blockExam__button button -primary"
							type="submit"
							value={data.button_text}
							disabled
						/>
					</form>
				)}
			</>
		);
	}, [data.button_text, data.name]);
	
	/**
     * Function feeded to a hook callback
     * Creates markup for each form item type
     * 
     * @param {BlockExamItem} item 
     * @param {number} index 
     * @param {BlockExamItem} parentItem
     * @param {JSX.Element | JSX.Element[] | undefined} children 
     * @returns {JSX.Element}
     */
	const createFormItems = React.useCallback((item: BlockExamItem, _index: number, parentItem: BlockExamItem, children?: JSX.Element | JSX.Element[] | undefined): JSX.Element => {

		switch(item.type) {
			case BlockFormItemTypes.RADIO_GROUP:
				return (
					<div key={item.unique_name}>
						<p className="labelTitle">
							{item.name}
						</p>
						{children && (
							<div className="radioGroup">
								{children}
							</div>
						)}
					</div>
				);

			case BlockFormItemTypes.RADIO:
				return (	
					<div key={item.unique_name}>
						<input
							type="radio"
							id={item.key}
							className="styledRadio"
							value={item.key}
							name={parentItem.key}
						/>
						<label
							className="styledRadio"
							htmlFor={item.key}
						>
							<div className="radioCircle"></div>
							<div className="radioText">
								<p className="radioTitle">
									{item.name}
								</p>
							</div>
						</label>
					</div>
				);
			default:
				return <></>; 
		}
	}, []);

	const { buildForm } = useFormStructure(items, createForm, createFormItems);

	return (
		<div className="cms-block cms-blockExam">
			{buildForm()}
		</div>
	);
};

export default BlockExam;
