import React from 'react';
import styled from 'styled-components/macro';
import update from 'immutability-helper';
import { GroupDetailsProps } from './model.GroupDetails';
import { GROUPMEMBERS_LIST_COLUMNS } from './consts.GroupDetails';
import { Group } from '../Groups.types';
import useModal from '../../../../hooks/Modal/useModal';
import { CloseModal } from '../../../../hooks/useModal/useModal.model';
import Search from '../../../../components/Search/Search';
import {
	BasicContentModal,
	ModalContainer
} from '../../../../components/UI';
import ControlPanelHeading from '../../../../components/ControlPanel/ControlPanelHeading/ControlPanelHeading';
import { UsersParams } from '../../../../containers/ControlPanel/Users/model.Users';
import SkeletonTable from '../../../../components/Skeletons/SkeletonTable/SkeletonTable';
import SkeletonTree from '../../../../components/Skeletons/SkeletonTree/SkeletonTree';
import TreeWrapper from '../../../../components/CheckboxTree/TreeWrapper/TreeWrapper';
import TreeCategory from '../../../../components/CheckboxTree/TreeCategory/TreeCategory';
import ItemsWrapper from '../../../../components/CheckboxTree/ItemsWrapper/ItemsWrapper';
import TreeItem from '../../../../components/CheckboxTree/TreeItem/TreeItem';
import useCheckboxTree from '../../../../hooks/useCheckboxTree/useCheckboxTree';
import usePrivilegesToCheckboxTree from '../../../../hooks/usePrivilegesToCheckboxTree/usePrivilegesToCheckboxTree';
import { Privileges } from '../../../../definitions/Privileges';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import TextInput from '../../../../components/Forms/TextInput/TextInput';
import { Textarea } from '../../../../components/Forms';
import useUsers from '../../Users/hooks/useUsers';
import ReactTable from '../../../../components/GenericTable/ReactTable/ReactTable';
import ErrorBoundary from '../../../../hoc/ErrorBoundary/ErrorBoundary';
import { TextInputProps } from '../../../../components/Forms/TextInput/model.TextInput';
import { TextareaProps } from '../../../../components/Forms/Textarea/model.Textarea';
import usePrivileges from '../../../../hooks/Permissions/usePrivileges';

const initalPageSize = 10;

const GroupDetails: React.FC<GroupDetailsProps> = (props) => {
	const { deleteGroupMutation, formValidation, modal } = props;
	const deleteGroupModal = useModal();
	const notification = useAlert()[1] as AlertDispatch;

	// Hook that handles the privileges data
	const { data: availablePrivileges, isLoading: isLoadingPrivileges, refetch: refetchPrivileges } = usePrivileges();

	// The complete Group's object with all data or null if not loaded yet.
	const group: Group | null = props.modal?.currentState || null;

	// If the group's id is -1 then we are creating a new group
	const isCreatingNewGroup = group && group.id === -1;

	// Function that updates the current state of the modal
	const updateState = modal?.updateState;

	// Holds the parameters used when fetching users
	const [groupMembersParams, setGroupMembersParams] = React.useState<UsersParams>({
		group: group?.id || null,
		query: '',
		sort: 'name',
		order: 'asc',
		page: 1, // backend counts from page 1
		limit: initalPageSize
	});
	
	// Hook that handles the users data
	const { data, isLoading: isLoadingMembers, refetch: refetchUsers } = useUsers(groupMembersParams, !isCreatingNewGroup);
	const groupMembers = data?.users || [];
	const amountOfMembers = data?.amount || 0;

	// Amount of pages provided to the ReactTable component when showing group members
	const amountOfPages = React.useMemo(() => {
		return amountOfMembers > 0 ? Math.ceil(amountOfMembers / initalPageSize) : 0;
	}, [amountOfMembers]);

	const {
		getTreeFromPrivileges,
		enableWithAuthenticatedUser,
		checkItemsFromList
	} = usePrivilegesToCheckboxTree();

	/**
	 * Generate the privileges tree data.
	 */
	const rawTree = React.useMemo(() => {
		if(!availablePrivileges) return [];

		// generate the base tree data from all the available privileges.
		let baseTree = getTreeFromPrivileges(availablePrivileges);

		// enable privileges based on the current authenticated user's privileges.
		baseTree = enableWithAuthenticatedUser(baseTree);

		// don't continue if the group id is not defined, is already loaded(has id) is if creting a new group
		if(!group) return baseTree;

		// check items from a list of privileges
		baseTree = checkItemsFromList(baseTree, group.privileges);

		return baseTree;
	}, [group, getTreeFromPrivileges, availablePrivileges, enableWithAuthenticatedUser, checkItemsFromList]);

	/**
	 * Used to generate the privileges tree
	 */
	const { tree: privilegesTree } = useCheckboxTree({
		data: rawTree
	});

	/**
	 * Reset the modal to its initial state when the component is mounted.
	 * 
	 * @returns {void}
	 */
	const resetModalHandler = React.useCallback((): void => {
		setGroupMembersParams({
			group: group?.id || null,
			query: '',
			sort: 'name',
			order: 'asc',
			page: 1, // backend counts from page 1
			limit: initalPageSize
		});
		refetchUsers();
		refetchPrivileges();
	}, [group?.id, refetchUsers, refetchPrivileges]);

	/**
	 * Handles when user clicks the button to search.
	 * Will use the current value from the text input and set it to a local state
	 *
	 * @param {React.MutableRefObject<HTMLInputElement>} input
	 * @returns {void}
	 */
	const searchButtonClickedHandler = React.useCallback((input: React.MutableRefObject<HTMLInputElement>): void => {
		const value = input.current.value;

		setGroupMembersParams(state => {
			return {
				...state, 
				query: value,
				page: 1
			};
		});
	}, []);

	/**
	 * Clears the search input texts when x button is clicked.
	 * 
	 * @returns {void}
	 */
	const searchClearedHandler = React.useCallback((): void => {
		if(groupMembersParams.query === '') return;

		setGroupMembersParams(state => {
			return {
				...state, 
				query: '',
				page: 1
			};
		});
	}, [groupMembersParams.query]);

	/**
	 * Handle when a tree item is changed.
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @param {string} categoryID
	 * @returns {void}
	 */
	const privilegesTreeCategoryCheckboxChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, categoryID: string): void => {
		if(!availablePrivileges) return;
		event.stopPropagation();

		const treeCategory = privilegesTree.find((category) => category.id === categoryID);

		const privilegeCategory = availablePrivileges.find((cat) => cat.id === parseInt(categoryID));

		// do nothing if we dont have a treeCategory, group or privilegeCategory
		if(!treeCategory || !group || !privilegeCategory) return;

		const addingPrivilege = !treeCategory.isChecked;

		// all privileges for a category
		const catPrivileges = Object.keys(privilegeCategory.privileges);

		let query = {};
		if(addingPrivilege) {
			const updatedPrivileges = update(group.privileges, {
				// convert array of privileges keys to object
				$merge: catPrivileges.reduce((accumulator: object, priv) => ({
					...accumulator,
					[priv]: true
				}), {})
			});

			query = {
				$set: updatedPrivileges
			};
		} else {
			query = {
				$unset: catPrivileges
			};
		}

		// updating user's privileges will automatically update tree as it
		//	depends on the users privileges
		if(updateState)
			updateState({
				privileges: query
			});
	}, [availablePrivileges, group, privilegesTree, updateState]);

	/**
	 * Handle when a tree item is changed.
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @param {string} privilegeKey
	 * @returns {void}
	 */
	const privilegesTreeItemCheckboxChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, privilegeKey: string): void => {
		event.stopPropagation();

		let query = {};

		const groupPriv = group?.privileges || {};

		// if the privilege is already in the object, then we should remove it to toggle it.
		const addingPrivilege = !(
			(privilegeKey as keyof Privileges) in groupPriv
		);

		if(addingPrivilege) {
			query = {
				[privilegeKey]: { $set: true }
			};
		} else {
			query = {
				$unset: [privilegeKey]
			};
		}

		if(updateState)
		// updating user's privileges will automatically update tree as it
		//	depends on the users privileges
			updateState({
				privileges: query
			});
	}, [group, updateState]);

	/**
	 * Open confirmation modal to delete current group
	 * 
	 * @returns {void}
	 */
	const openDeleteGroup = React.useCallback((): void => {
		deleteGroupModal.open({
			actions: [
				{
					text: 'Nej',
					isDefault: true,
					action: (_originalState: null, _currentState: null, closeModal: CloseModal) => {
						closeModal();
					}
				},
				{
					text: 'Ja',
					action: (_originalState: null, _currentState: null, closeModal: CloseModal) => {

						closeModal();

						// Close the Group details modal
						modal?.close();
						
						let alertId = notification('SHOW', {
							priority: AlertPriorityTypes.loading,
							title: 'Grupp tas bort',
							children: `Tar bort ${group?.name}...`
						});

						if(!group) {
							notification('MODIFY', {
								alertID: alertId,
								priority: AlertPriorityTypes.error,
								title: 'Ett fel har uppstått',
								children: 'Det gick inte att ta bort gruppen.'
							});
							
							return;
						}

						deleteGroupMutation({ group, alertId });
					}
				}
			]
		});
	}, [deleteGroupModal, deleteGroupMutation, group, modal, notification]);

	/**
	 * Handles when a input field changes, updating state
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} ev
	 * @param {TextInputProps | TextareaProps} elProps
	 * @returns {void}
	 */
	const fieldChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, elProps: TextInputProps | TextareaProps): void => {
		if(elProps.id === undefined) return;
		let newValue: string | boolean = ev.target.value;

		// do some modifications for the value of some fields
		switch(elProps.id) {
			case 'enabled':
				newValue = newValue === 'true' ? true : false;
		}

		if(updateState)
			updateState({
				[elProps.id]: { $set: newValue }
			});
	}, [updateState]);

	/**
	 * Handles when the pagination is changed
	 * 
	 * @param {number} index
	 * @param {number} size
	 * @returns {void}
	 */
	const paginationHandler = React.useCallback((index: number, size: number): void => {
		setGroupMembersParams(state => {
			return {
				...state,
				page: index + 1,
				limit: size
			};
		});
	}, []);

	return (
		<ErrorBoundary resetted={resetModalHandler}>
			{deleteGroupModal.getAsComponent(
				<BasicContentModal
					title={`Du håller på att ta bort gruppen ${group?.name}`}
					text="Är du säker på att du vill ta bort?"
				/>
			)}

			<ModalContainer>
				<ScGroup>
					<ScGroupInner>
						<ScGroupInfo>
							<ScFlexSpaceBetween>
								<ControlPanelHeading>
									Gruppinformation
								</ControlPanelHeading>

								{!isCreatingNewGroup && (
									<ScAction onClick={openDeleteGroup}>
										Ta bort Grupp
									</ScAction>
								)}

							</ScFlexSpaceBetween>

							<TextInput
								id="name"
								name="name"
								label="Namn"
								value={group?.name ?? ''}
								isRequired
								formValidationUnregister={formValidation.unregisterElement}
								error={formValidation.errors['name']}
								changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
									formValidation.watch(
										ev,
										fieldChangedHandler,
										data
									);
								}}
								inputRef={(ref) => formValidation.registerElement(ref, { required: true })}
							/>

							<Textarea
								id="description"
								name="description"
								label="Beskrivning"
								value={group?.description as string ?? ''}
								changed={fieldChangedHandler}
							/>
						</ScGroupInfo>

						<ScRights>
							<ControlPanelHeading>
								Rättigheter
							</ControlPanelHeading>

							{isLoadingPrivileges && (
								<ScSkeletonTree />
							)}
							
							{availablePrivileges && (
								<TreeWrapper isHorizontal={true}>
									{privilegesTree.map((category) => (
										<TreeCategory
											key={`CheckboxTreeCategory_${category.id}`}
											id={category.id}
											name={category.name}
											isChecked={category.isChecked}
											isEnabled={category.isEnabled}
											checkboxChanged={privilegesTreeCategoryCheckboxChangedHandler}
										>
											<ItemsWrapper isHorizontal={false}>
												{!!category.items &&
													category.items.map(
														(item) => (
															<TreeItem
																key={item.key}
																id={item.key}
																icon={item.icon}
																text={item.text}
																isChecked={item.isChecked}
																isEnabled={item.isEnabled}
																checkboxChanged={privilegesTreeItemCheckboxChangedHandler}
															/>
														)
													)}
											</ItemsWrapper>
										</TreeCategory>
									))}
								</TreeWrapper>
							)}
						</ScRights>
					</ScGroupInner>
				</ScGroup>

				{!isCreatingNewGroup && (
					<ScUsersContainer>
						<ControlPanelHeading>
							Användare
						</ControlPanelHeading>

						<Search
							hasButton
							searchPlaceholder="Sök på namn, användarnamn eller e-postadress"
							searchBtnClicked={searchButtonClickedHandler}
							isDisabled={isLoadingMembers}
							cleared={searchClearedHandler}
							enableSearchOnEnter
						/>

						<ScTableWrapper>
							{isLoadingMembers && (
								<SkeletonTable />
							)}

							{!isLoadingMembers && groupMembers.length === 0 && (
								<ScGroupNoMembers>
									Gruppen har inga medlemmar...
								</ScGroupNoMembers>
							)}

							{groupMembers.length > 0 && (
								<ReactTable
									columns={GROUPMEMBERS_LIST_COLUMNS}
									data={groupMembers}
									amountPages={amountOfPages}
									customPaginationCallback={paginationHandler}
									resultsPerPage={initalPageSize}
									totalResults={amountOfMembers}
									customInitialPageIndex={groupMembersParams.page - 1}
								/>
							)}
						</ScTableWrapper>
					</ScUsersContainer>
				)}
			</ModalContainer>
		</ErrorBoundary>
	);
};

export default React.memo(GroupDetails);

const ScGroup = styled.div`
	display: flex;
`;

const ScGroupInner = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const ScGroupInfo = styled.div`
	margin-bottom: 32px;
`;

const ScAction = styled.div`
	text-align: right;
	font-size: 12px;
	text-decoration: underline;
	color: var(--red-color);
	text-transform: uppercase;
	margin-left: 24px;
	white-space: nowrap;
	font-weight: 600;
	cursor: pointer;
`;

const ScFlexSpaceBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 24px;
`;

const ScRights = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
`;

const ScSkeletonTree = styled(SkeletonTree)`
	margin-bottom: 32px;
`;

const ScTableWrapper = styled.div`
	margin-top: 24px;
`;

const ScUsersContainer = styled.div`
	margin-top: 24px;
`;

const ScGroupNoMembers = styled.p`
	font-style: italic;
`;