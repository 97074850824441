/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';
import { BASE_URL } from '../settings';

const instance = axios.create({
	baseURL: BASE_URL,
	auth: {
		username: 'izonen_cms',
		password: 'w$oU7CEZi55aaagzW76#r9NVhaeNYQBh'
	},
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Accept-Language': 'sv'
	},
	withCredentials: true
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;
instance.triggerCancel = () => void 0;
instance.all = axios.all;
instance.spread = axios.spread;

export default instance;
