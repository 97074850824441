import React from 'react';
import { BlockNotesProps } from './BlockNotes.types';

const BlockNotes: React.FC<BlockNotesProps> = (props) => {
	const { data } = props;

	return (
		<div className="cms-block cms-blockNotes">
			<p className="blockNotes__description">
				{data.description}
			</p>
			<form className="blockNotes__form">
				<label
					htmlFor="notes"
					className="blockNotes__title"
				>
					{data.title}
				</label>
				<textarea
					id="notes"
					className="blockNotes__textarea"
					placeholder={data.placeholder + '...'}
				/>
				<button
					disabled
					className="blockNotes__button button"
					data-submit
					type="submit"
				>
					{data.button_text}
				</button>
			</form>
		</div>
	);
};

export default BlockNotes;
