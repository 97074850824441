import React from 'react';
import BlockExam from './BlockExam';
import { BlockExamType } from './BlockExam.types';
import BlockExamSettings from './settings/BlockExamSettings';
import { BuilderBlock } from '../../Block.types';
import { CloseModal, ModalConfig, ModalProps } from '../../../../hooks/useModal/useModal.model';
import { FormValidation } from '../../../../hooks/useFormValidation/types';

const initalData: BlockExamType = {
	type: 'exam',
	name: null,
	button_text: 'Nästa',
	items: {
		root: {
			children: [],
			uuid: 'root-uuid',
			is_root: true,
			type: null,
			name: '',
			input_type: null,
			key: 'root',
			unique_name: 'root'
		}
	},
	uuid: null
};

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
export const generateOpts = (
	openModal: (config: ModalConfig<BuilderBlock<BlockExamType>>) => void,
	closeModal: CloseModal,
	changed: (blockState: BuilderBlock<BlockExamType>) => void,
	removeBlock: (blockState: BuilderBlock<BlockExamType>) => void,
	formValidation: FormValidation
) => {
	return [
		{
			icon: ['fal', 'gear'],
			action: (blockData: BuilderBlock<BlockExamType>) => {
				const modalActions = [
					{
						text: 'Avbryt',
						isDefault: true,
						action: (originalState:  BuilderBlock<BlockExamType>, currentState:  BuilderBlock<BlockExamType>, closeModal: CloseModal) => {
							// Reset builder block settings
							formValidation.resetErrors();
							changed(blockData);
							closeModal();
						}
					},
					{
						text: 'Klar',
						action: (originalState: BuilderBlock<BlockExamType>, currentState: BuilderBlock<BlockExamType>, closeModal: CloseModal) => {
							// Only close the modal if the required forms are valid.
							formValidation.submit(() => {
								// Notify builder to update all block's atributes before closing the modal
								changed(currentState);
								closeModal();
							});
							
						}
					}
				];

				openModal({
					title: 'Redigerar provblock',
					actions: modalActions,
					height: false,
					position: 'right',
					width: '416px',
					hideBackdrop: true,
					isPositionable: true,
					isDismissable: false,
					isDraggable: false,
					state: blockData
				});
			}
		},
		{
			icon: ['fal', 'trash'],
			action: (blockData: BuilderBlock<BlockExamType>) => {
				removeBlock(blockData);
				closeModal();
			}
		}
	];
};

const baseStruct = {
	baseStruct: {
		key: null,
		data: initalData
	},
	builder: {
		component: <BlockExam data={initalData} />,
		setOpts: generateOpts
	},
	settings: {
		component: <BlockExamSettings
			modal={{} as ModalProps<BuilderBlock<BlockExamType>>}
			formValidation={{} as FormValidation}
		           />
	}
};

export default baseStruct;