import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// Extending the prototype of string
// Do not remove...
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import * as extensions from './utils/extensions';
import { SENTRY_DSN } from './settings';
import App from './App';

// Initialize Sentry before React so it catches errors
Sentry.init({
	environment: process.env.NODE_ENV,
	dsn: SENTRY_DSN,
	normalizeDepth: 10, // How deep the context will be, specially for Redux.
	enabled: process.env.NODE_ENV === 'production',
	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
	  Sentry.reactRouterV6BrowserTracingIntegration({
		  useEffect: React.useEffect
	  }),
	  Sentry.replayIntegration()
	],

	ignoreErrors: ['ResizeObserver loop limit exceeded'],

		 // Performance Monitoring
		 tracesSampleRate: 1.0, //  Capture 100% of the transactions
		 replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		 replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// if in development send user to subdomain cms.oc.io
// if(process.env.REACT_APP_ENV === 'development' && window.location.hostname === 'localhost') {
// 	window.location.replace("http://cms.oc.io:10005");
// }

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	// eslint-disable-next-line @typescript-eslint/naming-convention
	<BrowserRouter future={{ v7_startTransition: true }}>
		<App />
	</BrowserRouter>
);