import React from 'react';
import { createRandomId } from 'react-utils';
import { useSelector } from 'react-redux';
import update from 'immutability-helper';
import immutable, { Map } from 'immutable';
import useAlert, { AlertPriorityTypes } from '../../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../../hooks/useAlert/types.useAlert';
import { BlockSettingsProps, BuilderBlock } from '../../../Block.types';
import { BlockExamType, BlockExamItem } from '../BlockExam.types';
import { Button, ModalSettingsContainer } from '../../../../UI';
import { CheckItem } from '../../../../Forms';
import useFormStructure from '../../../../../hooks/Forms/useFormStructure';
import Draggable from '../../../../../hoc/DragDrop/Draggable';
import { AllowedItem, BlockFormItemTypes, GeneralFormItemStructures } from '../../BlockForm/BlockForm.types';
import FormItem from '../../../../Forms/FormItem/FormItem';
import { RootState } from '../../../../../store/types/RootTypes';
import useFormItems from '../../../../../hooks/Forms/useFormItems';
import TextInput from '../../../../Forms/TextInput/TextInput';
import { TextInputProps } from '../../../../Forms/TextInput/model.TextInput';
import { CheckItemProps } from '../../../../Forms/CheckItem/model.CheckItem';

const BlockExamSettings: React.FC<BlockSettingsProps<BuilderBlock<BlockExamType>>> = (props) => {
	// The modal hook this component is inside of
	const { currentState, updateState } = props.modal;

	// Extract the block data and items from the current state
	const blockData = currentState.data;
	const items = currentState.data.items;

	// The func to trigger when the modal state has changed,
	// used to notify the Builder to update the block for preview to work
	const changed = props.changed;

	// Get functions and states to be used withing the FormItem component and provided to the useFormStructure hook
	const { expandClickedHandler, createDragDropScope, getIcon, enableEditNameHandler, isEditingName, changeItemNameHandler, isExpanded, setIsExpanded } = useFormItems(blockData, updateState, currentState, changed);

	// Get the general structure of form items
	const { structures }: { structures: GeneralFormItemStructures } = useSelector((selector: RootState) => selector.builder.formStructure);

	// Used to show notifications
	const notification = useAlert()[1] as AlertDispatch;

	// A hook that provides methods for validating form elements
	const formValidation = props.formValidation;

	/**
	 * Responsible for deleting an exam item and all it's children
     * 
	 * @param {string} parent The parent item to remove the item from
     * @param {number} index The index of the item to remove
     * @param {string} key The key of the item to remove
     * @returns {void}
	 */
	const removeFormItemHandler = React.useCallback((parent: string, index: number, key: string): void => {
		if(!items) return;

		// Find all children of the item to delete
		const childrenKeys = items[key].children;
		const children = Object.keys(items).filter(itemKey => childrenKeys.includes(itemKey));

		// Remove the item and all it's children from the state
		const updatedState = update(blockData, {
			items: {
				[parent]: {
					children: {
						$splice: [[index, 1]]
					}
				},
				$unset: [...children, key]
			}
		});

		updateState({ data: { $set: updatedState }}).then((updatedState) => {
			if(changed) changed(updatedState);
		}).catch(() => {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Ett fel inträffade',
				children: 'Det gick inte att ta bort objektet'
			});
		});

	}, [blockData, changed, items, notification, updateState]);

	/**
	 * Adds a new item to the current state
	 * 
	 * @param {string} type The type of item to add
	 * @param {string | null} inputType The input type of the item to add
	 * @param {string} parent The parent item to add the new item to
	 * @returns {void}
	 */
	const addFormItemHandler = React.useCallback((type: string, _inputType: string | null, parent: string): void => {
		// generate new random key for the form element.
		const formElementKey =  createRandomId(type);

		let defaultItem: BlockExamItem = {
			name: 'Svar',
			uuid: null,
			is_root: false,
			children: [],
			is_correct: false,
			type: type as BlockFormItemTypes,
			key: formElementKey,
			input_type: null,
			unique_name: formElementKey
		};

		switch(type) {
			case BlockFormItemTypes.RADIO_GROUP:
				defaultItem = {
					...defaultItem,
					is_root: true,
					name: 'Fråga'
				};
				break;
		
			default:
				break;
		}

		const suggestedState = {
			data: {
				items: {
					$merge: {
						[formElementKey]: defaultItem
					},
					// add element to root element
					[parent]: {
						children: {
							$push: [formElementKey]
						}
					}
				}
			}
		};

		// This will expand the newly created item
		setIsExpanded((state) => [...new Set(state), formElementKey]);
		
		// This updates the preview in the builder with the new state
		if(changed && currentState) changed(update(currentState, suggestedState));
		
		updateState(suggestedState);
	}, [changed, currentState, setIsExpanded, updateState]);

	/**
	 * Updates the modal state at a given target with a new value
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @param {TextInputProps | CheckItemProps} props
	 * @param {string | undefined} itemKey
	 * @returns {void}
	 */
	const changeValueHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, props: CheckItemProps | TextInputProps, itemKey?: string): void => {
		if(!props.name) return;
		const newValue = (props.type === 'checkbox' && 'checked' in event.target) ? event.target.checked : event.target.value;
		let newState;

		// Set the target based on if it's an item that's changing 
		// or a prop in the root of the block data
		const target = itemKey ? ['items', itemKey, props.name] : [props.name];

		// If changing 'is_correct' of an item then set all other sibling items to is_correct: false
		if(props.name === 'is_correct' && itemKey) {
			// Find the parent of the item
			const parent = Object.values(items).find(item => item.children.includes(itemKey));

			// Find all children items
			const children = Object.keys(items).filter(key => parent && parent.children.includes(key));
			
			// Create an immutable version of the blockstate
			const immutableBlockstate = Map(blockData);

			// Set all children to is_correct: false except the one that's being changed
			const updatedImmutableState = children.reduce((prev, currentKey) => {
				const updatedItem = {
					...items[currentKey],
					is_correct: currentKey === itemKey ? true : false
				};

				// Update the item in the accumulator
				return prev.setIn(['items', currentKey], updatedItem);
			}, immutableBlockstate);

			// Convert back to JavaScript object
			newState = updatedImmutableState.toJS();
		} else {
			newState = immutable.setIn(blockData, target, newValue);
		}

		updateState({
			data: {
				$set: newState
			}
		}).then((updatedState) => {
			if(changed) changed(updatedState);
		}).catch(() => {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Ett fel inträffade',
				children: 'Det gick inte att ändra namnet'
			});
		});
	}, [blockData, changed, items, notification, updateState]);

	/**
     * Creates individual FormItems wrapped in a draggable
     * 
     * @param {BlockExamItem} item The item to create markup for
     * @param {number} index The index of the item
     * @param {BlockExamItem} parentItem The parent item of the item
     * @param {JSX.Element | JSX.Element[] | undefined} children The children elements of the item
     * @returns {JSX.Element}
     */
	const createFormItem = React.useCallback((item: BlockExamItem, index: number, parentItem: BlockExamItem, children?: JSX.Element | JSX.Element[] | undefined): JSX.Element => {
		const parentKey = parentItem.key ?? 'root';
		const { type } = item;
		if(!type) return <></>;

		// If the item is allowed to have children, create an object with allowed children
		const allowed = structures[type].allowed;
		const allowedCreationObject = allowed && allowed.map((item): AllowedItem => {
			return {
				element: item,
				name: structures[item].name === 'Envalsknapp' ? 'svar' : structures[item].name
			};
		});

		// Get the appropriate icon for the item
		let icon;
		switch(item.type) {
			case BlockFormItemTypes.RADIO_GROUP:
				icon = 'comment-question';
				break;
			case BlockFormItemTypes.RADIO:
				icon = 'comment';
				break;
		
			default:
				icon = getIcon(item);
				break;
		}
		
		// Create custom action for the form item of type 'radio' 
		// in order to be able to mark it as the correct answer
		let actions = undefined;
		if(item.type === BlockFormItemTypes.RADIO) {
			actions = [{
				element: <CheckItem
					type="checkbox"
					isBright
					isSmall
					checked={item.is_correct}
					changed={(ev, props) => changeValueHandler(ev, props, item.key)}
					name="is_correct"
				         />,
				name: 'is_correct',
				status: item.is_correct ? 'Rätt svar' : undefined
			}];
		}

		return (
			<Draggable
				id={item.key}
				key={item.key}
				index={index}
				scope={`scope-${parentKey}`}
				allowedDirection="vertical"
				parent={parentKey}
				enableDrag={true}
			>
				<FormItem
					name={`${item.name}`}
					id={item.key}
					parent={parentKey}
					index={index}
					icon={typeof icon === 'string' ? ['fal', icon] : icon}
					addItemClicked={addFormItemHandler}
					isEditingName={isEditingName === item.key}
					nameChangeEnabled={enableEditNameHandler}
					nameChanged={changeItemNameHandler}
					deleteClicked={removeFormItemHandler}
					allowed={allowedCreationObject}
					expandClicked={(() => expandClickedHandler(item.key))}
					isExpanded={isExpanded.includes(item.key)}
					isLocked={false}
					actions={actions}
				>
					{children}
				</FormItem>
			</Draggable>
		);
	}, [addFormItemHandler, changeItemNameHandler, changeValueHandler, enableEditNameHandler, expandClickedHandler, getIcon, isEditingName, isExpanded, removeFormItemHandler, structures]);

	const { buildForm } = useFormStructure(items, createDragDropScope, createFormItem, createDragDropScope);
	
	return (
		<ModalSettingsContainer>
			<TextInput
				id="name"
				value={blockData.name ?? ''}
				label="Provets namn"
				placeholder="Namn..."
				name="name"
				isRequired
				error={formValidation?.errors['name']}
				changed={(ev: React.ChangeEvent<HTMLInputElement>, props) => {
					formValidation?.watch(ev, changeValueHandler, [{ name: props.name }]);
				}}
				inputRef={(ref: HTMLInputElement | null) => {
					formValidation?.registerElement(ref, {
						required: true
					});
				}}
				formValidationUnregister={formValidation?.unregisterElement}
			/>

			{buildForm()}
			
			<Button
				isPrimary
				onClick={() => addFormItemHandler(BlockFormItemTypes.RADIO_GROUP, null, 'root')}
			>
				Lägg till fråga
			</Button>
		</ModalSettingsContainer>
	);
};

export default BlockExamSettings;