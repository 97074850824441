import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { PrivilegeData } from './privileges.types';
import axios from '../../utils/oc-axios';

const usePrivileges = () => {

	/**
     * Fetch privileges data
     * 
     * @param {AbortSignal | undefined} signal 
     * @returns {Promise<PrivilegeData[]>}
     */
	const fetchPrivileges = React.useCallback(async ({ signal } : { signal?: AbortSignal }): Promise<PrivilegeData[]> => {
		try {
			return axios.get('/auth/privileges', {
				signal
			}).then(res => res.data);
		} catch(error) {
			throw new Error('Det gick inte att hämta rättigheter');
		}
	}, []);

	// Holds a useQueryResult with privileges data
	const query = useQuery(['privileges'], fetchPrivileges, {
		refetchOnWindowFocus: false
	});

	return query;
};

export default usePrivileges;