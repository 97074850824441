import React from 'react';
import styled from 'styled-components/macro';
import Slider from 'react-slick';
import BlockEmpty from '../BlockEmpty/BlockEmpty';

/**
 *
 * @dots {*}  - shows dots under slider
 * @infinite {*}  - Infinite loop
 * @speed {*}  - Speed of transition between slides, milliseconds
 * @slidesToShow {*}  -Number of slides to show ex. 1 or 2 or 3 at once.
 * @slidesToScroll {*}  - Number of slides that will scroll at once *
 * @autoplay {*}  - Boolean if the slider will automatically slide or not
 * @autoplaySpeed {*}  - How fast it will change slides in milliseconds
 *
 */
const BlockSlideShow = (props) => {
	const images = props.data.images.map((image, index) => {
		// all slideshow images are of type poster, thus content is applied over the image
		const imageTypeClass = '--poster';

		// all slideshow content require a position, thus it's defaulted to 'south' if none is given
		const contentPosition =
			image.content_position === 'none'
				? 'south'
				: image.content_position;

		// the content position in the end is a class defined in backed
		const imageContentPositionClass = `--position-${contentPosition}`;

		// also theme classes (light, dark, etc) is applied as content background
		const imageThemeClass = `--theme-${image.theme}`;

		// an array of class names are created to be applied to the slideshow figure
		const classes = [
			imageTypeClass,
			imageContentPositionClass,
			imageThemeClass
		];

		let imageSrc;

		switch(props.viewInUse) {
			case 'desktop':
				imageSrc = image.media ? image.media.src : null;
				break;

			default:
				imageSrc = image.mobile_media.src
					? image.mobile_media.src
					: image.media.src;
		}

		return (
			<div key={`slideshow_${index}`}>
				<figure className={classes.join(' ')}>
					<img
						alt={image.link.title}
						src={imageSrc}
					/>
					<figcaption>
						{image.has_caption && image.title && (
							<h3>
								{image.title}
							</h3>
						)}
						{image.has_caption && image.caption && (
							<p>
								{image.caption}
							</p>
						)}
						{image.link_type === 'button' && (
							<button className="imageBtn">
								{image.link.title}
							</button>
						)}
					</figcaption>
				</figure>
			</div>
		);
	});

	// expensive work around due to a bug in React Slick
	// the bug wont update it's instance when props are changed
	const key =
		props.updated !== 1 ? `slick_${props.updated}` : `slick_${props.uuid}`;

	return (
		<ScContainer className="cms-block cms-blockSlideshow">
			{!images.length && (
				<BlockEmpty
					icon={['fal', 'images']}
					title="Här var det tomt..."
					description="Tryck på kugghjulet för att redigera ditt bildspel"
				>
				</BlockEmpty>
			)}

			{images.length > 0 && (
				<Slider
					key={key}
					dots={false}
					infinite={true}
					initialSlide={props.data.initial_slide || 0}
					speed={+props.data.transition_duration}
					fade={props.data.transition_type === 'fade'}
					slidesToShow={1}
					slidesToScroll={1}
					adaptiveHeight={true}
					autoplaySpeed={+props.data.transition_delay}
					autoplay={props.data.autoplay || false}
					arrows={props.data.show_navigation || false}
				>
					{images}
				</Slider>
			)}
		</ScContainer>
	);
};

const ScContainer = styled.div``;

export default BlockSlideShow;
