/* eslint-disable react/display-name */
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import immutable from 'immutable';
import GroupDetails from './GroupDetails';
import { GroupDetailsModalProps, GroupDetailsRefFunctions } from './model.GroupDetails';
import useModal from '../../../../hooks/Modal/useModal';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import useFormValidation from '../../../../hooks/useFormValidation/useFormValidation';
import { Group } from '../Groups.types';
import useUpdateGroup from '../hooks/useUpdateGroup';
import useCreateGroup from '../hooks/useCreateGroup';
import useDeleteGroup from '../hooks/useDeleteGroup';

/**
 * This modal is created as a forwardRef component to be able to call the openModal function from the parent component.
 * Read more about forwardRef here: https://react.dev/reference/react/forwardRef
 */
const GroupDetailsModal = React.forwardRef<GroupDetailsRefFunctions, GroupDetailsModalProps>((props, ref) => {
	const { refetchGroups } = props;
	const groupDetailsModal = useModal();
	const notification = useAlert()[1] as AlertDispatch;
	const formValidation = useFormValidation();
	const queryClient = useQueryClient();

	/**
	 * This hook is used to be able to call the openModal function from the parent component.
	 * Read more about useImperativeHandle here: https://react.dev/reference/react/useImperativeHandle
	 */
	React.useImperativeHandle(ref, () => ({
		openModal(group: Group | null) {
			openGroupDetails(group);
		}
	  }));

	/**
	 * Optimistically update the groups list.
	 * 
	 * @param {Group} group
	 * @param {boolean} isDeleted
	 * @returns {Promise<void>}
	 */
	const optimisticallyUpdateGroups = React.useCallback(async(group: Group, isDeleted: boolean = false): Promise<void> => {
		let updatedGroups: Group[] = [];

		// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
		// (The key undefined is instead of group id)
		await queryClient.cancelQueries({ queryKey: ['groups', undefined] });

		// Snapshot the previous value
		const previousGroups: Group[] | undefined = queryClient.getQueryData(['groups', undefined]);
		if(!previousGroups) return;

		// Find the index of the group to update
		const index = previousGroups.findIndex((g: Group) => g.id === group.id);

		// If the group is found and should be deleted, remove it
		if(index > -1 && isDeleted) {
			updatedGroups = immutable.removeIn(previousGroups, [index]);
		} 
		// If the group is found, update it
		else if(index > -1) {
			updatedGroups = immutable.setIn(previousGroups, [index], group);
		}
		// If the group is not found, add it
		else if(index === -1) {
			updatedGroups = [group, ...previousGroups];
		}

		// Optimistically update to the new value
		queryClient.setQueryData(['groups', undefined], () => updatedGroups);
	}, [queryClient]);

	// Holds the useMutationResult with information about the update group mutation
	const updateGroup = useUpdateGroup(optimisticallyUpdateGroups);

	// Holds the useMutationResult with information about the create group mutation
	const createGroup = useCreateGroup();

	// Holds the useMutationResult with information about the delete group mutation
	const deleteGroup = useDeleteGroup(optimisticallyUpdateGroups);
    
	/**
	 * Open modal with a group's information.
	 * 
	 * @param {Group | null} group
	 * @returns {void}
	 */
	const openGroupDetails = React.useCallback((group: Group | null): void => {
		let modalButtons = [
			{
				text: 'Stäng',
				isDefault: true,
				action: (_originalState: number, _currentState: Group, closeModal: () => void) => {
					formValidation.resetErrors();
					closeModal();
				}
			}
		];

		// Add a save button if editing existing group
		if(group) {
			modalButtons = [
				...modalButtons,
				{
					text: 'Spara',
					isDefault: false,
					action: (_originalState: number, currentState: Group, closeModal: () => void) => {

						formValidation.submit(() => {
							closeModal();

							let alertId = notification('SHOW', {
								priority: AlertPriorityTypes.info,
								title: 'Uppdaterar grupp',
								children: `Sparar gruppen ${currentState.name}...`
							});
							
							updateGroup.mutate({ group: currentState, alertId });
						});
					}
				}
			];
		}

		// Add a create button if creating new group
		if(!group) {
			modalButtons = [
				...modalButtons,
				{
					text: 'Lägg till',
					isDefault: false,
					action: (_originalState: number, currentState: Group, closeModal: () => void) => {
						
						formValidation.submit(() => {

							closeModal();

							let alertId = notification('SHOW', {
								priority: AlertPriorityTypes.info,
								title: 'Skapa grupp',
								children: `Skapar grupp ${currentState.name}...`
							});

							createGroup.mutate({ group: currentState, alertId, cb: refetchGroups });
						});
					}
				}
			];
		}

		groupDetailsModal.open({
			title: group ? `Redigerar ${group.name}` : 'Skapar grupp',
			width: '98%',
			height: '98%',
			isDismissable: 'true',
			isBackdropBlurred: true,
			actions: modalButtons,
			state: group ? group : {
				// send object with id -1 to indicate a new group is being created
				id: -1,
				key: '',
				name: '',
				description: '',
				users: 0,
				privileges: {}
			}
		});
	}, [createGroup, formValidation, groupDetailsModal, notification, refetchGroups, updateGroup]);

	return (
		<>
			{groupDetailsModal.getAsComponent(
				<GroupDetails
					formValidation={formValidation}
					deleteGroupMutation={deleteGroup.mutate}
				/>
			)}
		</>
	);
});

export default GroupDetailsModal;