import React from 'react';
import styled from 'styled-components/macro';
import { CompanyDetailsProps, StyledColumnProps } from './ComapyDetails.types';
import { Select } from '../../../components/Forms';
import { ModalContainer } from '../../../components/UI';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import { ValidationTypes } from '../../../hooks/useFormValidation/types';
import TextInput from '../../../components/Forms/TextInput/TextInput';
import { TextInputProps } from '../../../components/Forms/TextInput/model.TextInput';
import { SelectProps } from '../../../components/Forms/Select/model.Select';

const CompanyDetails: React.FC<CompanyDetailsProps> = (props) => {
	// The formValidation object
	const formValidation = props.formValidation;

	// The current state given by the modal props
	const currentState = props.modal.currentState;

	// The method to update the current state
	const updateState = props.modal.updateState;

	/**
	 * Handles changes to the form fields
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>} ev  - The change event
	 * @param {TextInputProps|SelectProps} props  - The props of the element that triggered the event
	 * @returns {void}
	 */
	const fieldChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>, props: TextInputProps|SelectProps): void => {
		if(!props.id) return;

		let newValue = ev.target.value.length <= 0 ? null : ev.target.value;

		updateState({
			[props.id]: { $set: newValue }
		});

	}, [updateState]);

	return (
		<ErrorBoundary>
			<ScModalContainer>
				<ScColumn hasMarginRight>
					<TextInput
						label="Namn"
						placeholder="Namn"
						isRequired
						id="name"
						name="name"
						value={currentState.name ?? ''}
						formValidationUnregister={formValidation.unregisterElement}
						error={formValidation.errors['name']}
						changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref: HTMLInputElement | null) => {
							formValidation.registerElement(ref, {
								required: true
							});
						}}
					/>
					<TextInput
						label="Organisationsnummer"
						placeholder="Organisationsnummer"
						isRequired
						id="identity_number"
						name="identity_number"
						value={currentState.identity_number ?? ''}
						formValidationUnregister={formValidation.unregisterElement}
						error={formValidation.errors['identity_number']}
						changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref: HTMLInputElement | null) => {
							formValidation.registerElement(ref, {
								required: true
							});
						}}
					/>
					<TextInput
						label="E-mail"
						placeholder="E-mail"
						isRequired
						id="email"
						name="email"
						value={currentState.email ?? ''}
						formValidationUnregister={formValidation.unregisterElement}
						error={formValidation.errors['email']}
						changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref: HTMLInputElement | null) => {
							formValidation.registerElement(ref, {
								required: true,
								validation:  ValidationTypes.EMAIL
							});
						}}
					/>
					<TextInput
						label="Telefon"
						placeholder="Telefon"
						isRequired
						id="phone"
						name="phone"
						value={currentState.phone ?? ''}
						formValidationUnregister={formValidation.unregisterElement}
						error={formValidation.errors['phone']}
						changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref: HTMLInputElement | null) => {
							formValidation.registerElement(ref, {
								required: true,
								validation: ValidationTypes.PHONE
							});
						}}
					/>
					<TextInput
						label="Ort"
						placeholder="Ort"
						isRequired
						id="city"
						name="city"
						value={currentState.city ?? ''}
						formValidationUnregister={formValidation.unregisterElement}
						error={formValidation.errors['city']}
						changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref: HTMLInputElement | null) => {
							formValidation.registerElement(ref, {
								required: true
							});
						}}
					/>
					<TextInput
						label="Adress"
						placeholder="Adress"
						id="street"
						name="street"
						value={currentState.street ?? ''}
						changed={fieldChangedHandler}
					/>
					<TextInput
						label="Postnummer"
						placeholder="Postnummer"
						id="postal_code"
						name="postal_code"
						value={currentState.postal_code ?? ''}
						formValidationUnregister={formValidation.unregisterElement}
						error={formValidation.errors['postal_code']}
						changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) => {
							formValidation.registerElement(ref, {
								validation: {
									type: ValidationTypes.NUMERIC
								}
							});
						}}
					/>
					
				</ScColumn>
				<ScColumn >
					<TextInput
						label="Inköpare"
						placeholder="Inköpare"
						id="buyer"
						name="buyer"
						value={currentState.buyer ?? ''}
						changed={fieldChangedHandler}
					/>
					<TextInput
						label="Ansvarig säljare"
						placeholder="Ansvarig säljare"
						id="izonen_seller"
						name="izonen_seller"
						value={currentState.izonen_seller ?? ''}
						changed={fieldChangedHandler}
					/>
					<TextInput
						label="Pris"
						placeholder="Pris"
						id="price"
						name="price"
						value={currentState.price ?? ''}
						formValidationUnregister={formValidation.unregisterElement}
						error={formValidation.errors['price']}
						changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) => {
							formValidation.registerElement(ref, {
								validation: {
									type: ValidationTypes.NUMERIC
								}
							});
						}}
					/>
					<TextInput
						label="Hemsida"
						placeholder="Hemsida"
						id="website"
						name="website"
						value={currentState.website ?? ''}
						formValidationUnregister={formValidation.unregisterElement}
						error={formValidation.errors['website']}
						changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) => {
							formValidation.registerElement(ref, {
								validation: {
									type: ValidationTypes.URL
								}
							});
						}}
					/>
					<TextInput
						label="Avslutsdatum"
						placeholder="ÅÅÅÅ-MM-DD"
						id="enddate"
						name="enddate"
						value={currentState.enddate ?? ''}
						formValidationUnregister={formValidation.unregisterElement}
						error={formValidation.errors['enddate']}
						changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) => {
							formValidation.registerElement(ref, {
								validation: {
									type: ValidationTypes.DATE
								}
							});
						}}
					/>
					<Select
						label="Tidsperiod för ofrekventa användare"
						description="Sätt en påminnelse för ofrekventa användare nedan"
						id="infrequent_users_period"
						name="infrequent_users_period"
						fullWidth
						changed={fieldChangedHandler}
						value={currentState.infrequent_users_period ?? ''}
					>
						<option value="">
							Ingen
						</option>
						<option value="30">
							30 dagar
						</option>
						<option value="7">
							7 dagar
						</option>
					</Select>

					{/* NOTE: This field is temporarily hidden
					<TextInput
						label="Datum för start av utmaningar"
						placeholder="ÅÅÅÅ-MM-DD"
						id="challenges_date"
						name="challenges_date"
						description="Lämna rutan tom om företaget inte ska ha några utmaningar"
						value={currentState.challenges_date ?? ''}
						formValidationUnregister={formValidation.unregisterElement}
						error={formValidation.errors['challenges_date']}
						changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) => {
							formValidation.registerElement(ref, {
								validation: {
									type: ValidationTypes.DATE
								}
							});
						}}
					/> */}
				</ScColumn>
			</ScModalContainer>
		</ErrorBoundary>
	);
};

export default CompanyDetails;

const ScModalContainer = styled(ModalContainer)`
	display: flex;
`;

const ScColumn = styled.div<StyledColumnProps>`
	margin-right: ${props => props.hasMarginRight ? '24' : '0'}px;
	width: 50%;
`;