import React from 'react';
import { FormValidation } from '../../hooks/useFormValidation/types';
import { ModalConfig, ModalProps } from '../../hooks/useModal/useModal.model';

/**
 * Interface used in the Redux state and Builder for representing a Block.
 */
export interface BuilderBlock<T> {
	key: string;
	data: T;
	// Unix timestamp when the block was modified last time.
	updated?: number;
}

/**
 * Defines the types of blocks available.
 */
export enum BlockTypes {
	FORM = 'form',
	GIFTCARD = 'giftcard'
}

export type Changed<T> = (updatedBlockData: T) => void;

/**
 * Used for a block's props.
 */
export interface BlockSettingsProps<T> {
	// This are the props that the modal hook inject to give access to the modal states and more
	modal: ModalProps<T>;

	// A callback to trigger an update in the Builder to reflect block setting changes
	changed?: Changed<T>;

	formValidation: FormValidation;
}

/**
 * Defines a block's diferents settings.
 */
export interface BlockBaseStruct<T> {
	isAddable?: boolean;
	// The blocks base structure used in the state & with the API to backend.
	baseStruct: {
		key: string | null;

		// Data specific to this block
		data: T;
	},

	builder: {
		// The component to render as the block inside the editor/builder.
		component: React.ReactNode;

		// Function used to dynamically generate the block's action buttons.
		setOpts: (
			openModal: (config: ModalConfig<BuilderBlock<T>>) => void,
			closeModal: () => void,
			changed: (blockState: BuilderBlock<T>) => void,
			removeBlock: (blockState: BuilderBlock<T>) => void,
			formValidation: FormValidation
		) => Array<BlockAction>;
	},

	settings: {
		// The React componentused to render as the block's settings.
		component: React.ReactNode
	}
}

/**
 * Defines an action button when hovering the block in the editor.
 */
export interface BlockAction {

}