import React from 'react';
import BlockSlideShow from './BlockSlideShow';
import BlockSlideshowSettings from './Settings/BlockSlideshowSettings';

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
export const generateOpts = (
	openModal,
	closeModal,
	changed,
	removeBlock,
	formValidation
) => {
	return [
		{
			icon: ['fal', 'gear'],
			action: (blockData) => {
				const modalActions = [
					{
						text: 'Avbryt',
						isDefault: true,
						action: (originalState, currentState, closeModal) => {
							// Reset builder block settings
							formValidation.resetErrors();
							changed(blockData);
							closeModal();
						}
					},
					{
						text: 'Klar',
						action: (originalState, currentState, closeModal) => {
							// Only close the modal if the required forms are valid.
							formValidation.submit(() => {
								// Notify builder to update all block's atributes before closing the modal
								changed(currentState);
								closeModal();
							});
						}
					}
				];

				openModal({
					title: 'Redigerar slideshowblock',
					actions: modalActions,
					position: 'right',
					hideBackdrop: true,
					isPositionable: true,
					state: blockData
				});
			}
		},
		{
			icon: ['fal', 'trash'],
			action: (data) => {
				// Tell the builder to remove the block
				removeBlock(data);
				closeModal();
			}
		}
	];
};

export default {
	baseStruct: {
		key: null,
		data: {
			caption_position: 'south',
			images: [],
			show_navigation: false,
			show_transition: false,
			theme: 'dark',
			transition_delay: 3000,
			transition_duration: 1500,

			type: 'slideshow'
		}
	},
	builder: {
		component: <BlockSlideShow />,
		setOpts: generateOpts
	},
	settings: {
		component: <BlockSlideshowSettings />
	}
};
