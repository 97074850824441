import React from 'react';
import BlockNotes from './BlockNotes';
import { BlockNotesType } from './BlockNotes.types';
import { BuilderBlock } from '../../Block.types';
import { CloseModal, ModalConfig } from '../../../../hooks/useModal/useModal.model';

const initalData = {
	title: 'Fråga: Vilken nytta gör detta klipp för dig i ditt arbete?',
	description: 'Spara dina svar här',
	button_text: 'Spara',
	value: null,
	placeholder: 'Svar',
	type: 'notes'
};

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
export const generateOpts = (
	openModal: (config: ModalConfig<BuilderBlock<BlockNotesType>>) => void,
	closeModal: CloseModal,
	changed: (blockState: BuilderBlock<BlockNotesType>) => void,
	removeBlock: (blockState: BuilderBlock<BlockNotesType>) => void
) => {
	return [
		{
			icon: ['fal', 'trash'],
			action: (blockData: BuilderBlock<BlockNotesType>) => {
				removeBlock(blockData);
				closeModal();
			}
		}
	];
};

const baseStruct = {
	baseStruct: {
		key: null,
		data: initalData
	},
	builder: {
		component: <BlockNotes data={initalData} />,
		setOpts: generateOpts
	}
};

export default baseStruct;