import React from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components/macro';
import { ErrorBoundaryProps } from './ErrorBoundary.types';
import BlockEmpty from '../../components/Builder/BlockTypes/BlockEmpty/BlockEmpty';
import { Button } from '../../components/UI';

class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
	constructor(props: ErrorBoundaryProps) {
	  super(props);
	  this.state = { hasError: false };
	}
  
	static getDerivedStateFromError() {
	  // Update state so the next render will show the fallback UI.
	  return { hasError: true };
	}
  
	componentDidCatch(error: Error) {
		Sentry.captureException(error);
	}
  
	render() {
	  if(this.state.hasError) {
			if(this.props.customComponent) {
				return this.props.customComponent;
			}

			return (
				<ScWrapper>
					<BlockEmpty
						icon={['fal', 'ghost']}
						title="Sorry, något har gått fel här..."
						description="En komponent har inte kunnat renderas korrekt. Detta beror högst troligt på att att datan har en felaktig struktur. Felet har automatiskt rapporterats."
					/>
					{this.props.resetted && (
						<Button
							onClick={this.props.resetted}
							isRounded
							buttonIcon={['fal', 'redo']}
						>
							Ladda om
						</Button>
					)}
				</ScWrapper>
			);
	  }
  
	  return this.props.children;
	}
}

export default ErrorBoundary;

const ScWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;