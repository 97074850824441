import { ControlPanelMenuDefinition } from './ControlPanelMenuDefinitions.types';
import { ERROR_REPORTING_LINK, WIKI_LINK } from '../../settings';
import { GroupsPrivileges, NavigationPrivileges, SettingsPrivileges, UsersPrivileges } from '../../definitions/Privileges';

const ControlPanelDefaultDefinitions: Record<string, ControlPanelMenuDefinition> = {
	media_archive: {
		name: 'Media-arkiv',
		icon: ['fal', 'sitemap'],
		permissions: [NavigationPrivileges.LIST],

		link: {
			internal: true,
			to: '/control-panel/media-archive',
			target: 'self'
		}
	},

	settings: {
		name: 'Inställningar',
		icon: ['fal', 'sliders'],
		permissions: [SettingsPrivileges.OPTIONS_GENERAL, SettingsPrivileges.OPTIONS_ADVANCED],
		link: {
			internal: true,
			to: '/control-panel/settings',
			target: 'self'
		}
	},

	users: {
		name: 'Användare',
		icon: ['fal', 'users'],
		permissions: [UsersPrivileges.LIST],
		link: {
			internal: true,
			to: '/control-panel/users',
			target: 'self'
		}
	},

	groups: {
		name: 'Grupper',
		icon: ['fal', 'screen-users'],
		permissions: [GroupsPrivileges.LIST],
		link: {
			internal: true,
			to: '/control-panel/groups',
			target: 'self'
		}
	},

	links: {
		name: 'Länkar',
		icon: ['fal', 'link'],
		permissions: [],
		link: {
			internal: true,
			to: '/control-panel/links',
			target: 'self'
		}
	},

	wiki: {
		name: 'Wiki',
		icon: ['fal', 'books'],
		permissions: [],
		link: {
			internal: false,
			to: WIKI_LINK,
			target: '_blank'
		}
	},

	bug: {
		name: 'Felrapportering',
		icon: ['fal', 'exclamation-triangle'],
		permissions: [],
		link: {
			internal: false,
			to: ERROR_REPORTING_LINK,
			target: '_blank'
		}
	},

	help: {
		name: 'Hjälp',
		icon: ['fal', 'circle-question'],
		permissions: [],
		link: {
			internal: false,
			to: 'https://help.shinedigital.se',
			target: '_blank'
		}
	}
};

export default ControlPanelDefaultDefinitions;
