/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { User } from '@sentry/react';
import styled from 'styled-components';
import update from 'immutability-helper';
import { GroupMembersFilter, GroupUsersModalProps } from './GroupUsersModal.types';
import axios from '../../../utils/oc-axios';
import { Group } from '../../../containers/ControlPanel/Groups/Groups.types';
import { Button, Loader, Message, ModalContainer } from '../../../components/UI';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import { UserListItem, UsersParams } from '../../../containers/ControlPanel/Users/model.Users';
import Search from '../../../components/Search/Search';
import MultiSelectBar from '../../../components/MultiSelectBar/MultiSelectBar';
import ReactTable from '../../../components/GenericTable/ReactTable/ReactTable';
import { CheckItem, Select } from '../../../components/Forms';
import { SkeletonTableView } from '../../../components/Skeletons';
import useFormValidation from '../../../hooks/useFormValidation/useFormValidation';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';
import { AlertDispatch } from '../../../hooks/useAlert/types.useAlert';
import useModal from '../../../hooks/Modal/useModal';
import UserDetails from '../../../components/ControlPanel/Users/UserDetails/UserDetails';
import { MultiSelectBarOptions } from '../../../components/MultiSelectBar/MultiSelectBar.types';
import useUsers from '../../../containers/ControlPanel/Users/hooks/useUsers';

const resultsPerPage = 20;

const GroupUsersModal: React.FC<GroupUsersModalProps> = (props) => {
	const notification = useAlert()[1] as AlertDispatch;
	const newUserModalFormValidation = useFormValidation();

	const userDetailsModal = useModal();

	// The complete Group's object with all data or null if not loaded yet.
	const group: Group | null = props.modal?.currentState || null;

	// Holds the parameters used when fetching users
	const [groupMembersParams, setGroupMembersParams] = React.useState<UsersParams>({
		group: group?.id || null,
		query: '',
		sort: 'name',
		order: 'asc',
		page: 1, // backend counts from page 1
		limit: resultsPerPage,
		group_filter: 'from_group'
	});

	// Hook that handles the users data
	const { data: groupMembers, isLoading: isLoadingMembers, refetch: refetchMembers, isFetching: isFetchingMembers, isError: hasMembersError } = useUsers(groupMembersParams);

	// All selected users in the group.
	const [selectedUsers, setSelectedUsers] = React.useState<number[]>([]);

	// Calculate the amount of pages based on the total amount of group members
	const amountOfPages = React.useMemo(() => {
		return groupMembers?.amount ? Math.ceil(groupMembers.amount / resultsPerPage) : 1;
	}, [groupMembers?.amount]);

	/**
	 * Handles when user clicks the button to search.
	 * Will use the current value from the text input and set it to a local state
	 *
	 * @param {React.MutableRefObject<HTMLInputElement>} input
	 * @returns {void}
	 */
	const searchButtonClickedHandler = React.useCallback((input: React.MutableRefObject<HTMLInputElement>): void => {
		const value = input.current.value;

		// Clear all selected users as some of the entries may dissapear 
		//	when searching and they not match the search terms.
		setSelectedUsers([]);

		setGroupMembersParams(state => {
			return {
				...state,
				query: value,
				page: 1
			};
		});
	}, []);

	/**
	 * Clears the search input texts when x button is clicked.
	 * 
	 * @returns {void}
	 */
	const searchClearedHandler = React.useCallback((): void => {
		// Clear all selected users as some of the entries may dissapear 
		//	when searching and they not match the search terms.
		setSelectedUsers([]);

		setGroupMembersParams(state => {
			return {
				...state,
				query: '',
				page: 1
			};
		});
	}, []);

	/**
	 * Handles the closing of the actionbar.
	 * 
	 * @returns {void}
	 */
	const actionbarClosedHandler = React.useCallback((): void => {
		// De-select all Groups
		setSelectedUsers([]);
	}, []);

	/**
	 * Handles when the user clicks on the Checkbox of a row.
	 * 
	 * @param {UserListItem} user The user object the event is relevant for.
	 * @returns {void}
	 */
	const userRowCheckboxClickedHandler = React.useCallback((user: UserListItem): void => {
		const groupIdIndex = selectedUsers.indexOf(user.id);

		let stateQry = {};

		// If in array (selected), toggle by removing it
		// Else add it to selected array
		if(groupIdIndex > -1) {
			stateQry = { $splice: [[groupIdIndex, 1]] };
		} else {
			stateQry = {
				$push: [user.id]
			};
		}

		const updatedState = update(selectedUsers, stateQry);
		setSelectedUsers(updatedState);

	}, [selectedUsers]);

	/**
	 * Handles when user changes group filter
	 * 
	 * @param {React.ChangeEvent<HTMLSelectElement>} ev The event that triggered the callback
	 * @returns {void}
	 */
	const groupFilterChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>): void => {
		const newValue = ev.currentTarget.value as GroupMembersFilter;

		setGroupMembersParams(state => {
			return {
				...state,
				group_filter: newValue
			};
		});
	}, []);

	/**
	 * Generate all the columns for the users table.
	 */
	const groupMembersTableColumns = React.useMemo(() => [
		{
			Header: 'Namn',
			accessor: 'name',
			Cell: (tableData: any) => {
				const user = tableData.row.original as UserListItem;

				return (
					<ScColumn>
						<ScCheckItem
							type="checkbox"
							checked={selectedUsers.includes(user.id)}
							changed={() => userRowCheckboxClickedHandler(user)}
						/>
						<div>
							{user.name ?? '-'}
						</div>
					</ScColumn>
					
				);
			}
		},
		{
			Header: 'Användarnamn',
			accessor: 'login_name'
		},
		{
			Header: 'E-postaddress',
			accessor: 'email'
		}
	], [selectedUsers, userRowCheckboxClickedHandler]);

	/**
	 * All options for the MultiSelectBar depending if adding/removing users from the group.
	 * 
	 * @returns {MultiSelectBarOptions[]}
	 */
	const selectedUsersMultiSelectOptions = React.useMemo((): MultiSelectBarOptions[] => {

		if(!group) {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Oväntad fel inträffade, arbetar utan en vald grupp!'
			});

			return [];
		}

		const usersMappedToClass = selectedUsers.map((user) => {
			return {
				id: user
			};
		});

		switch(groupMembersParams.group_filter) {
			case 'from_group':
				return [{
					icon: ['fal', 'user-minus'],
					label: 'Koppla ifrån',
					action: () => {
						axios.delete(`modules/groups/users/${group.id}`, {
							data: {
								users: usersMappedToClass
							}
						}).then(() => {
							setSelectedUsers([]);
							refetchMembers();

							notification('SHOW', {
								priority: AlertPriorityTypes.success,
								title: 'Valda användare är nu ifrån kopplade'
							});
						}).catch(() => {
							notification('SHOW', {
								priority: AlertPriorityTypes.error,
								title: 'Oväntad fel',
								children: 'Misslyckade koppla ifrån valda användare, vänligen prova igen'
							});
						});
					}
				}];

			case 'exclude_users_in_group':
				return [{
					icon: ['fal', 'user-plus'],
					label: 'Koppla till',
					action: () => {
						axios.post(`modules/groups/users/${group.id}`, {
							users: usersMappedToClass
						}).then(() => {
							setSelectedUsers([]);
							refetchMembers();
							
							notification('SHOW', {
								priority: AlertPriorityTypes.success,
								title: 'Valda användare är nu kopplade'
							});
						}).catch(() => {
							notification('SHOW', {
								priority: AlertPriorityTypes.error,
								title: 'Oväntad fel',
								children: 'Misslyckades att koppla valda användare, vänligen prova igen'
							});
						});
					}
				}];
		}

		return [];
	}, [group, groupMembersParams.group_filter, notification, refetchMembers, selectedUsers]);

	/**
	 * Opens a modal to create a new user.
	 * 
	 * @returns {void}
	 */
	const openNewUserModal = React.useCallback((): void => {
		// Creating a new user
		userDetailsModal.open({
			title: 'Skapar användare',
			width: '60%',
			height: '90%',
			isDismissable: 'false',
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					action: (
						_oState: number,
						_cState: number,
						closeModal: () => void
					) => {
						newUserModalFormValidation.resetErrors();
						closeModal();
					}
				},
				{
					text: 'Lägg till',
					action: (
						_oState: number,
						newUserData: User,
						closeModal: () => void
					) => {
						newUserModalFormValidation.submit(() => {

							let alertId = notification('SHOW', {
								priority: AlertPriorityTypes.loading,
								title: 'Skapar användare',
								children: `Skapar användare ${newUserData.name}...`
							});

							axios.post('modules/users', newUserData).then(() => {
								notification('MODIFY', {
									alertID: alertId,
									priority: AlertPriorityTypes.success,
									title: 'Användare skapad',
									children: `Användaren ${newUserData.name} har blivit skapad.`
								});

								refetchMembers();
								closeModal();
							}).catch(() => {
								notification('MODIFY', {
									alertID: alertId,
									priority: AlertPriorityTypes.error,
									title: 'Ett eller flera fel hittades',
									children: `Det gick inte att skapa användaren ${newUserData.name}, prova igen.`
								});
							}).finally(() => {
								newUserModalFormValidation.resetErrors();
							});
						});
					}
				}
			],
			// send object with id -1 to indicate a new user is being created
			state: {
				id: -1,
				email: '',
				name: '',
				mobile_phone: '',
				language: 'sv',
				login_name: '',
				enabled: false,
				verified: false,
				privileges: {},

				// Send with current open group so the groups are pre-selected
				groups: [group]
			}
		});
	}, [userDetailsModal, group, newUserModalFormValidation, notification, refetchMembers]);

	/**
	 * Fetch group's users on next page
	 * 
	 * @param {number} index
	 * @returns {void}
	 */
	const paginationHandler = React.useCallback((index: number): void => {
		setGroupMembersParams(state => {
			return {
				...state,
				page: index + 1
			};
		});
	}, []);

	/**
	 * Fetch group's users based on sorting (asc/desc)
	 * 
	 * @param {React.MouseEvent<HTMLDivElement>} ev
	 * @param {string} id
	 * @returns {void}
	 */
	const sortingHandler = React.useCallback((_ev: React.MouseEvent<HTMLDivElement>, id: string): void => {
		setGroupMembersParams(state => {
			return {
				...state,
				sort: id,
				order: state.order === 'asc' ? 'desc' : 'asc'
			};
		});
	}, []);

	return (
		<>
			{userDetailsModal.getAsComponent(
				<UserDetails
					formValidation={newUserModalFormValidation}
				/>
			)}

			<ModalContainer>
				<ErrorBoundary>

					<ScFlexContainer>
						<ScSelect
							id="enabled"
							fullWidth
							value={groupMembersParams.group_filter}
							isDisabled={isLoadingMembers}
							changed={groupFilterChangedHandler}
						>
							<option value="from_group">
								Gruppmedlemmar
							</option>
							<option value="exclude_users_in_group">
								Ej i gruppen
							</option>
						</ScSelect>
						<ScSearch
							hasButton
							searchPlaceholder="Sök på namn, användarnamn eller e-postadress"
							searchBtnClicked={searchButtonClickedHandler}
							isDisabled={isLoadingMembers}
							cleared={searchClearedHandler}
							enableSearchOnEnter
						/>
					</ScFlexContainer>

					<ScButton
						isXSmall
						isRounded
						isPrimary
						onClick={openNewUserModal}
						isDisabled={isLoadingMembers}
					>
						+ Skapa ny användare
					</ScButton>

					{!isLoadingMembers && isFetchingMembers && (
						<ScLoaderWrapper>
							<ScLoaderText>
								Listan över användare uppdateras...
							</ScLoaderText>
							<Loader
								hasNoBackdrop
								isDark
								size="20px"
							/>
						</ScLoaderWrapper>
					)}

					{!isFetchingMembers && hasMembersError && (
						<ScMessage
							size="16"
							icon={['fal', 'redo']}
							pressed={() => refetchMembers()}
						>
							Ett fel inträffade när användare hämtades, ladda om
						</ScMessage>
					)}

					{!isLoadingMembers && !isFetchingMembers && groupMembers && groupMembers.users.length < 1 && (
						<ScMessage>
							Inga användare hittades med filtreringen: 
							{' '}
							&quot;
							{groupMembersParams.group_filter === 'from_group' ? 'Gruppmedlemmar' : 'Ej i grupp'}
							&quot;
							{' '}
							{groupMembersParams.query ? 'och sökfrasen: ' + `"${groupMembersParams.query}"` : ''}
						</ScMessage>
						
					)}

					{!isLoadingMembers && groupMembers && groupMembers.users.length > 0 && (
						<ReactTable
							columns={groupMembersTableColumns}
							data={groupMembers.users}
							totalResults={groupMembers.amount}
							customPaginationCallback={paginationHandler}
							customSortingCallback={sortingHandler}
							sortableColumns={['name', 'login_name']}
							customSortBy={{ id: groupMembersParams.sort, order: groupMembersParams.order }}
							resultsPerPage={resultsPerPage}
							amountPages={amountOfPages}
							customInitialPageIndex={groupMembersParams.page - 1}
						/>
					)}

					{isLoadingMembers && <SkeletonTableView />}
				</ErrorBoundary>

				{selectedUsers.length > 0 && (
					<MultiSelectBar
						onClose={actionbarClosedHandler}
						opts={selectedUsersMultiSelectOptions}
						amount={selectedUsers.length}
						texts={{
							selectedSingle: 'Markerad användare',
							selectedMany: 'Markerade användare'
						}}
						isLoading={isLoadingMembers}
					/>
				)}

			</ModalContainer>
		</>
	);
};

export default GroupUsersModal;

const ScFlexContainer = styled.div`
	display: flex;
`;

const ScSearch = styled(Search)`
	margin-bottom: 24px;
`;

const ScSelect = styled(Select)`
	margin-right: 8px;
	min-width: 200px;
`;

const ScButton = styled(Button)`
	max-width: 120px;
`;

const ScColumn = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const ScCheckItem = styled(CheckItem)`
	margin-bottom: 0;
	margin-right: 16px;
`;

const ScLoaderText = styled.p`
	margin-right: 8px;
	font-style: italic;
`;

const ScLoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 16px 0;
`;

const ScMessage = styled(Message)`
	margin-bottom: 24px;
	margin-top: 16px;
`;