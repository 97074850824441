/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import styled from 'styled-components/macro';
import CompanyDetails from './CompanyDetails';
import axios from './../../../utils/oc-axios';
import { Company, Companies as CompaniesResult, UsersCSV } from './ComapyDetails.types';
import ModuleContainer from '../../../components/Content/ModuleContainer/ModuleContainer';
import Search from '../../../components/Search/Search';
import { Button, Icon } from '../../../components/UI';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import useModal from '../../../hooks/Modal/useModal';
import useFormValidation from '../../../hooks/useFormValidation/useFormValidation';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';
import { AlertDispatch } from '../../../hooks/useAlert/types.useAlert';
import { ModalProps } from '../../../hooks/useModal/useModal.model';
import ReactTable from '../../../components/GenericTable/ReactTable/ReactTable';
import { SkeletonTable } from '../../../components/Skeletons';

const Companies: React.FC = () => {
	const formValidation = useFormValidation();

	// Modal to create or edit a company
	const companyDetailsModal = useModal();

	// A method to trigger a notification
	const notification = useAlert()[1] as AlertDispatch;

	// Holds list with all companies
	const [companies, setCompanies] = React.useState<CompaniesResult|null>(null);

	// Loading state used when fetching companies
	const [isLoadingCompanies, setIsLoadingCompanies] = React.useState<boolean>(false);

	// The total amount of companies
	const [totalAmountCompanies, setTotalAmountCompanies] = React.useState<number>(0); 

	// Holds the fetched users CSV data and a loading state used while fetching
	const [usersCSV, setUsersCSV] = React.useState<UsersCSV>({
		data: null,
		isLoading: false
	});

	// A ref to the link to download the users CSV file
	const downloadUsersLinkRef = React.useRef<HTMLAnchorElement>(null);

	// Set how many companies to show on each page in the table
	const resultsPerPage = 100;

	// Calculate the amount of pages based on the total amount of companies and 
	// how many to display on each page in the table
	const amountPages = React.useMemo(() => (
		Math.ceil(totalAmountCompanies / resultsPerPage)
	), [totalAmountCompanies]);

	/**
	 * Opens the modal to create or edit a company
	 * 
	 * @param {Company|undefined} selectedCompany
	 * @returns {void}
	 */
	const openCompanyDetailsHandler = React.useCallback((selectedCompany?: Company): void => {
		companyDetailsModal.open({
			title: 'Skapar/Redigerar företag',
			position: 'center',
			width: '60%',
			isDismissable: false,
			actions: [
				{
					text: 'Spara',
					isDefault: false,
					action: async (
						originalState: Company,
						currentCompany: Company,
						closeModal: () => void
					) => {
						formValidation.submit(() => {
							let alertId = notification('SHOW', {
								priority: AlertPriorityTypes.loading,
								title: 'Sparar företag',
								children: `sparar företag ${currentCompany.name}...`
							});

							const companyId = selectedCompany?.id ? selectedCompany.id.toString() : null;

							// An undefined companyId means that we're creating a new company, otherwise we're updating an existing one.
							// Set the appropriate request accordingly
							const axiosRequest = companyId ? axios.put(`modules/companies/${companyId}`, currentCompany) : axios.post('modules/companies', currentCompany);

							axiosRequest.then((res) => {								
								notification('MODIFY', {
									alertID: alertId,
									priority: AlertPriorityTypes.success,
									title: 'Företag sparat',
									children: `Företaget ${currentCompany.name} har sparats.`
								});
								
								const updatedCompany: Company = res.data.result;

								// Update the companies state
								setCompanies(companies => {
									return {
										...companies,
										[updatedCompany.id]: updatedCompany
									};
								});

							}).catch(() => {
								notification('MODIFY', {
									alertID: alertId,
									priority: AlertPriorityTypes.error,
									title: 'Ett eller flera fel hittades',
									children: `Det gick inte att spara företaget ${currentCompany.name}.`
								});
							}).finally(() => {
								formValidation.resetErrors();
							});

							closeModal();
						});
					}
				},
				{
					text: 'Avbryt',
					isDefault: true,
					action: async (
						originalState: Company,
						currentState: Company,
						closeModal: () => void
					) => {
						closeModal();
						formValidation.resetErrors();
					}
				}
			],
			state: selectedCompany ?? {}
		});
	}, [companyDetailsModal, formValidation, notification]);

	/**
	 * Fetch all companies and save in state
	 * 
	 * @param {string|undefined} searchString
	 * @returns {Promise<void>}
	 */
	const fetchCompaniesHandler = React.useCallback(async(searchString?: string): Promise<void> => {
		try {
			setIsLoadingCompanies(true);
			const params = searchString ? `?needle=${searchString}` : '';
			const response = await axios.get(`modules/companies${params}`);

			// When searching for companies and nothing was found
			if(response.status === 204) {
				notification('SHOW', {
					priority: AlertPriorityTypes.info,
					title: 'Inga företag hittades'
				});
			}

			if(response.data) {
				const companies: CompaniesResult|null = response.data.result.companies;
				const amount: number = response.data.result.amount;
				setCompanies(companies);
				setTotalAmountCompanies(amount);
			}
			
		} catch(error) {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Ett fel inträffade',
				children: 'Ett fel inträffade när företag hämtades...'
			});
		} finally {
			setIsLoadingCompanies(false);
		}
	}, [notification]);
		
	/**
	 * Fetch all companies on mount and when sort order changes
	 */
	React.useEffect(() => {
		fetchCompaniesHandler();
	}, [fetchCompaniesHandler]);

	/**
	 * Updates the companies list with companies that match the search query
	 * 
	 * @param {React.MutableRefObject<HTMLInputElement>} input  - The search input element
	 * @returns {void}
	 */
	const searchButtonClickedHandler = React.useCallback((input: React.MutableRefObject<HTMLInputElement>): void => {
		const searchString = input.current.value;
		fetchCompaniesHandler(searchString);
	}, [fetchCompaniesHandler]);

	/**
	 * Refetch companies when search input is cleared
	 * 
	 * @returns {void}
	 */
	const searchClearedHandler = React.useCallback((): void => {
		fetchCompaniesHandler();
	}, [fetchCompaniesHandler]);

	/**
	 * Fetch all users and download them as a CSV file
	 * 
	 * @returns {Promise<void>}
	 */
	const exportUsersHandler = React.useCallback(async (): Promise<void> => {
		try {
			setUsersCSV(state => {
				return {
					...state,
					isLoading: true
				};
			});

			const res = await axios.get('/modules/users/export');
			const users: string = res.data;

			setUsersCSV(state => {
				return {
					...state,
					data: users
				};
			});

			// Trigger a click event on the link to download the CSV file
			downloadUsersLinkRef.current?.click();
		} catch(error) {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Ett fel inträffade',
				children: 'Ett fel inträffade när användare hämtades...'
			});
		} finally {
			setUsersCSV(state => {
				return {
					...state,
					isLoading: false
				};
			});
		}
	}, [notification]);

	return (
		<ModuleContainer
			header="Företag"
			buttonText="Exportera alla användare"
			buttonOnClick={exportUsersHandler}
			buttonIsLoading={usersCSV.isLoading}
			buttonIsDisabled={usersCSV.isLoading}
		>
			<ErrorBoundary>
				{companyDetailsModal.getAsComponent(
					<CompanyDetails
						modal={{} as ModalProps<Company>}
						formValidation={formValidation}
					/>
				)}

				<ScButton
					isPrimary
					isRounded
					isSmall
					hasLargerFontSize
					onClick={() => openCompanyDetailsHandler()}
				>
					+ Skapa företag
				</ScButton>

				{usersCSV.data && (
					<a
						href={`data:text/csv;charset=utf-8,${encodeURI(usersCSV.data)}`}
						download="users.csv"
						hidden
						ref={downloadUsersLinkRef}
					>
						export users as CSV
					</a>
				)}
			
				<ScSearch
					searchPlaceholder="Sök på företagsnamn, orgnr, inköpare, säljare"
					searchBtnClicked={searchButtonClickedHandler}
					hasButton
					cleared={searchClearedHandler}
					isDisabled={isLoadingCompanies}
					enableSearchOnEnter
				/>

				<ScTableWrapper>
					{isLoadingCompanies && <SkeletonTable />}

					{!isLoadingCompanies && !companies && (
						<div>
							Det finns inga företag...
						</div>
					)}

					{!isLoadingCompanies && companies && (
						<ReactTable
							columns={columns}
							data={Object.values(companies)}
							totalResults={totalAmountCompanies}
							amountPages={amountPages}
							hasNativeSorting
							hasNativePagination
							resultsPerPage={resultsPerPage}
							rowClickedCallback={(row) => openCompanyDetailsHandler(row.original)}
						/>
					)}
				</ScTableWrapper>
				
			</ErrorBoundary>
		</ModuleContainer>
	);
};

export default React.memo(Companies);

// Columns used in the table
const columns = [
	{
		  Header: 'Företagsnamn',
		  accessor: 'name' // accessor is the "key" in the data
	},
	{
		  Header: '',
		  accessor: 'edit-company',
		  Cell: () => {
			  return (
				  <ScIcon
					  icon={['fal', 'cog']}
					  color="white"
					  showOnHover
				  />
			  );
		  }
	}
];

const ScSearch = styled(Search)`
	width: 60%;
`;

const ScButton = styled(Button)`
	position: absolute;
	top: 85px;
	right: 40px;
`;

const ScTableWrapper = styled.div`
	margin-top: 24px;
`;

const ScIcon = styled(Icon)`
	cursor: pointer;
	height: 17px;
	float: right;
`;