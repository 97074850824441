import React, { memo } from 'react';
import styled, { css } from 'styled-components/macro';
import update from 'immutability-helper';
import BlockDefinitions from './BlockDefinitions';
import blockInjector from './blockInjector';
import { Icon } from '../UI';
import withErrorBoundary from '../../hoc/withErrorBoundary';

const arePropsEqual = (prevProps, nextProps) => {
	return (
		prevProps.updated === nextProps.updated &&
		prevProps.viewInUse === nextProps.viewInUse
	);
};

const BlockContainer = (props) => {
	return (
		<ScContainer>
			<ScBlockAction>
				<ScActionContainer>
					{getOptions(props)}
				</ScActionContainer>
			</ScBlockAction>

			{blockInjector(props)}

			<ScAddNewBlock>
				<ScAddNewBlockContainer
					onClick={(ev) => {
						ev.stopPropagation();

						props.addBlock(
							props.areaId,
							props.rowId,
							props.columnId,
							props.id,
							props.index,
							ev
						);
					}}
				>
					<ScAddNewBlockIcon icon={['fal', 'xmark']} />
				</ScAddNewBlockContainer>
			</ScAddNewBlock>
		</ScContainer>
	);
};

export default memo(withErrorBoundary(BlockContainer), arePropsEqual);

/**
 * Will dynamically create action bar options
 * And map their actions with the right data
 *
 * @param {object} properties
 */
const getOptions = (properties) => {
	const {
		type,
		openBlockSettingsModal,
		closeBlockSettingsModal,
		blockChanged,
		deleteBlockHandler,
		blockSettingsFormValidation,
		blockTypes
	} = properties;

	// Check for avoiding
	if(!type) throw new Error('This block is missing the "type" property in it\'s base structure (baseStruct > data > type).');

	const opts = BlockDefinitions[type].builder.setOpts(
		openBlockSettingsModal,
		closeBlockSettingsModal,
		blockChanged,
		deleteBlockHandler,
		blockSettingsFormValidation
	);

	let data = {
		...properties.data
	};

	// Exception in place to make sure text-block contents are not overriden by modal state
	if(type === 'text') {
		data = update(data, {
			$unset: ['contents']
		});
	}

	/**
	 * Creates an action item that will be used as a drag handle
	 */
	const getDragHandle = () => {
		return (
			<ScBlockItem
				draghandle
				key={`blockOpt_draghandle_${properties.index}`}
				data-is-draghandle={true}
			>
				<ScIcon icon={['fal', 'arrows-up-down-left-right']} />
				<ScTitle>
					{blockTypes[properties.type].name}
				</ScTitle>
			</ScBlockItem>
		);
	};

	const options = opts.map((opt, index) => (
		<ScBlockItem
			key={`blockOpt_${index}`}
			onClick={(ev) => {
				ev.stopPropagation();

				return opt.action(
					// MondayID: 1225754511 
					// Recreate a BuilderBlock interface/struct from the component props.
					{
						key: properties.id,
						data
					},
					{
						blockChanged: properties.blockChanged
					}
				);
			}}
		>
			<ScIcon icon={opt.icon} />
		</ScBlockItem>
	));

	// add drag handle as the first option
	options.unshift(getDragHandle());

	return options;
};

const ScActionContainer = styled.div`
	display: flex;
	pointer-events: visible;
	height: 100%;
	background-color: #666;
	/* Can't be css variable since this is inserted in iframe */
`;

const ScBlockAction = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	opacity: 0;
	width: 100%;
	justify-content: center;
	z-index: 999;
	pointer-events: none;
	align-items: center;
	font-size: 14px;
	overflow: visible;
	height: 0;

	.isTyping & {
		display: none;
	}
`;

const ScAddNewBlock = styled.div`
	position: absolute;
	height: 2px;
	width: 100%;
	left: 0;
	bottom: -1px;
	opacity: 0;
	user-select: none;
	transition: opacity 0.3s ease-in-out;
	z-index: 9999;

	:hover {
		background: #333;
		cursor: pointer;
	}
`;

const ScAddNewBlockContainer = styled.div`
	position: absolute;
	top: -9px;
	left: 48%;
	width: 20px;
	height: 20px;
	z-index: 9999;
	background: #555;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 80px;
	transform: rotate(45deg);
`;

const ScAddNewBlockIcon = styled(Icon)`
	color: #fafafa;
	width: 8px;
	pointer-events: none;
`;

const ScContainer = styled.div`
	position: relative;

	width: 100%;

	&:after {
		content: '';
		border: 1px solid rgba(197, 197, 197, 0);
		transition: border ease-in-out 0.3s;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;
	}

	&:hover {
		&:after {
			content: '';
			border: 1px solid rgba(197, 197, 197, 1);
		}

		${ScBlockAction} {
			opacity: 1;
			height: 48px;
			.isDragging & {
				opacity: 0;
			}
		}

		${ScAddNewBlock} {
			opacity: 1;
			.isDragging & {
				opacity: 0;
			}
		}
	}

	${ScAddNewBlock}:hover ~ & {
		border: 1px solid rgba(197, 197, 197, 0);

		.isDragging & {
			border: 1px solid transparent;
		}
	}
`;

const ScTitle = styled.div`
	line-height: 16px;
	pointer-events: none;
	margin-left: 8px;
`;

const ScBlockItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 12px;
	user-select: none;
	cursor: pointer;
	color: #fafafa;
	/* Can't be css variable since this is inserted in iframe */

	:hover {
		background: ${(props) => (props.delete ? '#DA534D' : '#333')};

		${(props) =>
		props.draghandle &&
		css`
				cursor: grab;
			`}
	}
`;

const ScIcon = styled(Icon)`
	width: 16px;
	height: 16px;
	display: block;
	pointer-events: none;
`;
