import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Product from '../../Product';
import BlockEmpty from '../../../../../components/Builder/BlockTypes/BlockEmpty/BlockEmpty';

/**
 * Used to show the content in the settings modal for the CheckOutBuyMore block.
 */
const BlockProduct = (props) => {
	const products = props.data.products;

	return (
		<div className="cms-block cms-blockProductsBatch">
			{products.length === 0 && (
				<BlockEmpty
					icon={['fal', 'dollar-sign']}
					title="Här var det tomt..."
					description="Tryck på kugghjulet för att lägga till max 5 produkter."
				/>
			)}
			<div className="products">
				<ul className="productList -horizontal -small">
					{products.length > 0 &&
						products.map((product, index) => (
							<Product
								isEnabled={product.enabled}
								key={`product_${index}_${product.articleNr}`}
								articleNr={product.number}
								name={product.name}
								price={product.price}
								isPriceFrom={product.has_tiers}
								thumbnail={product.thumbnail}
								sizes={product.sizes}
								colors={product.colors}
							/>
						))}
				</ul>
			</div>
		</div>
	);
};

BlockProduct.propTypes = {
	id: PropTypes.string,
	type: PropTypes.string,
	data: PropTypes.object,
	textChanged: PropTypes.func,
	isTyping: PropTypes.func,
	blockTypes: PropTypes.object,
	addBlock: PropTypes.func,
	index: PropTypes.number,
	area: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	row: PropTypes.string,
	column: PropTypes.string,
	opts: PropTypes.array,
	viewInUse: PropTypes.string
};

export default memo(BlockProduct);
