import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../../utils/oc-axios';
import { Group } from '../Groups.types';

const useGroups = (groupId?: number | null) => {

	/**
     * Fetch groups data
     * 
     * @param {AbortSignal | undefined} signal 
     * @returns {Promise<Group[]>}
     */
	const fetchGroups = React.useCallback(async ({ signal } : { signal?: AbortSignal }): Promise<Group[]> => {
		try {
			return axios.get(groupId ? `/modules/groups/${groupId}` : '/modules/groups', {
				signal
			}).then(res => res.data);
		} catch(error) {
			throw new Error('Det gick inte att hämta grupper');
		}

	}, [groupId]);

	// Holds a useQueryResult with groups data
	const query = useQuery(['groups', groupId], fetchGroups, {
		// Stale time set to 1 hour (in ms) to prevent unnecessary refetching
		staleTime: 1000 * 60 * 60 
	});

	return query;
};

export default useGroups;

/**
 * Filter all groups by a search term.
 *
 * @param {string} searchTerm	The text to search for.
 * @param {Group[] | undefined} groups		The groups to make the search on and filter.
 * @returns {Group[] | null} 			The groups that match the search term.
 */
export const filterGroupsBySearchText = (searchTerm: string, groups: Group[] | undefined): Group[] | null => {
	if(!groups) return null;

	const filteredGroups = groups.filter((group) => {
		// get a lowercase copy of the the string to work on like searchterm and
		// 	group properties to ignore case.
		const term = searchTerm.toLowerCase();
		const name = group.name.toLowerCase();
		const description = group.description?.toLowerCase() || ''; // default to empty string if undefined/null

		switch(true) {
			case name.includes(term):
			case description.includes(term):
				return true;

			default:
				return false;
		}
	});

	return filteredGroups;
};