import React from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from './../../../../utils/oc-axios';
import { Group, GroupMutationsVariables, GroupMutationCallback } from '../Groups.types';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';

const useUpdateGroup = (cb?: GroupMutationCallback) => {
	const notification = useAlert()[1] as AlertDispatch;

	/**
     * Update a group
     * 
     * @param {Group} group
     * @returns {Promise<void>}
     */
	const updateGroup = React.useCallback(async (group: Group): Promise<void> => {
		return axios.put(`modules/groups/${group.id}`, group)
			.then(res => res.data)
			.catch((error) => {
				throw new Error(error);
			});
	}, []);

	// Returns a mutation object in order to update a group,
	// Optional: provide it with an alertId to use with notifications and closeModal method to close the modal
	// When provided they can be accessed in onSuccess or onError methods of the mutation object
	const mutation = useMutation((variables: GroupMutationsVariables) => {
		return updateGroup(variables.group);
	}, {
		onSuccess(_data, variables) {
			if(variables.alertId) {
				notification('MODIFY', {
					alertID: variables.alertId,
					priority: AlertPriorityTypes.success,
					title: 'Grupp uppdaterad',
					children: `Gruppen ${variables.group.name} har sparats`
				});
			}

			// Call the callback method provided to the hook if given
			if(cb) cb(variables.group);

			// Call a callback from variables if such method is provided
			if(variables.cb) variables.cb();
		},
		onError(_error, variables) {
			if(variables.alertId) {
				notification('MODIFY', {
					alertID: variables.alertId,
					priority: AlertPriorityTypes.error,
					title: 'Ett fel har uppstått, prova igen',
					children: `Det gick inte att uppdatera gruppen ${variables.group.name}.`
				});
			}
		}
	});

	return mutation;
};

export default useUpdateGroup;