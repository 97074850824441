import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { SeoPageDetailsProps, StyledSettingProps } from './SeoPageDetails.types';
import { SEO_DESCRIPTION_MAX_LENGTH, SEO_TITLE_MAX_LENGTH, SeoPage } from './Seo.types';
import { Button, ModalContainer } from '../../../components/UI';
import DateTimePicker from '../../../components/UI/DateTimePicker/DateTimePicker';
import TextInput from '../../../components/Forms/TextInput/TextInput';
import Textarea from '../../../components/Forms/Textarea/Textarea';
import { CheckItem, Select } from '../../../components/Forms';
import { SUPPORTED_LANGUAGES } from '../../../settings';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import TooltipWithClick from '../../../components/UI/TooltipWithClick/TooltipWithClick';

const SeoPageDetails: React.FC<SeoPageDetailsProps> = (props) => {
	// The current state given by the modal props
	const { columns, page } = props.modal.currentState;

	// The setState function given by the modal props
	const { updateState } = props.modal;

	/**
	 * Updates the page values in the modal state
	 * 
	 * @param {string} key
	 * @param {string} value
	 * @returns {void}
	 */
	const updatePageHandler = React.useCallback((key: string, value: string): void => {
		let newValue = value;

		// Because it's only possible to update the h1 when there is only one h1, we can update the text in the first item of the array
		if(key === 'h1') {
			updateState({ 
				page: { 
					[key]: { 
						// eslint-disable-next-line @typescript-eslint/naming-convention
						0: { 
							text: { $set: newValue }
						}
					}
				}});
			return;
		}

		// Parse 'true' and 'false' to boolean, needed because options in select elements doesn't allow booleans
		if(value === 'true' || value == 'false') {
			newValue = JSON.parse(value);
		}

		updateState({ 
			page: { 
				[key]: { $set: newValue }
			}
		});
	}, [updateState]);

	/**
	 * Sets the un-/publish date in the modal state
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement>} ev
	 * @returns {void}
	 */
	const setPublishDateHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>): void => {
		const checked = ev.target.checked;
		const prop = ev.target.name;

		// When unchecking the publish date, also clear the unpublish date
		if(prop === 'publish_date' && !checked) {
			updateState({ 
				page: { 
					'publish_date': { $set: null },
					'unpublish_date': { $set: null }
				}
			});

			return;
		}

		updateState({ 
			page: { 
				[prop]: { $set: checked ? DateTime.utc().toISO() : null }
			}
		});
		
	}, [updateState]);

	/**
     * Renders an array of JSX elements containing the settings for the page
	 * 
	 * @returns {(JSX.Element | null)[]} 
     */
	const renderSettings = React.useMemo((): (JSX.Element | null)[] => {
		return columns.map(column => {
			const key = column.accessor as keyof SeoPage;
			const value = page[key] as string;
			let element;
			let tooltipText;
            
			switch(key) {
				case 'last_edited_by':
					element = (
						<p>
							{value ?? '-'}
						</p>
					);
					break;
				
				case 'created_at':
				case 'last_saved':
					element = (
						<p>
							{new Date(value).toLocaleString('sv-SE')}
						</p>
					);
					break;
        
				case 'title':
				case 'seo_title':
				case 'slug':
				case 'seo_canonical_url':
					element = (
						<TextInput
							info={key === 'seo_title' ? getSeoInfoText(value, SEO_TITLE_MAX_LENGTH) : undefined}
							id={key}
							value={value ?? ''}
							omitLabel
							changed={(ev) => updatePageHandler(key, ev.target.value)}
							hasMaxWidth
						/>
					);
					break;

				case 'seo_description':
				case 'seo_long_description':
				case 'seo_keywords':
					element = (
						<ScTextarea
							info={key === 'seo_description' ? getSeoInfoText(value, SEO_DESCRIPTION_MAX_LENGTH) : undefined}
							id={key}
							value={value ?? ''}
							changed={(ev) => updatePageHandler(key, ev.target.value)}
						/>
					);
					break;
                
				case 'h1':
					if(!page.h1 || page.h1.length <= 0) {
						element = (
							<ScText isHighlighted>
								Saknas
							</ScText>
						);
						break;
					}

					if(page.h1.length === 1) {
						element = (
							<ScText>
								{page.h1[0].text}
							</ScText>
						);
						break;
					}

					if(page.h1.length > 1) {
						element = (
							<ScText isHighlighted>
								Fler än 1st
							</ScText>
						);
						break;
					}
					break;

				case 'h2':
					if(!page.h2 || page.h2.length <= 0) {
						element = (
							<ScText>
								Saknas
							</ScText>
						);

						break;
					}

					element = page.h2.map((h2, index) => (
						<ScText
							hasMarginBottom={page.h2 ? index !== page.h2.length - 1 : false}
							key={`${h2.block_id} ${h2.text}`}
						>
							{h2.text}
						</ScText>
					));

					break;

				case 'publish_date':
				case 'unpublish_date':
					const date = page[key];

					element = (
						<>
							{key === 'unpublish_date' && (
								<CheckItem
									changed={setPublishDateHandler}
									checked={!!date}
									title="Aktivera slutdatum för publicering"
									name="unpublish_date"
									description="Om ni vill att sidan endast ska vara aktiv under en viss period"
									type="checkbox"
								/>
							)}
							{key === 'publish_date' && (
								<CheckItem
									changed={setPublishDateHandler}
									checked={!!date}
									title="Publicera sidan"
									name="publish_date"
									type="checkbox"
								/>
							)}
							{date && (
								<ScDateTimePicker
									minDate={key === 'unpublish_date' ? page.publish_date : undefined}
									timeFormat
									value={date}
									secondaryColor
									description={(SUPPORTED_LANGUAGES && SUPPORTED_LANGUAGES.length > 1) ? `Ändringar för ${column.Header as string} appliceras på alla översättningar för sidan` : undefined}
									changed={(isoDate: string) => updatePageHandler(key, isoDate)}
								/>
							)}
						</>
					);
					break;
				
				case 'seo_hidden':
					element = (
						<ScSelect
							changed={(ev) => updatePageHandler(key, ev.currentTarget.value)}
							value={page.seo_hidden}
						>
							<option value="true">
								Ja
							</option>
							<option value="false">
								Nej
							</option>
						</ScSelect>
					);
					break;

				case 'missing_alt_texts':
					const images = page.missing_alt_texts;
					
					element = (
						<>
							{images?.map(image => (
								<ScImageLink
									hasMarginBottom={images ? image !== images[images.length - 1] : false}
									key={image.url + '' + image.file_name}
									href={image.url}
									target="_blank"
								>
									{image.file_name}
								</ScImageLink>
							))}
							{(!images || images.length <= 0) && (
								<ScText isItalic>
									Inga
								</ScText>
							)}
						</>
					);	
					break;
				
				case 'boost_value':
					tooltipText = (
						<>
							<p>
								Låg = FAQs, föråldrad information, gamla pressmeddelanden, helt statiska sidor som fortfarande är tillräckligt relevanta för att inte raderas helt.
							</p>
							<ScTooltipText>
								Mellan = Nyhetsartiklar, vissa vädertjänster, blogginlägg, sidor som ingen sida skulle vara komplett utan.
							</ScTooltipText>
							<p>
								Hög = Hemsida, produktinformation, landningssidor.
							</p>
						</>
					);

					element = (
						<Select
							changed={(ev) => updatePageHandler(key, ev.target.value)}
							value={page.boost_value || 'none'}
							name="boost_value"
							description="Ändringar för prioritet appliceras på alla översättningar för sidan"
						>
							{!page.boost_value && (
								<option
									value="none"
									disabled
								>
									Ingen prioriet
								</option>
							)}
							<option value="high">
								Hög
							</option>
							<option value="default">
								Mellan
							</option>
							<option value="low">
								Låg
							</option>
						</Select>
					);
					break;

				default:
					return null;
			}
			return (
				<ScSetting key={key}>
					<ScLabel htmlFor={key}>
						{column.Header as string}

						{tooltipText && (
							<TooltipWithClick
								isRight
								icon={['fal', 'question-circle']}
							>
								{tooltipText}
							</TooltipWithClick>
						)}
					</ScLabel>
					{element}
				</ScSetting>
			);
		});
	}, [columns, page, setPublishDateHandler, updatePageHandler]);

	return (
		<ModalContainer>
			<ErrorBoundary>
				<ScSettingsWrapper>
					{renderSettings}
				</ScSettingsWrapper>

				<ScButtonsWrapper>
					<ScButton
						isPrimary
						isRounded
						isSmall
						buttonIcon={['fal', 'pen-to-square']}
					>
						<ScInternalLink
							to={SUPPORTED_LANGUAGES && SUPPORTED_LANGUAGES.length > 1 ? `/page/${page.page_uuid}/${page.lang}/edit` : `/page/${page.page_uuid}/edit`}
							target="_blank"
						>
							Redigera sida
						</ScInternalLink>
					</ScButton>
					<ScButton
						isPrimary
						isRounded
						isSmall
						buttonIcon={['fal', 'window']}
					>
						<ScExternalLink
							href={page.url}
							target="_blank"
						>
							Gå till sida i webbläsaren
						</ScExternalLink>
					</ScButton>
				</ScButtonsWrapper>
			</ErrorBoundary>
		</ModalContainer>
	);
};

export default SeoPageDetails;

/**
 * Return an info text with the text length, the recommended length 
 * and whether the text length exceeds the recommended length
 * 
 * @param {string} text			            The text you want to check
 * @param {number} recommendedLength		The recommended length of the text
 * @return {string}
 */
export const getSeoInfoText = (text: string, recommendedLength: number): string => {
	if(!text) return '';

	const textLenght = text.length;
	let infoText = '';
        
	if(textLenght <= recommendedLength) infoText = `${textLenght + '/' + recommendedLength} - Texten är inom rekommenderad teckenlängd.`;
	if(textLenght > recommendedLength) infoText = `${textLenght + '/' + recommendedLength} - Rekommenderad teckenlängd har överskridits med ${textLenght - recommendedLength} tecken.`;
        
	return infoText;
};

const ScSettingsWrapper = styled.div`
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
	margin: 0 40px;
`;

const ScSetting = styled.div`
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
	width: 230px;
	margin-bottom: 32px;
	flex-grow: 1;
`;

const ScText = styled.p<StyledSettingProps>`
	width: 100%;

	${props => props.isHighlighted && css`
		color: var(--red-color);
	`}

	${props => props.hasMarginBottom && css`
		margin-bottom: 8px;
	`}

	${props => props.isItalic && css`
		font-style: italic;
	`}
`;

const ScImageLink = styled.a<StyledSettingProps>`
	width: 100%;
	color: #3F69FF;
	cursor: pointer;
	text-decoration: underline;

	${props => props.hasMarginBottom && css`
		margin-bottom: 8px;
	`}
`;

const ScLabel = styled.label`
	width: 100%;
	font-weight: 600;
	margin-bottom: 8px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
`;

const ScTextarea = styled(Textarea)`
	width: 100%;
`;

const ScDateTimePicker = styled(DateTimePicker)`
	width: 100%;
	.rdtPicker {
		position: absolute;
	}
`;

const ScButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
`;

const ScButton = styled(Button)`
	font-weight: 400;
`;

const ScExternalLink = styled.a`
	color: var(--font-bright-color);
	text-decoration: none;
`;

const ScInternalLink = styled(Link)`
	color: var(--font-bright-color);
	text-decoration: none;
`;

const ScSelect = styled(Select)`
	width: 100%;
`;

const ScTooltipText = styled.p`
	margin: 8px 0;
`;