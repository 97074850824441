import React from 'react';
import styled from 'styled-components/macro';
import update from 'immutability-helper';
import { createRandomId } from 'react-utils';
import { useSelector } from 'react-redux';
import AddFormItemModal from './AddFormItemModal/AddFormItemModal';
import FormItemSettings from './FormItemSettings/FormItemSettings';
import { BasicCallbackProps, FormItemCallbackValue, FormItemSettingsAcceptedEvents } from './FormItemSettings/FormItemSettings.types';
import useModal from '../../../../../../hooks/Modal/useModal';
import { CheckItem, TextInput } from '../../../../../Forms';
import { BasicContentModal, Button, Message } from '../../../../../UI';
import { AllowedItem, BlockFormInputItemTypes, BlockFormItem, BlockFormItemTypes, BlockFormType, ConnectedObject, EdittedItem, GeneralFormItemStructures } from '../../BlockForm.types';
import FormItem from '../../../../../Forms/FormItem/FormItem';
import Draggable from '../../../../../../hoc/DragDrop/Draggable';
import useFormStructure from '../../../../../../hooks/Forms/useFormStructure';
import { SettingsTabProps } from '../SettingsTab.types';
import { CloseModal } from '../../../../../../hooks/useModal/useModal.model';
import { ExternalCallback } from '../../../../../../hooks/useFormValidation/types';
import { RootState } from '../../../../../../store/types/RootTypes';
import useAuth from '../../../../../../hooks/useAuth/useAuth';
import { DebugPrivileges } from '../../../../../../definitions/Privileges';
import { BASE_URL } from '../../../../../../settings';
import { FORMITEM_DEFAULT } from '../../BlockForm.consts';
import { generateFormItemChildrens } from '../../BlockForm.func';
import { BlockExamItem } from '../../../BlockExam/BlockExam.types';
import useFormItems from '../../../../../../hooks/Forms/useFormItems';
import { CheckItemProps } from '../../../../../Forms/CheckItem/model.CheckItem';
import { AlertDispatch } from '../../../../../../hooks/useAlert/types.useAlert';
import useAlert, { AlertPriorityTypes } from '../../../../../../hooks/useAlert';

const Layout: React.FC<SettingsTabProps> = (props) => {
	const debugUrlWasPrinted = React.useRef<boolean>(false);
	const [edittedItem, setEdittedItem] = React.useState<EdittedItem>();

	const notification = useAlert()[1] as AlertDispatch;

	const formvalidation = props.formValidation;

	const { verifyUserPermission } = useAuth();

	const { structures }: { structures: GeneralFormItemStructures } = useSelector((selector: RootState) => selector.builder.formStructure);

	// The func to trigger when the modal state has changed,
	// used to notify the Builder to update the block for preview to work
	const { builderUpdateCbk } = props;

	const addFormItemModal = useModal();
	const deleteElementConfirmationModal = useModal();

	// Extract the currentState and updateState from the modal
	// updateState: Callback to update the modal's state only call it with an object for immutability-helper.
	const { currentState, updateState } = props.modal;

	// The block's data.
	const blockData = currentState.data;

	// The actual form items currently added
	const items = React.useMemo(() => {
		return blockData?.items || {};
	}, [blockData]);

	// Get functions and states to be used withing the FormItem component and provided to the useFormStructure hook
	const { expandClickedHandler, createDragDropScope, getIcon, enableEditNameHandler, isEditingName, changeItemNameHandler, isExpanded, setIsExpanded } = useFormItems(blockData, updateState, currentState, builderUpdateCbk);

	/**
     * Used to change "global" form settings, such as name and show_name
     * 
	 * @param {React.ChangeEvent<HTMLInputElement>} ev
	 * @param {CheckItemProps} props
	 * @returns {void}
     */
	const changeFormHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>, props?: CheckItemProps): void => {
		const value = props && 'checked' in props ? ev.target.checked : ev.target.value;
		const key = ev.target.name as keyof BlockFormType;

		const updatedState = update(blockData, {
			[key]: {
				$set: value
			}
		});

		if(updateState) updateState({
			data: {
				$set: updatedState
			}
		}).then((updatedState) => {

			// This shows the changes as preview in the builder
			if(builderUpdateCbk) builderUpdateCbk(updatedState);
			
		}).catch(() => {
			notification('SHOW', {
				priority: AlertPriorityTypes.loading,
				title: 'Ett fel uppstod',
				children: 'Något gick fel när formuläret skulle uppdateras. Vänligen försök igen.'
			});
		});

	}, [blockData, builderUpdateCbk, notification, updateState]);

	/**
	 * Adds a new item to the list.
     * 
     * @param {string} type 
     * @param {string | null} itemKey 
     * @param {string} parent 
     */
	const addFormItemHandler = React.useCallback((type: string, inputType: string | null, parent: string) => {
		
		// generate new random key for the form element.
		const formElementKey = createRandomId(type);

		// Fetch the default FormItem structure and create a new populated object out of it
		let defaultItem, itemChildrens = null;

		switch(type) {
			case BlockFormItemTypes.ADDRESS_GROUP:
				itemChildrens = generateFormItemChildrens([
					{
						name: 'Postadress',
						type: BlockFormItemTypes.INPUT,
						input_type: BlockFormInputItemTypes.TEXT,
						required: false
					},
					{
						name: 'C/o adress',
						type: BlockFormItemTypes.INPUT,
						input_type: BlockFormInputItemTypes.TEXT,
						required: false
					},
					{
						name: 'Postort',
						type: BlockFormItemTypes.INPUT,
						input_type: BlockFormInputItemTypes.TEXT,
						required: false
					},
					{
						name: 'Postnummer',
						type: BlockFormItemTypes.INPUT,
						input_type: BlockFormInputItemTypes.TEXT,
						required: false
					},
					{
						name: 'Land',
						type: BlockFormItemTypes.SELECT,
						input_type: BlockFormInputItemTypes.TEXT,
						required: false
					}
				]);

				// overide defaults for the address group
				defaultItem = update(FORMITEM_DEFAULT, {
					key: { $set: formElementKey },
					name: { $set: 'Adress' },
					children: {
						$set: Object.keys(itemChildrens)
					},
					type: { $set: BlockFormItemTypes.ADDRESS_GROUP }
				});
				break;

			case BlockFormItemTypes.NAME_GROUP:
				itemChildrens = generateFormItemChildrens([
					{
						name: 'Förnamn',
						type: BlockFormItemTypes.INPUT,
						input_type: BlockFormInputItemTypes.TEXT,
						required: true
					},
					{
						name: 'Efternamn',
						type: BlockFormItemTypes.INPUT,
						input_type: BlockFormInputItemTypes.TEXT,
						required: true
					}]);

				// overide defaults for the address group
				defaultItem = update(FORMITEM_DEFAULT, {
					key: { $set: formElementKey },
					name: { $set: 'Namn' },
					children: {
						$set: Object.keys(itemChildrens)
					},
					type: { $set: BlockFormItemTypes.NAME_GROUP }
				});

				break;

			case BlockFormItemTypes.EMAIL:
				// overide defaults for the email
				defaultItem = update(FORMITEM_DEFAULT, {
					key: { $set: formElementKey },
					name: { $set: 'E-postadress' },
					type: { $set: BlockFormItemTypes.INPUT },
					input_type: { $set: BlockFormInputItemTypes.EMAIL },
					validation: { $set: 'email' }
				});

				break;

			case BlockFormItemTypes.PHONE:
				// overide defaults for the email
				defaultItem = update(FORMITEM_DEFAULT, {
					key: { $set: formElementKey },
					name: { $set: 'Telefon' },
					type: { $set: BlockFormItemTypes.INPUT },
					input_type: { $set: BlockFormInputItemTypes.TELEPHONE },
					validation: { $set: 'phone' }
				});
	
				break;

			default:
				const itemStructure = structures[type];
				defaultItem = {
					...FORMITEM_DEFAULT,
					key: formElementKey,
					name: itemStructure.name,
					type: type as BlockFormItemTypes,
					input_type: inputType
				};
		}
		
		const suggestedState = {
			data: {
				items: {
					// add formItems object.
					$merge: {
						[formElementKey]: defaultItem,
						...itemChildrens
					},

					// add element to root element
					[parent]: {
						children: {
							$push: [formElementKey]
						}
					}
				}
			}
		};

		setIsExpanded((state) => [...new Set(state), formElementKey]);
		
		if(builderUpdateCbk && currentState) builderUpdateCbk(update(currentState, suggestedState));
		if(updateState && suggestedState) updateState(suggestedState);
	}, [builderUpdateCbk, currentState, setIsExpanded, structures, updateState]);

	/**
     * Responsible for deleting a form item and all it's children recursivily
     * 
	 * @param {string} parentItem
     * @param {number} index
     * @param {string} id
     * @returns {void}
	 */
	const removeFormItemHandler = React.useCallback((parent: string, index: number, id: string) => {
		const items = blockData?.items;
		
		const modalActions = [
			{
				text: 'Avbryt',
				action: (
					oState: null,
					cState: null,
					closeModal: CloseModal
				) => {
					closeModal();
				},
				isDefault: true,
				isVisible: true
			},
			{
				text: 'Radera',
				action: (
					oState: null,
					cState: null,
					closeModal: CloseModal
				) => {
					// if there are no items then return as nothing can't be removed.
					if(!items) return;

					const children = items[id].children;
        
					const getRecursiveElements = (children: string[]) => {
						if(children.length) {
							return children.reduce((prev: string[], itemKey: string) => {
								const item = items[itemKey];
								const children = item.children;

								if(children.length > 0) {
									prev = prev.concat(itemKey, ...getRecursiveElements(children));
								}
								else prev = prev.concat(itemKey, ...children);

								return prev;
							}, []);
						}

						return [];
					};

					// Complete list of elements affected by this change
					// Should contain no duplicates, but we'll do a final check just in case
					const affectedElements =  [id, ...new Set(getRecursiveElements(children))];

					const updatedState = update(blockData, {
						items: {
							[parent]: {
								children: {
									$splice: [[index, 1]]
								}
							},
							$unset: affectedElements
						}
					});
		
					if(updateState) {
						updateState({ data: { $set: updatedState }})
							.then((updatedState) => {
								if(builderUpdateCbk) builderUpdateCbk(updatedState);
							});
					}

					closeModal();
				},
				isDefault: false,
				isVisible: true
			}
		];

		deleteElementConfirmationModal.open({
			actions: modalActions,
			isDismissable: false,
			state: null
		});
		
	}, [blockData, builderUpdateCbk, deleteElementConfirmationModal, updateState]);

	/**
     * Used to store a reference of which item and it's type that will be edited
     * 
     * @param {BlockFormItem} item 
     */
	const settingsClickedHandler = React.useCallback((item: BlockFormItem) => {
		const { key, type } = item;

		setEdittedItem((state) => {
			if(state && state.key === key) return null;
			return { key, type };
		});
	}, []);

	/**
     * Will update a specific FormItem in modal and editor
     * 
     * @param {FormItemSettingsAcceptedEvents} ev
     * @param {BasicCallbackProps} properties
     * @returns {void}
     */
	const formItemChangedHandler = React.useCallback((ev: FormItemSettingsAcceptedEvents, properties: BasicCallbackProps) => {
		ev.persist();
		let { name, value } = properties;
		let updatedValue: FormItemCallbackValue = null;
		const key = edittedItem?.key as string;

		// Type guard to make sure we have a value in our target
		if('value' in ev.target) {
			updatedValue = ev.target.value;
		}

		// If we deal a select flagged with "multiple"
		if(updatedValue && Array.isArray(value) && 'selectedOptions' in ev.target) {
			updatedValue  = Array.from(ev.target.selectedOptions as HTMLOptionElement[], option => option.value);
		}

		// In case out value is of type boolean, then just switch it to the opposite
		switch(typeof value) {
			case 'boolean':
				updatedValue = !value;
				break;

			default:
		}

		// Helper function
		// Maps all related children to the parent into an array
		const mapChildrenToParent = (parent: BlockFormItem, current: BlockFormItem[] = []) => {
			const childObjects = parent.children.map(itemKey => blockData!.items[itemKey]);

			const children = childObjects.reduce((prev, current) => {

				if(current.children.length > 0) {
					prev.concat(mapChildrenToParent(current, prev));
				}
				else prev.push(current);

				return prev;
			}, current as BlockFormItem[]);

			current.concat(...children);
			return current;
		};

		let updatedBlock = blockData;

		// Exception in logic to handle checked elements in a group
		// Remove selected/checked from sibling items if radio-group
		if(name === 'selected' && blockData && key) {
			let parentItem = Object.values(blockData.items).find(item => item.children.includes(key)) as BlockFormItem;

			// This is only applicable to when parent is of type option group, select or radio group
			if(parentItem.type === BlockFormItemTypes.OPTION_GROUP || parentItem.type === BlockFormItemTypes.SELECT || parentItem.type === BlockFormItemTypes.RADIO_GROUP) {
                
				// In case the parent is a radio group, then find it's parent select
				if(parentItem && parentItem.type === BlockFormItemTypes.OPTION_GROUP) {
					parentItem = Object.values(blockData.items).find(item => item.children.includes(parentItem.key)) as BlockFormItem;
				}

				if(!parentItem.multiple) {
					const children = mapChildrenToParent(parentItem);

					// Looks for the selected item
					const checkedItem = children.find(item => item.selected); 

					// If one is found, then set it it to false
					if(checkedItem) {
						updatedBlock = update(updatedBlock, {
							items: {
								[checkedItem.key]: {
									selected: {
										$set: false
									}
								}
							}
						});
					}
				}
				
			}
		}

		// If removing multiple from a form item, then clear children selected flag recursivily
		if(updatedBlock && name === 'multiple' && !updatedValue) {
			const parentItem = updatedBlock.items[key];
			const children = mapChildrenToParent(parentItem);

			const updatedChildren = children.reduce((prev, current) => {
				const key = current.key;
				prev[key] = {
					...current,
					selected: false
				};

				return prev;
			}, {} as {[key: string]: BlockFormItem});

			updatedBlock = update(updatedBlock, {
            	items: {
            		$merge: updatedChildren
            	}
			});
		}

		// Logical exception when chaning the input type
		// Some props are not compatible beween input types, thus we need to clear them from the FormItem
		if(name === 'input_type' && typeof updatedValue === 'string') {

			const reservedProps = ['key', 'input_type', 'uuid', 'name', 'unique_name', 'type', 'children', 'accept', 'autofocus', 'sort_order'];
			const settings = structures['input'].settings;

			// The settings for the new input_type and the reservedProps will be compared against actual values and kept
			const simple = settings.simple;
			const advanced = settings.advanced.input_type as ConnectedObject;
			let compatibleProps = [...Object.keys(simple)];

			// Get the advanced settings
			if(advanced && updatedValue in advanced) {
				const advancedItemProps = advanced[updatedValue] as keyof BlockFormItem[];
				
				if(Array.isArray(advancedItemProps)) {
					compatibleProps = compatibleProps.concat(...advancedItemProps);
				}
			}

			if(key) {
				updatedBlock = update(updatedBlock, {
					items: {
						[key]: {
							$apply: (formItem) => {
								// Go through each prop and value in the FormItem
								return Object.entries(formItem).reduce((prev, current) => {
									const [prop, value] = current;
									prev[prop] = null;

									// If the value is compatible or reserved, then add it to the new FormItem object
									if(compatibleProps.includes(prop) || reservedProps.includes(prop)) {
										prev[prop] = value;
									}

									return prev;
								}, {} as {[key: string]: any}) as BlockFormItem;
							}
						}
					}
				});
			}
		}

		// Logical exception to restore an empty string to an empty array
		if(name === 'accept' && !updatedValue) {
			updatedValue = [];
		}

		// Update the state
		const updatedState = update(updatedBlock, {
			items: {
				[key]: {
					[name]: {
						$set: updatedValue
					}
				}
			}
		});

		if(updateState) {
			updateState({
				data: {
					$set: updatedState
				}
			})
				.then((updatedState) => {
					if(builderUpdateCbk) builderUpdateCbk(updatedState);
				});
		}
	}, [blockData, builderUpdateCbk, edittedItem, structures, updateState]);

	/**
     * Creates individual FormItems wrapped in a draggable
     * 
     * @param {BlockFormItem | BlockExamItem} item 
     * @param { number} index 
     * @param {BlockFormItem | BlockExamItem} parentItem
     * @param {JSX.Element | JSX.Element[] | undefined} children 
     * @returns {JSX.Element}
     */
	const createFormItem = React.useCallback((item: BlockFormItem | BlockExamItem, index: number, parentItem: BlockFormItem | BlockExamItem, children?: JSX.Element | JSX.Element[] | undefined) => {
		// This checks if the item and parentItem is of type BlockFormItem, since unique_name is not a property of BlockExamItem
		if(!('unique_name' in item)) return <></>;

		let isLocked = false;
		const parentKey = parentItem.key ?? 'root';

		const { type } = item;

		if(parentItem) {
			const { type: parentType } = parentItem;
		
			if(parentType) {
				isLocked = structures[parentType].locked;
			}
		}

		const allowed = structures[type].allowed;
		const allowedCreationObject = allowed && allowed.map((item): AllowedItem => {
			return {
				element: item,
				name: structures[item].name 
			};
		});

		const icon = getIcon(item);

		return (
			<Draggable
				id={item.key}
				key={index}
				index={index}
				scope={`scope-${parentKey}`}
				allowedDirection="vertical"
				parent={parentKey}
				enableDrag={!isLocked}
			>
				<FormItem
					name={`${item.name}`}
					id={item.key}
					parent={parentKey}
					index={index}
					icon={typeof icon === 'string' ? ['fal', icon] : icon}
					addItemClicked={addFormItemHandler}
					isEditingName={isEditingName === item.key}
					nameChangeEnabled={enableEditNameHandler}
					nameChanged={changeItemNameHandler}
					settingsClicked={() => settingsClickedHandler(item)}
					deleteClicked={removeFormItemHandler}
					allowed={allowedCreationObject}
					expandClicked={(() => expandClickedHandler(item.key))}
					isExpanded={isExpanded.includes(item.key)}
					isDisabled={item.disabled}
					isDefault={item.selected}
					isActive={edittedItem?.key === item.key}
					isLocked={isLocked}
				>
					{children}
				</FormItem>
			</Draggable>
		);
	}, [addFormItemHandler, changeItemNameHandler, edittedItem, enableEditNameHandler, expandClickedHandler, getIcon, isEditingName, isExpanded, removeFormItemHandler, settingsClickedHandler, structures]);

	const { buildForm } = useFormStructure(items, createDragDropScope, createFormItem, createDragDropScope);

	/**
	 * Opens modal for adding new FormItem.
	 */
	const addItemHandler = React.useCallback((event: any) => {
		event.preventDefault();
		event.stopPropagation();
		
		const modalActions = [{
			text: '',
			action: (
				oState: null,
				cState: null,
				closeModal: CloseModal
			) => {
				closeModal();
			},
			isDefault: true,
			isVisible:false
		}];

		addFormItemModal.open({
			title: 'Lägg till',
			actions: modalActions,
			isDismissable: true,
			state: {}
		});
	}, [addFormItemModal]);

	let affectedItem = null;
	if(edittedItem?.key) {
		affectedItem = items[edittedItem.key];
	}

	if(verifyUserPermission(DebugPrivileges.FORM_RESULTS)) {
		if(blockData && !debugUrlWasPrinted.current) {
			// This console.log is currently needed to display a link in the console for debug purposes
			// Do not remove
		    // eslint-disable-next-line no-console
			console.log(`${window.location.protocol}${BASE_URL}forms/debug/${blockData.uuid}`);
			debugUrlWasPrinted.current = true;
		}
	}

	return (
		<>
			{deleteElementConfirmationModal.getAsComponent(
				<BasicContentModal
					title="Du håller på att ta bort formulärelementet"
					text="Är du säker på att du vill ta bort?"
				/>
			)}
			<div style={{ display: 'flex', background: '#fff' }}>

				<ScContainer>
				
					{addFormItemModal.getAsComponent(
						<AddFormItemModal
							addItemHandler={addFormItemHandler}
						/>
					)}

					<TextInput
						id="name"
						name="name"
						label="Namn på formulär"
						value={blockData?.name}
						formValidationUnregister={formvalidation?.unregisterElement}
						error={formvalidation?.errors['name']}
						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formvalidation?.watch(ev, changeFormHandler as ExternalCallback, data);
						}}
						inputRef={(ref: HTMLInputElement) =>
							formvalidation?.registerElement(ref, {
								required: true
							})}
					/>

					<CheckItem
						changed={changeFormHandler}
						checked={!!blockData.show_name}
						type="checkbox"
						name="show_name"
						title="Visa namn på formulär"
					/>

					<ScMessage
						isSmall
					>
						Lägg till och sortera formulär element
					</ScMessage>

					{buildForm()}

					<ScButton
						isPrimary
						onClick={addItemHandler}
					>
						Lägg till formulär element
					</ScButton>

				</ScContainer>

				{affectedItem && (
					<FormItemSettings
						isOpen={affectedItem !== null}
						affectedItem={affectedItem}
						itemChangedCallback={formItemChangedHandler}
					/>
				)}
			</div>
		</>
	);
};

export default Layout;

const ScContainer = styled.div`
	width: 416px;
	overflow-y: scroll;
	height: 100%;
	max-height: calc(100vh - 272px);
	padding: var(--modal-padding);
	box-sizing:border-box;
	background: var(--modal-side-bg-color);
`;

const ScMessage = styled(Message)`
	margin-bottom: 4px;
`;

const ScButton = styled(Button)`
	margin-top: 4px;
`;