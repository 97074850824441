import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { parseStringAsObjectPath } from 'react-utils';
import Inline from './Inline';
import Poster from './Poster';
import useModal from '../../../../../hooks/Modal/useModal';
import TabContainer from '../../../../../components/Tabs/TabContainer';
import Tab from '../../../../Tabs/Tab';
import { TextInput, CheckItem } from '../../../../Forms';
import { ImageThumbnail, Icon } from '../../../../UI';
import { grid } from '../../../../UI/PositionGrid/PositionGrid';
import MediaArchive from '../../../../../containers/MediaArchive/MediaArchive';
import MainNavigation from '../../../../../containers/MainNavigation/MainNavigation';
import ScreenVariants from '../../../../ScreenVariants';

/**
 * Help function to figure out a valid position depending on image type (inline / poster)
 *
 * @param {*} type
 * @param {*} position
 */
export const determineCapturePositionHelper = (type, position) => {
	switch(type) {
		case 'poster':
			// checks whether the value is supported
			const positionInGrid = grid.find((item) => item.value === position);

			// if the given value is not supported, then default it to south
			if(positionInGrid === void 0) position = 'south';

			return position;

		default:
			// checks whether the value is supported
			const positionCheck = ['below', 'above', 'default'].includes(
				position
			);

			// if the given value is not supported, then default it to south
			if(!positionCheck) position = 'below';

			return position;
	}
};

const BlockImageSettings = (props) => {
	// The modal hook this component is inside of.
	const { currentState, updateState } = props.modal;

	// The func to trigger when something inside here changed.
	const changed = props.changed;

	// The hook to make formValidation possible
	const formValidation = props.formValidation;

	// The block data to show inside this component
	const state = currentState.data;

	const builderConfig = useSelector((state) => state.config.builder);
	const viewVariants = builderConfig.variants;

	const [selectedVariant, setSelectedVariant] = useState(
		builderConfig.variants.current
	);

	const mediaArchiveModal = useModal();
	const openMediaArchiveModal = mediaArchiveModal.open;
	const closeMediaArchiveModal = mediaArchiveModal.close;
	const getAsComponentMediaArchiveModal = mediaArchiveModal.getAsComponent;

	const linkSelectModal = useModal();
	const openLinkSelectModal = linkSelectModal.open;
	const closeLinkSelectModal = linkSelectModal.close;
	const getAsComponentLinkSelectModal = linkSelectModal.getAsComponent;

	const contentPosition = determineCapturePositionHelper(
		state.image_type,
		state.content_position
	);

	const openMediaArchive = useCallback(() => {
		openMediaArchiveModal({
			position: 'center',
			width: '80%',
			height: '80%',
			isDismissable: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					isVisible: false,
					action: (originalState, currentState, closeModal) =>
						closeModal()
				}
			]
		});
	}, [openMediaArchiveModal]);

	const imageThumbnailActions = useMemo(
		() => [
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'gear']}
				           />,
				action: openMediaArchive
			},
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'trash']}
				           />,
				action: () => {
					const variant =
						selectedVariant === 'desktop'
							? 'media'
							: 'mobile_media';

					updateState({
						data: {
							[variant]: {
								uuid: { $set: null },
								thumbnail: { $set: null },
								src: { $set: null }
							}
						}
					}).then((updatedState) => {
						// notify Builder to update the block for preview to work
						changed(updatedState);
					});
				},
				styles: { hover: { backgroundColor: '#DA534D' }}
			}
		],
		[changed, openMediaArchive, selectedVariant, updateState]
	);

	/**
	 * Updates state when something changes it's value.
	 *
	 * @param {string} property				The property to change
	 * @param {*} newValue					The new value
	 * @param {*} preventBuilderPreview		If Builder should be notified to mirror changes there.
	 */
	const changedHandler = useCallback(
		(property, newValue, preventBuilderPreview) => {
			let suggestedState = parseStringAsObjectPath(property, newValue);

			if(property === 'image_type') {
				suggestedState = {
					...suggestedState,
					content_position: {
						$set: newValue === 'inline' ? 'below' : 'south'
					}
				};
			}

			// update the modal's state with the new data
			updateState({ data: suggestedState }).then((updatedState) => {
				// notify Builder to update the block for preview to work
				if(!preventBuilderPreview) changed(updatedState);
			});
		},
		[changed, updateState]
	);

	/**
	 * Handles when an input changes it's value.
	 *
	 * @param {Event} ev
	 */
	const inputChangedHandler = useCallback(
		(ev, inputProps) =>
			changedHandler(
				ev.target.name,
				ev.target.value,
				inputProps.preventBuilderPreview
			),
		[changedHandler]
	);

	/**
	 * Handles when a checkbox changes it's value.
	 *
	 * @param {Event} ev
	 */
	const checkboxChangedHandler = useCallback(
		(ev, inputProps) =>
			changedHandler(
				ev.target.name,
				ev.target.checked,
				inputProps.preventBuilderPreview
			),
		[changedHandler]
	);

	/**
	 * Media Archive returns an array of objects
	 * Uses the first object (as ImageBlock only allows one image)
	 * And updates the state and closes the modal
	 *
	 * @param {array} files
	 */
	const imageSelectedHandler = useCallback((files) => {
		const file = files[0];

		// TODO: media & media_mobile are the hardcoded prop names for now until back-end makes variants dinamic
		const variant = selectedVariant === 'desktop' ? 'media' : 'mobile_media';

		updateState({
			data: {
				[variant]: {
					src: { $set: file.src },
					uuid: { $set: file.uuid }
				},
				alt_text: { $set: file.alt_text }
			}
		}).then((updatedState) => {
			// notify Builder to update the block for preview to work
			changed(updatedState);

			closeMediaArchiveModal();
		});
	}, [changed, closeMediaArchiveModal, selectedVariant, updateState]);

	/**
	 * Navigate to a page in Editor mode
	 *
	 * @param {Event} ev
	 * @param {object} item
	 */
	const menuItemDoubleClickHandler = useCallback((item) => {
		changedHandler('link.uri', item.uri, true);

		closeLinkSelectModal();
	}, [closeLinkSelectModal, changedHandler]);

	/**
	 * Opens a modal with the main menu for slecting an item
	 */
	const openMainMenuSelector = useCallback(() => {
		openLinkSelectModal({
			title: 'Välj mål för länk',
			position: 'right',
			hideBackdrop: false,
			isDismissable: true,
			actions: [
				{
					text: 'Avbryt',
					isDefault: true,
					action: (originalState, currentState, closeModal) => {
						// Reset builder block settings
						closeModal();
					}
				},
				{
					text: 'Klar',
					action: (originalState, currentState, closeModal) => {
						// Notify builder to update all block's atributes before closing the modal

						closeModal();
					}
				}
			],
			state: {}
		});
	}, [openLinkSelectModal]);

	/**
	 * Handles when the variant have been changed.
	 */
	const changedVariantHandler = useCallback(
		(variantName) => setSelectedVariant(variantName),
		[]
	);

	let activeInnerTab = (
		<Inline
			block={state}
			contentPosition={contentPosition}
			changed={changedHandler}
			inputChanged={inputChangedHandler}
			checkboxChanged={checkboxChangedHandler}
			formValidation={formValidation}
			openMainMenuSelector={openMainMenuSelector}
		/>
	);

	if(state.image_type === 'poster') {
		activeInnerTab = (
			<Poster
				block={state}
				contentPosition={contentPosition}
				changed={changedHandler}
				inputChanged={inputChangedHandler}
				checkboxChanged={checkboxChangedHandler}
				formValidation={formValidation}
				openMainMenuSelector={openMainMenuSelector}
			/>
		);
	}

	const tabsDisabled =
		formValidation.formHasErrors() ||
		(!state.media.src && !state.mobile_media.src);

	return (
		<>
			{getAsComponentMediaArchiveModal(
				<MediaArchive
					mediaChosen={imageSelectedHandler}
					maxSelectionAmount={1}
					fileShowOnly={['image']}
				/>
			)}

			{getAsComponentLinkSelectModal(
				<MainNavigation
					itemDoubleClicked={menuItemDoubleClickHandler}
				/>
			)}

			<TabContainer disableAllTabsButActive={tabsDisabled}>
				<Tab title="Innehåll">
					<ScreenVariants
						currentVariant={selectedVariant}
						variants={viewVariants.available}
						changed={changedVariantHandler}
					>
						<ScImageThumbnail
							height="200px"
							width="100%"
							actions={imageThumbnailActions}
							placeholderClicked={openMediaArchive}
							thumbnail={
								selectedVariant === 'desktop'
									? state.media.src
									: state.mobile_media.src
							}
						/>
					</ScreenVariants>

					<TextInput
						label="Alternativ-text"
						description="The text showing when hover the image. Good for SEO purposes"
						changed={inputChangedHandler}
						name="alt_text"
						value={state.alt_text || ''}
						preventBuilderPreview
						maxLength={127}
					/>
				</Tab>

				<Tab title="Design">
					<ScImageTypes>
						<ScImageType>
							<CheckItem
								type="radio"
								changed={inputChangedHandler}
								name="image_type"
								value="inline"
								checked={!!(state.image_type === 'inline')}
								isHidden
							/>
							<ScImageTypeBg
								isActive={!!(state.image_type === 'inline')}
							>
								<svg
									width="40"
									height="30"
									viewBox="0 0 40 30"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M2 1.5H38V22.5H2V1.5ZM0 24V0H40V24H0ZM24 28H0V30H24V28Z"
										fill="#333"
									/>
								</svg>
								<ScImageTypeTitle
									isActive={!!(state.image_type === 'inline')}
								>
									Utanför
								</ScImageTypeTitle>
							</ScImageTypeBg>
						</ScImageType>

						<ScImageType>
							<CheckItem
								type="radio"
								changed={inputChangedHandler}
								name="image_type"
								value="poster"
								checked={!!(state.image_type === 'poster')}
								isHidden
							/>
							<ScImageTypeBg
								isActive={!!(state.image_type === 'poster')}
							>
								<svg
									width="40"
									height="32"
									viewBox="0 0 40 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M38 2H2V30H38V2ZM0 0V32H40V0H0ZM8 11H32V13H8V11ZM28 18H12V20H28V18Z"
										fill="#333"
									/>
								</svg>
								<ScImageTypeTitle
									isActive={!!(state.image_type === 'poster')}
								>
									Ovanpå
								</ScImageTypeTitle>
							</ScImageTypeBg>
						</ScImageType>
					</ScImageTypes>
					{activeInnerTab}
				</Tab>
			</TabContainer>
		</>
	);
};

BlockImageSettings.propTypes = {
	modal: PropTypes.object,
	changed: PropTypes.func,
	formValidation: PropTypes.object
};

export default BlockImageSettings;

const ScImageThumbnail = styled(ImageThumbnail)`
	background-color: var(--bg-bright-color);
	padding: 0;

	#ScBorder {
		border: 1px solid var(--bg-dark-grey-color);
	}

	:hover {
		#ScBorder {
			border: 1px solid var(--bg-dark-grey-color);
			box-shadow: 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;

const ScImageTypes = styled.div`
	background-color: var(--bg-grey-color);
	display: flex;
	margin: 0 -16px 16px;
`;

const ScImageType = styled.label`
	display: flex;
	flex: 1;
	cursor: pointer;
`;

const ScImageTypeBg = styled.div`
	padding: 24px;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: ${(props) => (props.isActive ? '#333' : 'transparent')};

	svg path {
		fill: ${(props) => (props.isActive ? '#f2f2f2' : '#333')};
	}
`;

const ScImageTypeTitle = styled.div`
	margin-top: 16px;
	font-weight: 300;
	font-size: 14px;
	color: ${(props) => (props.isActive ? '#fafafa' : '#333')};
`;
