import { Transforms, Range } from 'slate';
import { LINK, OC7Editor } from '../../../OC7Editor';
import { SUPPORTED_LANGUAGES } from '../../../../../settings';

/**
 * Callback function that will wrap selection in an anchor tag
 *
 * @param {Editor} editor
 * @param {{navItem: NavItem, new_window: boolean, type: string, uri: string, uri_type: string, value: string}} url
 * @param {Function} setTextEditorContent
 * @param {string} activeLanguage
 */
export const insertLink = (editor, url, setTextEditorContent, activeLanguage) => {
	if(editor.selection) {
		wrapLink(editor, url, activeLanguage);
		setTextEditorContent();
	}
};

/**
 * Get the link node from the editor
 */
export const getLinkNode = (editor) => {
	const [link] = OC7Editor.nodes(editor, { match: (n) => n.type === LINK });

	// The first object in the array is the node (with information about the link), the second is the path
	return link ? link[0] : null;
};

/**
 * Determines whether a node is a link
 *
 * @param {Editor} editor
 * @returns {boolean}
 */
export const isLinkActive = (editor) => {
	const [link] = OC7Editor.nodes(editor, { match: (n) => n.type === LINK });
	return !!link;
};

/**
 * Unwraps the anchor tags from a node
 *
 * @param {Editor} editor
 */
export const unwrapLink = (editor) => {
	Transforms.unwrapNodes(editor, { match: (n) => n.type === LINK });
};

/**
 * Wraps a node in an anchor tag
 *
 * @param {Editor} editor
 * @param {{navItem: NavItem, new_window: boolean, type: string, uri: string, uri_type: string, value: string, button_class: string}} url
 */
export const wrapLink = (editor, url, activeLanguage) => {
	if(isLinkActive(editor)) {
		unwrapLink(editor);
	}

	// Check if there is language support and then set the slug for the current language
	let href;
	if(SUPPORTED_LANGUAGES.length > 1 && url.type === 'navItem') {
		href = '/' + url.navItem.l10n[activeLanguage].slug;
	} else {
		href = url.type === 'navItem' ? url.uri : url.value;
	}

	const { selection } = editor;
	const isCollapsed = selection && Range.isCollapsed(selection);
	const link = {
		type: LINK,
		uri_type: url.uri_type,
		new_window: url.new_window,
		href: href,
		children: isCollapsed ? [{ text: url.value }] : [],
		button_class: url.button_class
	};

	if(isCollapsed) {
		Transforms.insertNodes(editor, link);
	} else {
		Transforms.wrapNodes(editor, link, { split: true });
		Transforms.collapse(editor, { edge: 'end' });
	}
};

/**
 * Get the correct uri type from the href
 * 
 * @param {string} href
 * @returns {LinkType} // 'http' | 'slug' | 'phone' | 'email' | 'advanced'
 */
export const getUriType = (href) => {
	if(!href) return 'slug';

	if(href.startsWith('http')) return 'http';

	if(href.startsWith('mailto')) return 'email';

	if(href.startsWith('tel')) return 'phone';

	return 'slug';
};

/**
 * Strip the href from 'mailto:' and 'tel:'
 * 
 * @param {string} href
 * @returns {string}
 */
export const stripHref = (href) => {
	if(!href) return '';

	if(href.startsWith('mailto')) return href.replace('mailto:', '');

	if(href.startsWith('tel')) return href.replace('tel:', '');

	return href;
};