import React from 'react';
import * as Sentry from '@sentry/react';

class LayoutErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { errorMessage: null };
	}

	static getDerivedStateFromError(error, errorInfo) {
		// Update state so the next render will show the fallback UI.
		return { errorMessage: error.message };
	}

	componentDidCatch(error, errorInfo) {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		if(this.state.errorMessage) {
			// You can render any custom fallback UI
			return (
				<h1>
					Ett fel har uppstått: 
					{' '}
					{this.state.errorMessage}
				</h1>
			);
		}
		return this.props.children;
	}
}

export default LayoutErrorBoundary;
