import React from 'react';
import BlockEmbed from './BlockEmbed';
import BlockEmbedSettings from './Settings/BlockEmbedSettings';

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
const generateOpts = (
	openModal,
	closeModal,
	changed,
	removeBlock
) => {
	return [
		{
			icon: ['fal', 'gear'],
			action: (blockData) => {
				const modalActions = [
					{
						text: 'Avbryt',
						isDefault: true,
						action: (originalState, currentState, closeModal) => {
							// Reset builder block settings
							changed(blockData);
							closeModal();
						}
					},
					{
						text: 'Klar',
						action: (originalState, currentState, closeModal) => {
							// Notify builder to update all block's atributes before closing the modal
							changed(currentState);
							closeModal();
						}
					}
				];

				openModal({
					title: 'Redigerar embedblock',
					actions: modalActions,
					position: 'right',
					hideBackdrop: true,
					isPositionable: true,
					state: blockData
				});
			}
		},
		{
			icon: ['fal', 'trash'],
			action: (blockData) => {
				// Tell the builder to remove the block
				removeBlock(blockData);
				closeModal();
			}
		}
	];
};

const BlockEmbedDefinition = {
	baseStruct: {
		key: null,
		data: {
			block: 'embed',
			type: 'embed',
			contents: ''
		}
	},
	builder: {
		component: <BlockEmbed />,
		setOpts: generateOpts
	},
	settings: {
		component: <BlockEmbedSettings />
	}
};

export default BlockEmbedDefinition;
